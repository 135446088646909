import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useCategoryMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const categoryMasterData = getData(state, apiKeys.categoryMaster, []);
  const saveCategoryMasterStatus = getStatus(state, apiKeys.saveCategoryMaster, '');
  const updateCategoryMasterStatus = getStatus(state, apiKeys.updateCategoryMaster, '');
  const categoryMasterStatus = getStatus(state, apiKeys.categoryMaster, '');
  const categoryMasterErrMsg = getErrorMessage(state, apiKeys.categoryMaster, '');
  const deleteCategoryMasterStatus = getStatus(state, apiKeys.deleteCategoryMaster, '');


  const getCategoryMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.categoryMaster,
      method: apiMethods.GET,
      key: apiKeys.categoryMaster,
      failureMsg: formatMessage({ id: "failed-to-get-category-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getCategoryMaster()
  }, [dispatch, getCategoryMaster]);

  useEffect(() => {
    if (deleteCategoryMasterStatus === apiStatuses.succeeded) {
      getCategoryMaster();
    }
  }, [deleteCategoryMasterStatus, getCategoryMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.categoryMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  
  useEffect(() => {
    if (saveCategoryMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getCategoryMaster();
    } 
  }, [saveCategoryMasterStatus, closeModal, getCategoryMaster]);

  useEffect(() => {
    if (updateCategoryMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getCategoryMaster();
    } 
  }, [updateCategoryMasterStatus, closeModal, getCategoryMaster]);

  return useMemo(() => ({
    categoryMasterData,
    categoryMasterStatus,
    categoryMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    categoryMasterData,
    categoryMasterStatus,
    categoryMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
