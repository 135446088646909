
import {
    BookOutlined, UserOutlined, UsergroupAddOutlined
} from '@ant-design/icons';
import Department from '../components/departments/departments';
import Designation from '../components/designations/designations';
import Grade from '../components/grades/grades';
import GradeList from '../components/gradelists/gradelists';
import Role from '../components/roles/roles';

// tabName is locale id
export const roleMasterTabs = [
    {
        id: "department",
        tabName: "department", 
        icon: <UserOutlined />,
        tabContent: <Department />
    },
    {
        id: "designation",
        tabName: "designation",
        icon: <BookOutlined />,
        tabContent: <Designation />
    },
    {
        id: "grade",
        tabName: "grade",
        icon: <UsergroupAddOutlined />,
        tabContent: <Grade />
    },
    {
        id: "gradelist",
        tabName: "gradelist",
        icon: <UsergroupAddOutlined />,
        tabContent: <GradeList />
    },
    {
        id: "role",
        tabName: "role",
        icon: <UsergroupAddOutlined />,
        tabContent: <Role />
    }
]