import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

export default function Spinner() {
  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="center">
        <Grid item>
            <CircularProgress />
        </Grid>
    </Grid>
  );
}