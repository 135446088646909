import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useGradeMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveGradeMasterStatus = getStatus(state, apiKeys.saveGradeMaster, '');
  const updateGradeMasterStatus = getStatus(state, apiKeys.updateGradeMaster, '');
  const gradeMasterData = getData(state, apiKeys.gradeMaster, []);
  const gradeMasterStatus = getStatus(state, apiKeys.gradeMaster, '');
  const gradeMasterErrMsg = getErrorMessage(state, apiKeys.gradeMaster, '');
  const deleteGradeMasterStatus = getStatus(state, apiKeys.deleteGradeMaster, '');

  const getGradeMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.gradeMaster,
      method: apiMethods.GET,
      key: apiKeys.gradeMaster,
      failureMsg: formatMessage({ id: "failed-to-get-grade-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getGradeMaster()
  }, [dispatch, getGradeMaster]);

  useEffect(() => {
    if (deleteGradeMasterStatus === apiStatuses.succeeded) {
      getGradeMaster();
    }
  }, [deleteGradeMasterStatus, getGradeMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.gradeMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  
  useEffect(() => {
    if (saveGradeMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getGradeMaster();
    } 
  }, [saveGradeMasterStatus, closeModal, getGradeMaster]);

  useEffect(() => {
    if (updateGradeMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getGradeMaster();
    } 
  }, [updateGradeMasterStatus, closeModal, getGradeMaster]);


  return useMemo(() => ({
    gradeMasterData,
    gradeMasterStatus,
    gradeMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    gradeMasterData,
    gradeMasterStatus,
    gradeMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
