import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Stack, Typography } from '@mui/material';

import { aboutus } from "../../../store/api/constants/baseURL";
import navs from '@/from-theme/routes/constants/nav';

const Footer = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" 
  sx={{ p: '24px 16px 0px', mt: 'auto' }}>
    <Typography variant="caption">&copy; All rights reserved</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      <Link component={RouterLink} to={aboutus}
       target="_blank" variant="caption" color="textPrimary">
        About us
      </Link>
      <Link component={RouterLink} to={navs.policy} target="_blank" variant="caption" 
      color="textPrimary">
        Privacy
      </Link>
      <Link component={RouterLink} to={navs.terms} target="_blank" variant="caption"
       color="textPrimary">
        Terms
      </Link>
    </Stack>
  </Stack>
);

export default Footer;
