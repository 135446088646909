import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import Dropdown from "@/common/components/dropdown/dropdown";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import useCreateEditDesignationMaster from "./hooks/useCreateEditDesignationMaster";
import useGetGradeOptions from "@/common/hooks/useGetGradeMaster";
import PropTypes from "prop-types";
import { apiStatuses } from "@/store/api/constants";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";
import useGetDepartmentMasterByDivision from "@/common/hooks/useGetDepartmentByDivision";
import useGetDivisionOptions from "@/common/hooks/useGetDivisionMaster";


function CreateEditDesignationMaster({
  closeModal, activeEditId
}) {

   const {
    getDepartmentsByDivisionOptions: departmentsData,
    getDepartmentsByDivision: getDepartment,
    getDepartmentsByDivisionLoading: departmentsLoading
  } = useGetDepartmentMasterByDivision('department');

  const { getGradeOptions, getGradeLoading } = useGetGradeOptions();
  const { getDivisionOptions, getDivisionLoading } = useGetDivisionOptions();

  const {
    values, formik, getEditDesignationMasterStatus, getEditDesignationMasterErrMsg,
     onChangeDivision, saveDesignationMasterStatus, updateDesignationMasterStatus,
  } = useCreateEditDesignationMaster({activeEditId, getDepartment});

  const isPending = [saveDesignationMasterStatus, updateDesignationMasterStatus]
  .includes(apiStatuses.pending);


  const {
    designationCode, designationName, departmentId, desGradeId, divisionId, activeStatus
  } = values;

  return (
    <>
      {
        getEditDesignationMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }

      {getEditDesignationMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditDesignationMasterErrMsg} />
      )}

      {(getEditDesignationMasterStatus === apiStatuses.succeeded || !activeEditId) &&
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25} style={{paddingBottom:'240px'}}>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="designation-code" size="small" fullWidth
                  value={designationCode}
                  inputLabel="designation-code"
                  formik={formik}
                  name="designationCode"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="designation-name" size="small" fullWidth
                  value={designationName}
                  inputLabel="designation-name"
                  formik={formik}
                  name="designationName"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <Dropdown
                  options={getDivisionOptions}
                  loading={getDivisionLoading}
                  placeholder="division-name"
                  inputLabel="division-name"
                  value={divisionId}
                  formik={formik}
                  name="divisionId"
                  onChange={onChangeDivision}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <Dropdown
                  options={departmentsData}
                  loading={departmentsLoading}
                  placeholder="department-name"
                  inputLabel="department-name"
                  value={departmentId}
                  formik={formik}
                  name="departmentId"
                />
              </Grid>
              
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <Dropdown
                  options={getGradeOptions}
                  loading={getGradeLoading}
                  placeholder="grade-name"
                  inputLabel="grade-name"
                  value={desGradeId}
                  formik={formik}
                  name="desGradeId"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending}
             sx={{ mr: 1 }}>
              <FormattedMessage id={"cancel"} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
                <FormattedMessage id={"save"} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      }
    </>
  )
}



CreateEditDesignationMaster.defaultProps = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
}


CreateEditDesignationMaster.defaultProps = {
  activeEditId: 0,
};


export default CreateEditDesignationMaster;