import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useDropdownListMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveDropdownlistMasterStatus = getStatus(state, apiKeys.saveDropdownlistMaster, '');
  const updateDropdownlistMasterStatus = getStatus(state, apiKeys.updateDropdownlistMaster, '');
  const dropdownlistMasterData = getData(state, apiKeys.dropdownlistMaster, []);
  const dropdownlistMasterStatus = getStatus(state, apiKeys.dropdownlistMaster, '');
  const dropdownlistMasterErrMsg = getErrorMessage(state, apiKeys.dropdownlistMaster, '');
  const deleteDropdownlistMasterStatus = getStatus(state, apiKeys.deleteDropdownlistMaster, '');

  const getDropdownlistMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.dropdownlistMaster,
      method: apiMethods.GET,
      key: apiKeys.dropdownlistMaster,
      failureMsg: formatMessage({ id: "failed-to-get-dropdownlist-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getDropdownlistMaster()
  }, [dispatch, getDropdownlistMaster]);

  useEffect(() => {
    if (deleteDropdownlistMasterStatus === apiStatuses.succeeded) {
      getDropdownlistMaster();
    }
  }, [deleteDropdownlistMasterStatus, getDropdownlistMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.dropdownlistMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  useEffect(() => {
    if (saveDropdownlistMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getDropdownlistMaster();
    } 
  }, [saveDropdownlistMasterStatus, closeModal, getDropdownlistMaster]);

  useEffect(() => {
    if (updateDropdownlistMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getDropdownlistMaster();
    } 
  }, [updateDropdownlistMasterStatus, closeModal, getDropdownlistMaster]);

  return useMemo(() => ({
    dropdownlistMasterData,
    dropdownlistMasterStatus,
    dropdownlistMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    dropdownlistMasterData,
    dropdownlistMasterStatus,
    dropdownlistMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
