import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetMainmenuOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getMainmenuData = getData(state, apiKeys.menuMaster, []);
  const getMainmenuStatus = getStatus(state, apiKeys.menuMaster, '');
  const getMainmenuLoading = apiStatuses.pending === getMainmenuStatus;

    const getMainmenuOptions = useMemo(() => {
      return getMainmenuData.filter((option) => option.activeStatus === "1")
      .map(({ menuId, menuName }) => ({
        id: menuId,
        label: menuName
      }))
    }, [getMainmenuData]);


  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.menuMaster,
        method: apiMethods.GET,
        key: apiKeys.menuMaster
      })
    );
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.menuMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getMainmenuData,
      getMainmenuOptions,
      getMainmenuStatus,
      getMainmenuLoading
    }),
    [getMainmenuData, getMainmenuOptions, getMainmenuStatus, getMainmenuLoading]
  );
}

export default useGetMainmenuOptions;
