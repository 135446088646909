import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateEditCategoryMaster(activeEditId) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveCategoryMasterStatus = getStatus(state, apiKeys.saveCategoryMaster, '');
  const updateCategoryMasterStatus = getStatus(state, apiKeys.updateCategoryMaster, '');
  const saveCategoryMasterLoading = apiStatuses.pending === saveCategoryMasterStatus;
  const getEditCategoryMasterData = getData(state, apiKeys.getEditCategoryMaster, {});
  const getEditCategoryMasterStatus = getStatus(state, apiKeys.getEditCategoryMaster, "");
  const getEditCategoryMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditCategoryMaster,
    formatMessage({ id: "failed-to-get-selected-category-details" })
  );

  const validationSchema = yup.object({
    categoryCode: yup.string().required(formatMessage({ id: "this-field-is-required" }))
    .matches(/^[A-Za-z\s]+$/, formatMessage({ id: 'only-alphanumerical-characters-allowed' })),
    categoryName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    brandId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateCategoryMaster(values)
      }else{
        saveCategoryMaster(values)
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue('categoryCode', formik.values.categoryCode.toUpperCase());
  }, [ formik.values.categoryCode]);

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditCategoryMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditCategoryMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditCategoryMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-category-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditCategoryMaster()
    }
  }, [activeEditId, getEditCategoryMaster]);

  useEffect(() => {
    if (getEditCategoryMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditCategoryMasterData))
    }
  }, [getEditCategoryMasterStatus])

  const saveCategoryMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveCategoryMaster,
        method: apiMethods.POST,
        key: apiKeys.saveCategoryMaster,
        successMsg: formatMessage({ id: "category-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "category-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateCategoryMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateCategoryMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateCategoryMaster,
        successMsg: formatMessage({ id: "category-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "category-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveCategoryMaster, 
        apiKeys.getEditCategoryMaster] }));
    },
    [dispatch]
  );

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveCategoryMasterStatus,
    updateCategoryMasterStatus,
    saveCategoryMasterLoading,
    getEditCategoryMasterData,
    getEditCategoryMasterStatus,
    getEditCategoryMasterErrMsg,
  }), [
    formik,
    saveCategoryMasterStatus,
    updateCategoryMasterStatus,
    saveCategoryMasterLoading,
    getEditCategoryMasterData,
    getEditCategoryMasterStatus,
    getEditCategoryMasterErrMsg,
  ])
}

export default useCreateEditCategoryMaster