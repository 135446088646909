import React from 'react';
import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import TextInput from '@/common/components/text-input/text-input';
import SwitchInput from '@/common/components/switch-input/switch-input';
import AnimateButton from '@/from-theme/components/@extended/AnimateButton';
import { FormattedMessage } from 'react-intl';
import useCreateEditMenuMaster from './hooks/useCreateEditMenuMaster';
import PropTypes from 'prop-types';
import { apiStatuses } from '@/store/api/constants';
import ErrorInPage from '@/common/components/error-in-page/error-in-page';
import Spinner from '@/common/components/spinner/spinner';

function CreateEditMenuMaster({ closeModal, activeEditId }) {
  const { values, formik, getEditMenuMasterStatus, getEditMenuMasterErrMsg,
    saveMenuMasterStatus, updateMenuMasterStatus, } = 
  useCreateEditMenuMaster(activeEditId);

  const isPending = [saveMenuMasterStatus, updateMenuMasterStatus].includes(apiStatuses.pending);


  const { baseUrl, menuName, sortOrderId, subMenuAvail, activeStatus } = values;

  return (
    <>
      {getEditMenuMasterStatus === apiStatuses.pending && activeEditId && <Spinner />}
      {getEditMenuMasterStatus === apiStatuses.rejected && activeEditId && 
      <ErrorInPage message={getEditMenuMasterErrMsg} />}
      {(getEditMenuMasterStatus === apiStatuses.succeeded || !activeEditId) && (
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25}>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="menu-name"
                  size="small"
                  fullWidth
                  value={menuName}
                  inputLabel="menu-name"
                  formik={formik}
                  name="menuName"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="sub-menu"
                  leftLabel="no"
                  rightLabel="yes"
                  value={subMenuAvail}
                  formik={formik}
                  name="subMenuAvail"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="base-url"
                  size="small"
                  fullWidth
                  value={baseUrl}
                  inputLabel="base-url"
                  formik={formik}
                  name="baseUrl"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="order-id"
                  size="small"
                  fullWidth
                  value={sortOrderId}
                  inputLabel="order-id"
                  formik={formik}
                  name="sortOrderId"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending}
             sx={{ mr: 1 }}>
              <FormattedMessage id={'cancel'} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
                <FormattedMessage id={'save'} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      )}
    </>
  );
}


CreateEditMenuMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditMenuMaster.defaultProps = {
  activeEditId: 0,
};



export default CreateEditMenuMaster;
