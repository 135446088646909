export const getStringData = (data, stringInExport) => {
  if (stringInExport && stringInExport.length > 0) {
    const mapping = {
      activeStatus: { 1: 'Active', 0: 'Inactive' },
      gpsStatus: { 1: 'Yes', 0: 'No' },
      planApproval: { 1: 'Yes', 0: 'No' },
      planStatus: {
        1: 'Plan Created',
        2: 'Approved By Reporting Manager',
        3: 'Rejected By Reporting Manager',
        4: 'Verified By Backoffice',
        5: 'Rejected By Backoffice'
      },
      planBoApproval: { 1: 'Yes', 0: 'No' },
      planDeviationApproval: { 1: 'Yes', 0: 'No' },
      planDeviationBoApproval: { 1: 'Yes', 0: 'No' },
      networkStrength: { 1: 'Low', 2: 'Medium', 3: 'Good', 4: 'Strong' },
      prospectsMasterApproval: { 1: 'Yes', 0: 'No' },
      internetStatus: { 1: 'Yes', 0: 'No' }
    };

    return data.map((item) => {
      let updatedItem = { ...item };

      for (var prop in item) {
        if (mapping[prop] && mapping[prop][item[prop]]) {
          updatedItem[prop] = mapping[prop][item[prop]];
        }
      }

      return updatedItem;
    });
  }

  return data;
};

export const dateString = (date) => {
  if (!date) return ''; // Handle undefined or null dates
  const d = new Date(date); // Convert to Date object
  if (isNaN(d)) return date; // Return original if not a valid date

  const day = String(d.getUTCDate()).padStart(2, '0');
  const month = String(d.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = d.getUTCFullYear();
  let hours = d.getUTCHours();
  const minutes = String(d.getUTCMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert '0' hour to '12'

  const time = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

  return `${day}-${month}-${year} ${time}`;
};
