import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { useNavigate } from "react-router";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";
import navs from "@/from-theme/routes/constants/nav";

function useCreateEditMenuListMaster(activeEditId) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveMenuListMasterStatus = getStatus(state, apiKeys.saveMenuListMaster, '');
  const updateMenuListMasterStatus = getStatus(state, apiKeys.updateMenuListMaster, '');
  const saveMenuListMasterLoading = apiStatuses.pending === saveMenuListMasterStatus;
  const getEditMenuListMasterData = getData(state, apiKeys.getEditMenuListMaster, {});
  const getEditMenuListMasterStatus = getStatus(state, apiKeys.getEditMenuListMaster, "");
  const getEditMenuListMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditMenuListMaster,
    formatMessage({ id: "failed-to-get-selected-menulist-details" })
  );

  const validationSchema = yup.object({
    menuId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    menuDisplayName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    sortOrder: yup.string(),
    routesUrl: yup
    .string()
    .required(formatMessage({ id: "this-field-is-required" })),
    keyName: yup
    .string()
    .required(formatMessage({ id: "this-field-is-required" })),
    projectId: yup.object().nullable(),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateMenuListMaster(values)
      }else{
        saveMenuListMaster(values)
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue('keyName', formik.values.keyName.toUpperCase());
  }, [ formik.values.keyName]);

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditMenuListMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditMenuListMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditMenuListMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-menulist-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditMenuListMaster()
    }
  }, [activeEditId, getEditMenuListMaster]);

  useEffect(() => {
    if (getEditMenuListMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditMenuListMasterData))
    }
  }, [getEditMenuListMasterStatus])

  const saveMenuListMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveMenuListMaster,
        method: apiMethods.POST,
        key: apiKeys.saveMenuListMaster,
        successMsg: formatMessage({ id: "menulist-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "menulist-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateMenuListMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateMenuListMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateMenuListMaster,
        successMsg: formatMessage({ id: "menulist-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "menulist-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (saveMenuListMasterStatus === apiStatuses.succeeded) {
      navigate(navs.menuMaster);
    }
  }, [saveMenuListMasterStatus, navigate])

  useEffect(() => {
    if (updateMenuListMasterStatus === apiStatuses.succeeded) {
      navigate(navs.menuMaster);
    }
  }, [updateMenuListMasterStatus, navigate])
  

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveMenuListMaster, apiKeys.updateMenuListMaster,
         apiKeys.getEditMenuListMaster] }));
    },
    [dispatch]
  );

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveMenuListMasterStatus,
    updateMenuListMasterStatus,
    saveMenuListMasterLoading,
    getEditMenuListMasterData,
    getEditMenuListMasterStatus,
    getEditMenuListMasterErrMsg,
  }), [
    formik,
    saveMenuListMasterStatus,
    updateMenuListMasterStatus,
    saveMenuListMasterLoading,
    getEditMenuListMasterData,
    getEditMenuListMasterStatus,
    getEditMenuListMasterErrMsg,
  ])
}

export default useCreateEditMenuListMaster