import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useSubzoneMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveSubzoneMasterStatus = getStatus(state, apiKeys.saveSubzoneMaster, '');
  const updateSubzoneMasterStatus = getStatus(state, apiKeys.updateSubzoneMaster, '');
  const subzoneMasterData = getData(state, apiKeys.subzoneMaster, []);
  const subzoneMasterStatus = getStatus(state, apiKeys.subzoneMaster, '');
  const subzoneMasterErrMsg = getErrorMessage(state, apiKeys.subzoneMaster, '');
  const deleteSubzoneMasterStatus = getStatus(state, apiKeys.deleteSubzoneMaster, '');


  const getSubzoneMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.subzoneMaster,
      method: apiMethods.GET,
      key: apiKeys.subzoneMaster,
      failureMsg: formatMessage({ id: "failed-to-get-subzone-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getSubzoneMaster()
  }, [dispatch, getSubzoneMaster]);

  useEffect(() => {
    if (deleteSubzoneMasterStatus === apiStatuses.succeeded) {
      getSubzoneMaster();
    }
  }, [deleteSubzoneMasterStatus, getSubzoneMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.subzoneMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  useEffect(() => {
    if (saveSubzoneMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
    } 
  }, [saveSubzoneMasterStatus, closeModal]);

  useEffect(() => {
    if (updateSubzoneMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getSubzoneMaster();
    } 
  }, [updateSubzoneMasterStatus, closeModal, getSubzoneMaster]);

  return useMemo(() => ({
    subzoneMasterData,
    subzoneMasterStatus,
    subzoneMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    subzoneMasterData,
    subzoneMasterStatus,
    subzoneMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
