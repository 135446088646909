import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import Dropdown from "@/common/components/dropdown/dropdown";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import useCreateEditDistrictMaster from "./hooks/useCreateEditDistrictMaster";
import useGetStateOptions from "@/common/hooks/useGetStateMaster";
import useGetCountryOptions from "@/common/hooks/useGetCountryMaster";
import PropTypes from "prop-types";
import { apiStatuses } from "@/store/api/constants";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";


function CreateEditDistrictMaster({
  closeModal, activeEditId
}) {

  const { getStateOptions, getStateLoading } = useGetStateOptions();
  const { getCountryOptions, getCountryLoading } = useGetCountryOptions();
  const { values, formik, getEditDistrictMasterStatus, onChangeCountry,
    getEditDistrictMasterErrMsg, saveDistrictMasterStatus, updateDistrictMasterStatus, }
     = useCreateEditDistrictMaster(activeEditId);

  const isPending = [saveDistrictMasterStatus, updateDistrictMasterStatus]
  .includes(apiStatuses.pending);

  const {
    stateId, districtName, countryId, activeStatus
  } = values;

  return (
    <>
      {
        getEditDistrictMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }

      {getEditDistrictMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditDistrictMasterErrMsg} />
      )}

      {(getEditDistrictMasterStatus === apiStatuses.succeeded || !activeEditId) &&
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25} style={{ paddingBottom: '225px' }}>
            <Grid item xs={12} sm={6} md={4} lg={6}>
                <Dropdown
                  options={getCountryOptions}
                  loading={getCountryLoading}
                  placeholder="country-name"
                  inputLabel="country-name"
                  value={countryId}
                  formik={formik}
                  name="countryId"
                  onChange={onChangeCountry}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <Dropdown
                  options={getStateOptions}
                  loading={getStateLoading}
                  placeholder="state-name"
                  inputLabel="state-name"
                  value={stateId}
                  formik={formik}
                  name="stateId"
                />
              </Grid>
             
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="district-name" size="small" fullWidth
                  value={districtName}
                  inputLabel="district-name"
                  formik={formik}
                  name="districtName"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending}
             sx={{ mr: 1 }}>
              <FormattedMessage id={"cancel"} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
                <FormattedMessage id={"save"} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      }
    </>
  )
}

CreateEditDistrictMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

CreateEditDistrictMaster.defaultProps = {
  activeEditId: 0,
};

export default CreateEditDistrictMaster;