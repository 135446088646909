import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import useGradeMaster from "./hooks/useGradesMaster";
import CreateEditGradeMaster from "./create-grade-master/create-grade-master";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";
import useAuth from "@/from-theme/hooks/useAuth";

const {roleMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;

const GradeMaster = () => {
    const { gradeMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, gradeMasterStatus } = useGradeMaster();
    const {
        onRowDelete,
        deleteGradeMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();
    const { userData } = useAuth();
    const entitlement = userData?.entitlement || {};
  
    const isUpdateDeleteAccess = isUserHasAccess(roleMasterKey, updateKey, entitlement)
      && isUserHasAccess(roleMasterKey, deleteKey, entitlement);

    const actionButton = (
    <VerifyAccess requestedMenu={roleMasterKey} requestedFeature={createKey}>
        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => setIsCreateEditModalOpen(true)}
        ><FormattedMessage id="add-grade" />
        </Button>
        </VerifyAccess>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={gradeMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteGradeMasterStatus,
                        isUpdateDeleteAccess })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={gradeMasterStatus}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-grade-master" :
                         "edit-grade-master"}
                         maxWidth= {"md"}
                    >
                        <CreateEditGradeMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
};

export default GradeMaster;
