import { EditTwoTone } from "@ant-design/icons";
import { IconButton, Stack, Tooltip } from "@mui/material";
import PropTypes from 'prop-types';

function EditAction({onRowEdit, row}) {
    const {original: {cityId}} = row; 
    return (
    <Stack direction="row" spacing={1} alignItems="center">
        <Tooltip title="Edit">
            <IconButton
                color="primary"
                onClick={ () => onRowEdit(cityId)}
            >
                <EditTwoTone />
            </IconButton>
        </Tooltip>
    </Stack>
    );
}

EditAction.propTypes = {
    row: PropTypes.object,
    table: PropTypes.object,
    options: PropTypes.array,
    id: PropTypes.number,
    index: PropTypes.number,
    onRowEdit: PropTypes.func.isRequired
};
export default EditAction;
