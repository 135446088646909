import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateEditRoleMaster(activeEditId) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveRoleMasterStatus = getStatus(state, apiKeys.saveRoleMaster, '');
  const updateRoleMasterStatus = getStatus(state, apiKeys.updateRoleMaster, '');
  const saveRoleMasterLoading = apiStatuses.pending === saveRoleMasterStatus;
  const getEditRoleMasterData = getData(state, apiKeys.getEditRoleMaster, {});
  const getEditRoleMasterStatus = getStatus(state, apiKeys.getEditRoleMaster, "");
  const getEditRoleMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditRoleMaster,
    formatMessage({id: "failed-to-get-selected-role-details"})
  );

  const validationSchema = yup.object({
    roleName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateRoleMaster(values)
      }else{
        saveRoleMaster(values)
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditRoleMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditRoleMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditRoleMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-role-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditRoleMaster()
    }
  }, [activeEditId, getEditRoleMaster]);

  useEffect(() => {
    if (getEditRoleMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditRoleMasterData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditRoleMasterStatus])

  const saveRoleMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveRoleMaster,
        method: apiMethods.POST,
        key: apiKeys.saveRoleMaster,
        successMsg: formatMessage({ id: "role-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "role-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateRoleMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateRoleMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateRoleMaster,
        successMsg: formatMessage({ id: "role-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "role-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);


  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveRoleMaster, apiKeys.updateRoleMaster,
         apiKeys.getEditRoleMaster] }));
    },
    [dispatch]
  );

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveRoleMasterStatus,
    updateRoleMasterStatus,
    saveRoleMasterLoading,
    getEditRoleMasterData,
    getEditRoleMasterStatus,
    getEditRoleMasterErrMsg,
  }), [
    formik,
    saveRoleMasterStatus,
    updateRoleMasterStatus,
    saveRoleMasterLoading,
    getEditRoleMasterData,
    getEditRoleMasterStatus,
    getEditRoleMasterErrMsg,
  ])
}

export default useCreateEditRoleMaster