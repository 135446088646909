
export const initialValues = {
  menuId: "",
  menuDisplayName: "",
  sortOrder: "",
  routesUrl: "",
  keyName: "",
  projectId: "",
  activeStatus: 1,

}