import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearMultipleData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetDistrictMasterBySubzone(subzone) {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getDistrictsBySubzoneData = 
    getData(state, apiKeys.districtListBySubzone(subzone), []);
  const getDistrictsBySubzoneStatus = 
    getStatus(state, apiKeys.districtListBySubzone(subzone), '');
  const getDistrictsBySubzoneLoading = apiStatuses.pending === getDistrictsBySubzoneStatus;
  
  const getDistrictsBySubzone = useCallback((inputId) => {
    dispatch(fetchData({
      url: apiUrls.districtListBySubzone,
      method: apiMethods.POST,
      key: apiKeys.districtListBySubzone(subzone),
      body: inputId,
    }))
  }, [subzone, dispatch]);

  const getDistrictsBySubzoneOptions = useMemo(() => {
    return getDistrictsBySubzoneData
    .map(({ districtId, districtName }) => ({
      id: districtId,
      label: districtName
    }))
  }, [getDistrictsBySubzoneData]);


  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: apiKeys.districtListBySubzone(subzone) }));
    },
    [subzone, dispatch]
  );

  return useMemo(
    () => ({
      getDistrictsBySubzoneData,
      getDistrictsBySubzoneStatus,
      getDistrictsBySubzone,
      getDistrictsBySubzoneOptions,
      getDistrictsBySubzoneLoading
    }),

    [
      getDistrictsBySubzoneData,
      getDistrictsBySubzoneStatus,
      getDistrictsBySubzone,
      getDistrictsBySubzoneOptions,
      getDistrictsBySubzoneLoading
    ]
  );
}

export default useGetDistrictMasterBySubzone;
