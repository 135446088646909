import TabsView from "@/common/components/tabs-view/tabs-view";
import React from "react";
import { roleMasterTabs } from "./constants/constants";

export default function RoleMaster() {
    return(
        <TabsView
            tabs={roleMasterTabs}
        />
    )
}