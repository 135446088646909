import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useDistrictMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const districtMasterData  = getData(state, apiKeys.districtMaster, []);
  const saveDistrictMasterStatus = getStatus(state, apiKeys.saveDistrictMaster, '');
  const updateDistrictMasterStatus = getStatus(state, apiKeys.updateDistrictMaster, '');
  const districtMasterStatus = getStatus(state, apiKeys.districtMaster, '');
  const districtMasterErrMsg = getErrorMessage(state, apiKeys.districtMaster, '');
  const deleteDistrictMasterStatus = getStatus(state, apiKeys.deleteDistrictMaster, '');

  const getDistrictMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.districtMaster,
      method: apiMethods.GET,
      key: apiKeys.districtMaster,
      failureMsg: formatMessage({ id: "failed-to-get-district-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getDistrictMaster()
  }, [dispatch, getDistrictMaster]);

  useEffect(() => {
    if (deleteDistrictMasterStatus === apiStatuses.succeeded) {
      getDistrictMaster();
    }
  }, [deleteDistrictMasterStatus, getDistrictMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.districtMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  useEffect(() => {
    if (saveDistrictMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getDistrictMaster();
    } 
  }, [saveDistrictMasterStatus, closeModal, getDistrictMaster]);

  useEffect(() => {
    if (updateDistrictMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getDistrictMaster();
    } 
  }, [updateDistrictMasterStatus, closeModal, getDistrictMaster]);

  return useMemo(() => ({
    districtMasterData,
    districtMasterStatus,
    districtMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    districtMasterData,
    districtMasterStatus,
    districtMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
