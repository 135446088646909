import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetCountryOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getCountryData = getData(state, apiKeys.countryMaster, []);
  const getCountryStatus = getStatus(state, apiKeys.countryMaster, '');
  const getCountryLoading = apiStatuses.pending === getCountryStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.countryMaster,
        method: apiMethods.GET,
        key: apiKeys.countryMaster
      })
    );
  }, [dispatch]);

  const getCountryOptions = useMemo(() => {
    return getCountryData.filter((option) => option.activeStatus === "1")
    .map(({ countryId, countryName }) => ({
      id: countryId,
      label: countryName
    }))
  }, [getCountryData]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.countryMaster,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getCountryData,
      getCountryOptions,
      getCountryStatus,
      getCountryLoading
    }),
    [getCountryData,
      getCountryOptions, getCountryStatus, getCountryLoading]
  );
}

export default useGetCountryOptions;
