import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateEditGradeList(activeEditId) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveGradeListStatus = getStatus(state, apiKeys.saveGradeList, '');
  const updateGradeListStatus = getStatus(state, apiKeys.updateGradeList, '');
  const saveGradeListLoading = apiStatuses.pending === saveGradeListStatus;
  const getEditGradeListData = getData(state, apiKeys.getEditGradeList, {});
  const getEditGradeListStatus = getStatus(state, apiKeys.getEditGradeList, "");
  const getEditGradeListErrMsg = getErrorMessage(
    state,
    apiKeys.getEditGradeList,
    formatMessage({id: "failed-to-get-selected-employees-details"})
  );

  const validationSchema = yup.object({
    gradeId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    gradeListName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateGradeList(values)
      }else{
        saveGradeList(values)
      }
    }
  });
  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditGradeList = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditGradeList(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditGradeList,
        failureMsg: formatMessage({ id: "failed-to-get-selected-grade-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditGradeList()
    }
  }, [activeEditId, getEditGradeList]);

  useEffect(() => {
    if (getEditGradeListStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditGradeListData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditGradeListStatus])

  const saveGradeList = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveGradeList,
        method: apiMethods.POST,
        key: apiKeys.saveGradeList,
        successMsg: formatMessage({ id: "grade-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "grade-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateGradeList = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateGradeList,
        method: apiMethods.PUT,
        key: apiKeys.updateGradeList,
        successMsg: formatMessage({ id: "grade-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "grade-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveGradeList, apiKeys.updateGradeList,
         apiKeys.getEditGradeList] }));
    },
    [dispatch]
  );

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveGradeListStatus,
    updateGradeListStatus,
    saveGradeListLoading,
    getEditGradeListData,
    getEditGradeListStatus,
    getEditGradeListErrMsg,
  }), [
    formik,
    saveGradeListStatus,
    updateGradeListStatus,
    saveGradeListLoading,
    getEditGradeListData,
    getEditGradeListStatus,
    getEditGradeListErrMsg,
  ])
}

export default useCreateEditGradeList