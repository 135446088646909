import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

// project import
import useAuth from '@/from-theme/hooks/useAuth';
import navs from '@/from-theme/routes/constants/nav';
import { isUserHasAccess } from './helpers/helpers';

// ==============================|| AUTH GUARD ||============================== //

const ProtectedRoute = (props) => {
    const {children, requestedMenu, requestedFeature} = props;
    const { userData } = useAuth();

    const entitlement = userData?.entitlement || {};
    const location = useLocation();
    if(isUserHasAccess(requestedMenu, requestedFeature, entitlement)) {
        return children
    }
    return <Navigate to={navs.noAccess(location.pathname)} />;
};

ProtectedRoute.propTypes = {
    children: PropTypes.node,
    requestedMenu: PropTypes.string.isRequired,
    requestedFeature: PropTypes.string.isRequired,
};

export default ProtectedRoute;
