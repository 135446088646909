import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetDistrictMasterByState(stateId) {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getDistrictsByStateData = 
    getData(state, apiKeys.districtListByState(stateId), []);
  const getDistrictsByStateStatus = 
    getStatus(state, apiKeys.districtListByState(stateId), '');
  const getDistrictsByStateLoading = apiStatuses.pending === getDistrictsByStateStatus;
  
  const getDistrictsByState = useCallback((inputId) => {
    dispatch(fetchData({
      url: apiUrls.districtListByState,
      method: apiMethods.POST,
      key: apiKeys.districtListByState(stateId),
      body: inputId,
    }))
  }, [stateId, dispatch]);

  const getDistrictsByStateOptions = useMemo(() => {
    return getDistrictsByStateData
    .map(({ districtId, districtName }) => ({
      id: districtId,
      label: districtName
      
    }))
  }, [getDistrictsByStateData]);


  useEffect(
    () => () => {
      dispatch(clearData({ key: apiKeys.districtListByState(stateId) }));
    },
    [stateId, dispatch]
  );

  return useMemo(
    () => ({
      getDistrictsByStateData,
      getDistrictsByStateStatus,
      getDistrictsByState,
      getDistrictsByStateOptions,
      getDistrictsByStateLoading
    }),

    [
      getDistrictsByStateData,
      getDistrictsByStateStatus,
      getDistrictsByState,
      getDistrictsByStateOptions,
      getDistrictsByStateLoading
    ]
  );
}

export default useGetDistrictMasterByState;
