import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import useCreateEditBrandMaster from "./hooks/useCreateEditBrandMaster";
import PropTypes from "prop-types";
import { apiStatuses } from "@/store/api/constants";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";
import Spinner from "@/common/components/spinner/spinner";

function CreateEditBrandMaster({
  closeModal, activeEditId
}) {

  const {
    values, formik, getEditBrandMasterStatus, getEditBrandMasterErrMsg, saveBrandMasterStatus,
    updateBrandMasterStatus, } = useCreateEditBrandMaster(activeEditId);

  const isPending = [saveBrandMasterStatus, updateBrandMasterStatus,].includes(apiStatuses.pending);

  const {
    brandCode, brandName, activeStatus
  } = values;

  return (
    <>
      {
        getEditBrandMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }

      {getEditBrandMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditBrandMasterErrMsg} />
      )}

      {(getEditBrandMasterStatus === apiStatuses.succeeded || !activeEditId) &&
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25}>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="brand-code" size="small" fullWidth
                  value={brandCode}
                  inputLabel="brand-code"
                  formik={formik}
                  name="brandCode"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="brand-name" size="small" fullWidth
                  value={brandName}
                  inputLabel="brand-name"
                  formik={formik}
                  name="brandName"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} disabled={isPending}
             variant="outlined" sx={{ mr: 1 }}>
              <FormattedMessage id={"cancel"} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
                <FormattedMessage id={"save"} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      }
    </>
  )
}

CreateEditBrandMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditBrandMaster.defaultProps = {
  activeEditId: 0,
};

export default CreateEditBrandMaster;