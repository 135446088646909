import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateEditGradeMaster(activeEditId) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveGradeMasterStatus = getStatus(state, apiKeys.saveGradeMaster, '');
  const updateGradeMasterStatus = getStatus(state, apiKeys.updateGradeMaster, '');
  const saveGradeMasterLoading = apiStatuses.pending === saveGradeMasterStatus;
  const getEditGradeMasterData = getData(state, apiKeys.getEditGradeMaster, {});
  const getEditGradeMasterStatus = getStatus(state, apiKeys.getEditGradeMaster, "");
  const getEditGradeMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditGradeMaster,
    formatMessage({id: "failed-to-get-selected-employees-details"})
  );

  const validationSchema = yup.object({
    gradeName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    gradeCode: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateGradeMaster(values)
      }else{
        saveGradeMaster(values)
      }
    }
  });
  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditGradeMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditGradeMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditGradeMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-grade-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditGradeMaster()
    }
  }, [activeEditId, getEditGradeMaster]);

  useEffect(() => {
    if (getEditGradeMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditGradeMasterData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditGradeMasterStatus])

  const saveGradeMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveGradeMaster,
        method: apiMethods.POST,
        key: apiKeys.saveGradeMaster,
        successMsg: formatMessage({ id: "grade-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "grade-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateGradeMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateGradeMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateGradeMaster,
        successMsg: formatMessage({ id: "grade-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "grade-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveGradeMaster, apiKeys.updateGradeMaster,
         apiKeys.getEditGradeMaster] }));
    },
    [dispatch]
  );

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveGradeMasterStatus,
    updateGradeMasterStatus,
    saveGradeMasterLoading,
    getEditGradeMasterData,
    getEditGradeMasterStatus,
    getEditGradeMasterErrMsg,
  }), [
    formik,
    saveGradeMasterStatus,
    updateGradeMasterStatus,
    saveGradeMasterLoading,
    getEditGradeMasterData,
    getEditGradeMasterStatus,
    getEditGradeMasterErrMsg,
  ])
}

export default useCreateEditGradeMaster