import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetStateOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getStateData = getData(state, apiKeys.stateMaster, []);
  const getStateStatus = getStatus(state, apiKeys.stateMaster, '');
  const getStateLoading = apiStatuses.pending === getStateStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.stateMaster,
        method: apiMethods.GET,
        key: apiKeys.stateMaster
      })
    );
  }, [dispatch]);

  const getStateOptions = useMemo(() => {
    return getStateData.filter((option) => option.activeStatus === "1")
    .map(({ stateId, stateName }) => ({
      id: stateId,
      label: stateName
    }))
  }, [getStateData]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.stateMaster,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getStateData,
      getStateOptions,
      getStateStatus,
      getStateLoading
    }),
    [getStateData, getStateOptions, getStateStatus, getStateLoading]
  );
}

export default useGetStateOptions;
