import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearMultipleData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import {  useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetGradeData() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getGradeData = getData(state, apiKeys.gradeMaster, []);
  const getGradeStatus = getStatus(state, apiKeys.gradeMaster, '');
  const getGradeLoading = apiStatuses.pending === getGradeStatus;


  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.gradeMaster,
        method: apiMethods.GET,
        key: apiKeys.gradeMaster
      })
    );
  }, [dispatch]);

  const getGradeOptions = useMemo(() => {
    return getGradeData.filter((option) => option.activeStatus === "1")
    .map(({ gradeId, gradeName }) => ({
      id: gradeId,
      label: gradeName
    }))
  }, [getGradeData]);


  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.getGradeOptions, apiUrls.gradeMaster,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getGradeData,
      getGradeOptions,
      getGradeStatus,
      getGradeLoading,
    }),
    [  getGradeData,
      getGradeOptions,
      getGradeStatus,
      getGradeLoading,]
  );
}

export default useGetGradeData;
