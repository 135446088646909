import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getErrorMessage, getStatus } from '@/store/slices/helpers/helpers';
import { fetchData, clearData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { initialValues } from '../constants/constants';
import { getFormattedPayload, getFormattedResponse } from '@/common/helpers/helpers';

function useCreateEditCountryMaster(activeEditId) {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveCountryMasterStatus = getStatus(state, apiKeys.saveCountryMaster, '');
  const updateCountryMasterStatus = getStatus(state, apiKeys.updateCountryMaster, '');
  const saveCountryMasterLoading = apiStatuses.pending === saveCountryMasterStatus;
  const getEditCountryMasterData = getData(state, apiKeys.getEditCountryMaster, {});
  const getEditCountryMasterStatus = getStatus(state, apiKeys.getEditCountryMaster, '');
  const getEditCountryMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditCountryMaster,
    formatMessage({ id: 'failed-to-get-selected-country-details' })
  );

  const validationSchema = yup.object({
    countryName: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    activeStatus: yup.boolean().required(formatMessage({ id: 'this-field-is-required' }))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (activeEditId) {
        updateCountryMaster(values);
      } else {
        saveCountryMaster(values);
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditCountryMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditCountryMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditCountryMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-selected-country-details' })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditCountryMaster();
    }
  }, [activeEditId, getEditCountryMaster]);

  useEffect(() => {
    if (getEditCountryMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditCountryMasterData));
    }
  }, [getEditCountryMasterStatus]);

  const saveCountryMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.saveCountryMaster,
          method: apiMethods.POST,
          key: apiKeys.saveCountryMaster,
          successMsg: formatMessage({ id: 'country-master-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'country-master-is-failed-to-save' }),
          body: getFormattedPayload(values)
        })
      );
    },
    [dispatch, formatMessage]
  );

  const updateCountryMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.updateCountryMaster,
          method: apiMethods.PUT,
          key: apiKeys.updateCountryMaster,
          successMsg: formatMessage({ id: 'country-master-is-updated-successfully' }),
          failureMsg: formatMessage({ id: 'country-master-is-failed-to-update' }),
          body: getFormattedPayload({ ...values, id: activeEditId })
        })
      );
    },
    [dispatch, formatMessage, activeEditId]
  );


  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.saveCountryMaster, 
        apiKeys.getEditCountryMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      formik,
      values: formik.values,
      saveCountryMasterStatus,
      updateCountryMasterStatus,
      saveCountryMasterLoading,
      getEditCountryMasterData,
      getEditCountryMasterStatus,
      getEditCountryMasterErrMsg
    }),
    [formik, saveCountryMasterStatus, updateCountryMasterStatus, saveCountryMasterLoading,
       getEditCountryMasterData, getEditCountryMasterStatus, getEditCountryMasterErrMsg]
  );
}

export default useCreateEditCountryMaster;
