import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetDropdownListOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getDropdownListData = getData(state, apiKeys.dropdownlistMaster, []);
  const getDropdownListStatus = getStatus(state, apiKeys.dropdownlistMaster, '');
  const getDropdownListLoading = apiStatuses.pending === getDropdownListStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.dropdownlistMaster,
        method: apiMethods.GET,
        key: apiKeys.dropdownlistMaster
      })
    );
  }, [dispatch]);

  const getDropdownListOptions = useMemo(() => {
    return getDropdownListData.map(({ id, dropListId, dropdownListName }) => ({
      id: id || dropListId,
      label: dropdownListName
    }));
  }, [getDropdownListData]);

  const planningModeOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 1 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const offerOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 2 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const deviceOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 3 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const planIntervalOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 4 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      })); 
  }, [getDropdownListData]);

  const prospectCategoryOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 8 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const ageGroupOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 9 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const prospectTimings = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 10 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const CustCategory = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 16 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const primeCustCategory = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 13 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const priceGroupOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 17 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const attPunchVisitType = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 21 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const planSchGrpOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 22 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const employmentOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 23 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const moduleOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 25 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const transportModeOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 26 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const weekdaysOptions = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 27 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const expenseType = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 30 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const expenseCategory = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 33 && option.activeStatus === "1")
      .map(({ id, dropListId, dropdownListName }) => ({
        id: id !== undefined ? id : dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);


  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.dropdownlistMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getDropdownListData,
      getDropdownListOptions,
      getDropdownListStatus,
      getDropdownListLoading,
      employmentOptions,
      moduleOptions,
      deviceOptions,
      offerOptions,
      ageGroupOptions,
      prospectTimings,
      weekdaysOptions,
      prospectCategoryOptions,
      transportModeOptions,
      planSchGrpOptions,
      planningModeOptions,
      planIntervalOptions,
      primeCustCategory,
      CustCategory,
      attPunchVisitType,
      expenseType,
      expenseCategory,
      priceGroupOptions
    }),
    [
      getDropdownListData,
      getDropdownListOptions,
      getDropdownListStatus,
      getDropdownListLoading,
      employmentOptions,
      moduleOptions,
      deviceOptions,
      offerOptions,
      ageGroupOptions,
      prospectTimings,
      weekdaysOptions,
      prospectCategoryOptions,
      transportModeOptions,
      planSchGrpOptions,
      planningModeOptions,
      planIntervalOptions,
      primeCustCategory,
      CustCategory,
      attPunchVisitType,
      expenseType,
      expenseCategory,
      priceGroupOptions
    ]
  );
}

export default useGetDropdownListOptions;
