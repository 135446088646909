import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, } from "@mui/material";
import { columns, reportFileName } from "./constants/constants";
import CreateEditSecondaryCustomerMaster from
    "./create-edit-secondary-customer-master/create-edit-secondary-customer-master";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import useSecondaryCustomerMaster from "./hooks/useSecondaryCustomerMaster";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";
import useAuth from "@/from-theme/hooks/useAuth";

const {customerMasterKey} = requestedMenuAll;
const { updateKey, deleteKey} = requestedFeaturesAll;

export default function SecondaryCustomer() {
    const { secondaryCustomerMasterData, isCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, secondaryCustomerMasterStatus, secondaryCustomerMasterErrMsg }
        = useSecondaryCustomerMaster();
    const { userData } = useAuth();
    const entitlement = userData?.entitlement || {};
    
    const isUpdateDeleteAccess = isUserHasAccess(customerMasterKey, updateKey, entitlement)
        && isUserHasAccess(customerMasterKey, deleteKey, entitlement);        

    const {
        onRowDelete,
        deleteSecondaryCustomerMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={secondaryCustomerMasterData}
                    columns={columns({ onRowEdit, onRowDelete, 
                        deleteSecondaryCustomerMasterStatus, isUpdateDeleteAccess })}
                    reportFileName={reportFileName}
                    apiStatus={secondaryCustomerMasterStatus}
                    apiErrorMsg={secondaryCustomerMasterErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-secondary-customer-master" : 
                        "edit-secondary-customer-master"}
                        fullScreen
                    >
                        <CreateEditSecondaryCustomerMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
}
