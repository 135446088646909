import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateEditProductMaster(activeEditId) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveProductMasterStatus = getStatus(state, apiKeys.saveProductMaster, '');
  const updateProductMasterStatus = getStatus(state, apiKeys.updateProductMaster, '');
  const saveProductMasterLoading = apiStatuses.pending === saveProductMasterStatus;
  const getEditProductMasterData = getData(state, apiKeys.getEditProductMaster, {});
  const getEditProductMasterStatus = getStatus(state, apiKeys.getEditProductMaster, "");
  const getEditProductMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditProductMaster,
    formatMessage({ id: "failed-to-get-selected-product-details" })
  );

  const validationSchema = yup.object({
    productName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    productCode: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    categoryId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateProductMaster(values)
      }else{
        saveProductMaster(values)
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;
  
  const getEditProductMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditProductMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditProductMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-product-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditProductMaster()
    }
  }, [activeEditId, getEditProductMaster]);

  useEffect(() => {
    if (getEditProductMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditProductMasterData))
    }
  }, [getEditProductMasterStatus])

  const saveProductMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveProductMaster,
        method: apiMethods.POST,
        key: apiKeys.saveProductMaster,
        successMsg: formatMessage({ id: "product-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "product-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateProductMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateProductMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateProductMaster,
        successMsg: formatMessage({ id: "product-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "product-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveProductMaster, 
        apiKeys.getEditProductMaster] }));
    },
    [dispatch]
  );

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveProductMasterStatus,
    updateProductMasterStatus,
    saveProductMasterLoading,
    getEditProductMasterData,
    getEditProductMasterStatus,
    getEditProductMasterErrMsg,
  }), [
    formik,
    saveProductMasterStatus,
    updateProductMasterStatus,
    saveProductMasterLoading,
    getEditProductMasterData,
    getEditProductMasterStatus,
    getEditProductMasterErrMsg,
  ])
}

export default useCreateEditProductMaster