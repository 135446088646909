import { Grid, InputLabel } from '@mui/material';
import './switch-input.scss';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import { useMemo } from 'react';

function SwitchInput({
  value,
  inputLabel,
  leftLabel,
  rightLabel,
  name,
  formik,
  onChange,
}) {
  const formikError = useMemo(() => {
    if (formik) {
      return {
        error: formik?.touched[name] && Boolean(formik?.errors[name]),
        helperText: formik?.touched[name] && formik?.errors[name],
      };
    }
    return {};
  }, [formik, name]);

  return (
    <>
      {!!inputLabel && (
        <InputLabel className='labelClr' required={!formik?.fields[name]?.spec?.optional}
         for={name}>
          <FormattedMessage id={inputLabel} />
        </InputLabel>
      )}

      <Grid container spacing={2} className="switch-input" alignItems="center">
        <Grid item>
          <span>
            <FormattedMessage id={leftLabel} />
          </span>
        </Grid>
        <Grid item>
          <Switch
            id={name}
            style={{ marginBottom: '4px' }}
            checked={value}
            onChange={(e) => {
              const newValue = e.target.checked ? 1 : 0;
              if (formik) {
                formik.setFieldValue(name, newValue);
              } else {
                onChange(newValue);
              }
            }}
          />
        </Grid>
        <Grid item>
          <span>
            <FormattedMessage id={rightLabel} />
          </span>
        </Grid>
      </Grid>
      {!!formikError?.error && (
        <p
          className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall
           MuiFormHelperText-contained css-xfx3vf-MuiFormHelperText-root"
        >
          {formikError?.helperText}
        </p>
      )}
    </>
  );
}

SwitchInput.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputLabel: PropTypes.string,
  name: PropTypes.string,
  formik: PropTypes.object,
  onChange: PropTypes.func,
};

SwitchInput.defaultProps = {
  leftLabel: '',
  rightLabel: '',
  value: 0,
  inputLabel: 'inputLabel',
  formik: null,
  name: '',
  onChange: () => {},
};

export default SwitchInput;
