import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetProductNameOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getProductNameData = getData(state, apiKeys.productMaster, []);
  const getProductNameStatus = getStatus(state, apiKeys.productMaster, '');
  const getProductNameLoading = apiStatuses.pending === getProductNameStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.productMaster,
        method: apiMethods.GET,
        key: apiKeys.productMaster
      })
    );
  }, [dispatch]);

  const getProductNameOptions = useMemo(() => {
    return getProductNameData.filter((option) => option.activeStatus === "1")
    .map(({ faProdCatId, productName }) => ({
      id: faProdCatId,
      label: productName
    }))
  }, [getProductNameData]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.productMaster,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
        getProductNameData,
        getProductNameOptions,
        getProductNameStatus,
        getProductNameLoading
    }),
    [getProductNameData, getProductNameOptions, getProductNameStatus, getProductNameLoading]
  );
}

export default useGetProductNameOptions;
