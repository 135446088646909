import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetZoneOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getZoneData = getData(state, apiKeys.zoneMaster, []);
  const getZoneStatus = getStatus(state, apiKeys.zoneMaster, '');
  const getZoneLoading = apiStatuses.pending === getZoneStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.zoneMaster,
        method: apiMethods.GET,
        key: apiKeys.zoneMaster
      })
    );
  }, [dispatch]);

  const getZoneOptions = useMemo(() => {
    return getZoneData.filter((option) => option.activeStatus === "1")
    .map(({ zoneId, zoneName }) => ({
      id: zoneId,
      label: zoneName
    }))
  }, [getZoneData]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.zoneMaster,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getZoneData,
      getZoneOptions,
      getZoneStatus,
      getZoneLoading
    }),
    [getZoneData, getZoneOptions, getZoneStatus, getZoneLoading]
  );
}

export default useGetZoneOptions;
