import { createBrowserRouter } from 'react-router-dom';

// project import
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import PublicRoutes from './PublicRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter(
    [ LoginRoutes, MainRoutes, PublicRoutes], { basename: process.env.REACT_APP_BASE_NAME }
);

export default router;
