import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";
function useCreateEditSubzoneMaster(activeEditId) {

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveSubzoneMasterStatus = getStatus(state, apiKeys.saveSubzoneMaster, '');
  const updateSubzoneMasterStatus = getStatus(state, apiKeys.updateSubzoneMaster, '');
  const saveSubzoneMasterLoading = apiStatuses.pending === saveSubzoneMasterStatus;
  const getEditSubzoneMasterData = getData(state, apiKeys.getEditSubzoneMaster, {});
  const getEditSubzoneMasterStatus = getStatus(state, apiKeys.getEditSubzoneMaster, "");
  const getEditSubzoneMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditSubzoneMaster,
    formatMessage({ id: "failed-to-get-selected-subzone-details" })
  );

  const validationSchema = yup.object({
    subzoneName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    zoneId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    districtId: yup.array().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateSubzoneMaster(values)
      }else{
        saveSubzoneMaster(values)
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditSubzoneMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditSubzoneMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditSubzoneMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-subzone-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditSubzoneMaster()
    }
  }, [activeEditId, getEditSubzoneMaster]);

  useEffect(() => {
    if (getEditSubzoneMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditSubzoneMasterData))
    }
  }, [getEditSubzoneMasterStatus])

  const saveSubzoneMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveSubzoneMaster,
        method: apiMethods.POST,
        key: apiKeys.saveSubzoneMaster,
        successMsg: formatMessage({ id: "subzone-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "subzone-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateSubzoneMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateSubzoneMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateSubzoneMaster,
        successMsg: formatMessage({ id: "subzone-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "subzone-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  // clear state
  useEffect(() => () => {
    dispatch(clearMultipleData({ key: [apiKeys.saveSubzoneMaster, apiKeys.getEditSubzoneMaster] }));
  }, [dispatch])


  return useMemo(() => ({
    formik,
    values: formik.values,
    saveSubzoneMasterStatus,
    updateSubzoneMasterStatus,
    saveSubzoneMasterLoading,
    getEditSubzoneMasterData,
    getEditSubzoneMasterStatus,
    getEditSubzoneMasterErrMsg,
  }), [
    formik,
    saveSubzoneMasterStatus,
    updateSubzoneMasterStatus,
    saveSubzoneMasterLoading,
    getEditSubzoneMasterData,
    getEditSubzoneMasterStatus,
    getEditSubzoneMasterErrMsg,
  ])
}

export default useCreateEditSubzoneMaster