import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useRoleMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveRoleMasterStatus = getStatus(state, apiKeys.saveRoleMaster, '');
  const updateRoleMasterStatus = getStatus(state, apiKeys.updateRoleMaster, '');
  const roleMasterData = getData(state, apiKeys.roleMaster, []);
  const roleMasterStatus = getStatus(state, apiKeys.roleMaster, '');
  const roleMasterErrMsg = getErrorMessage(state, apiKeys.roleMaster, '');
  const deleteRoleMasterStatus = getStatus(state, apiKeys.deleteRoleMaster, '');

  const getRoleMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.roleMaster,
      method: apiMethods.GET,
      key: apiKeys.roleMaster,
      failureMsg: formatMessage({ id: "failed-to-get-role-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getRoleMaster()
  }, [dispatch, getRoleMaster]);

  useEffect(() => {
    if (deleteRoleMasterStatus === apiStatuses.succeeded) {
      getRoleMaster();
    }
  }, [deleteRoleMasterStatus, getRoleMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.roleMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  
  useEffect(() => {
    if (saveRoleMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getRoleMaster();
    } 
  }, [saveRoleMasterStatus, closeModal, getRoleMaster]);

  useEffect(() => {
    if (updateRoleMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getRoleMaster();
    } 
  }, [updateRoleMasterStatus, closeModal, getRoleMaster]);


  return useMemo(() => ({
    roleMasterData,
    roleMasterStatus,
    roleMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    roleMasterData,
    roleMasterStatus,
    roleMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
