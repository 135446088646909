import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetDistrictOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getDistrictData = getData(state, apiKeys.districtMaster, []);
  const getDistrictStatus = getStatus(state, apiKeys.districtMaster, '');
  const getDistrictLoading = apiStatuses.pending === getDistrictStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.districtMaster,
        method: apiMethods.GET,
        key: apiKeys.districtMaster
      })
    );
  }, [dispatch]);

  const getDistrictOptions = useMemo(() => {
    return getDistrictData.filter((option) => option.activeStatus === "1")
    .map(({ districtId, districtName }) => ({
      id: districtId,
      label: districtName
    }))
  }, [getDistrictData]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.districtMaster,] }));
    },
    [dispatch]
  );


  return useMemo(
    () => ({
      getDistrictData,
      getDistrictOptions,
      getDistrictStatus,
      getDistrictLoading
    }),
    [getDistrictData, getDistrictOptions, getDistrictStatus, getDistrictLoading]
  );
}

export default useGetDistrictOptions;
