const navs = {
    employeeMaster: "/employee-master",
    userMaster: "/user-master",
    editEmployeeMaster: (id) => `/employee-master/edit/${id}`,
    editUserMaster: (id) => `/user-master/edit/${id}`,
    editIfdCompetitorMaster: (id) => `/ifd-competitor-master/edit/${id}`,
    editCompetitorOffers: (id) => `/competitor-offers/edit/${id}`,
    editDivisionMaster: (id) => `/division-master/edit/${id}`,
    editEmployeeMapping: (id) => `/employee-mapping/edit/${id}`,
    editOutletMaster: (id) => `/outlet-master/edit/${id}`,
    editPlantMaster: (id) => `/plant-master/edit/${id}`,
    editVenueMaster: (id) => `/venue-master/edit/${id}`,
    editProspectMaster: (id) => `/prospect-master/edit/${id}`,
    getEditMenuAccess: (id) => `/menu-access/edit/${id}`,
    editShiftMaster: (id) => `/shift-master/edit/${id}`,
    editPjpMaster: (id) => `/pjp-creation/edit/${id}`,
    viewBOPlanApproval: (id) =>`/plan-validation/edit/${id}`,
    viewClaimExpense: (id) =>`/claim-details-report/view/${id}`,
    viewBOPlanDevApproval: (id) =>`/plan-deviation/edit/${id}`,
    viewProspectApproval: (id) =>`/prospect-validation/edit/${id}`,
    viewClaimBOIFApproval: (id) =>`/approval-claim-backoffice/edit/${id}`,
    viewClaimSmartForm: (id) =>`/claim-details-report/edit/${id}`,
    ClaimReportView: `/claim-details-report`,
    ClaimApprovalBOIF: `/approval-claim-backoffice`,
    divisionMaster: "/division-master",
    plantMaster: "/plant-master",
    login: "/login",
    attendanceReport: "/attendance-report",
    appTrackReport: "/app-track-report",
    travelReport: "/travel-report",
    prospectMaster: "/prospect-master",
    shiftMaster: "/shift-master",
    dropdown: "/dropdown-master",
    dropdownlist: "/dropdownlist",
    ifdCompetitorMaster: "/ifd-competitor-master",
    venueMaster: "/venue-master",
    menuMaster: "/menu-master",
    areas: "/area-master",
    outletMaster: "/outlet-master",
    PrimaryCustomerMaster: "/customer-master",
    competitorOffers: "/competitor-offers",
    companyMasterCreate: "/company-master/create",
    claimCreate: "/claim-creation/create",
    ifdCompetitorMasterCreate: "/ifd-competitor-master/create",
    fdCompetitorMasterCreate: "/fd-competitor-master/create",
    competitorOffersCreate: "/competitor-offers/create",
    divisionMasterCreate: "/division-master/create",
    primaryCustomerMasterCreate: "/primary-customer-master/create",
    employeeMasterCreate: "/employee-master/create",
    venueMasterCreate: "/venue-master/create",
    userMasterCreate: "/user-master/create",
    employeeMappingCreate: "/employee-mapping/create",
    userMappingCreate: "/user-mapping/create",
    plantMasterCreate: "/plant-master/create",
    menuAccessCreate: "/menu-access/create",
    shiftMasterCreate: "/shift-master/create",
    planCreationCreate: "/pjp-creation/create",
    outletMasterCreate: "/outlet-master/create",
    prospectMasterCreate: "/prospect-master/create",    
    bOPlanApproval: "/plan-validation",   
    ifoodsPlanDeviation: "/plan-deviation",   
    PlanApprovalFoods: "/approval-foods",   
    claimCreation: "/claim-creation",   
    ProspectValidation: "/prospect-validation",   
    pjpMaster: "/pjp-creation",   
    terms: "/terms",   
    policy: "/policy",   
    deactivate: "/deactivate",   
 
    noAccess: (redirectedFrom) => `/no-access${redirectedFrom
        ? `?redirectedFrom=${redirectedFrom}` : ""}`,
}

export default navs;