import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useDropdownsMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveDropdownMasterStatus = getStatus(state, apiKeys.saveDropdownMaster, '');
  const updateDropdownMasterStatus = getStatus(state, apiKeys.updateDropdownMaster, '');
  const dropdownMasterData = getData(state, apiKeys.dropdownMaster, []);
  const dropdownMasterStatus = getStatus(state, apiKeys.dropdownMaster, '');
  const dropdownMasterErrMsg = getErrorMessage(state, apiKeys.dropdownMaster, '');
  const deleteDropdownMasterStatus = getStatus(state, apiKeys.deleteDropdownMaster, '');

  const getDropdownMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.dropdownMaster,
      method: apiMethods.GET,
      key: apiKeys.dropdownMaster,
      failureMsg: formatMessage({ id: "failed-to-get-dropdown-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getDropdownMaster()
  }, [dispatch, getDropdownMaster]);

  useEffect(() => {
    if (deleteDropdownMasterStatus === apiStatuses.succeeded) {
      getDropdownMaster();
    }
  }, [deleteDropdownMasterStatus, getDropdownMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.dropdownMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  useEffect(() => {
    if (saveDropdownMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getDropdownMaster();
    } 
  }, [saveDropdownMasterStatus, closeModal, getDropdownMaster]);

  useEffect(() => {
    if (updateDropdownMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getDropdownMaster();
    } 
  }, [updateDropdownMasterStatus, closeModal, getDropdownMaster]);


  return useMemo(() => ({
    dropdownMasterData,
    dropdownMasterStatus,
    dropdownMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    dropdownMasterData,
    dropdownMasterStatus,
    dropdownMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
