import { Box } from "@mui/material";
import PropTypes from 'prop-types';

function TabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.oneOfType[PropTypes.string, PropTypes.number],
    index: PropTypes.oneOfType[PropTypes.string, PropTypes.number],
  };

  export default TabPanel;