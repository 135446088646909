import { Outlet } from 'react-router-dom';

// project import

// ==============================|| LAYOUT - AUTH ||============================== //

const PublicLayout = () => (
    <Outlet />
);

export default PublicLayout;
