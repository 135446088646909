import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearMultipleData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetDropdownListByDropdown() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getDropdownListByDropdownData = 
    getData(state, apiKeys.dropdownListByDropdown, []);
  const getDropdownListByDropdownStatus = 
    getStatus(state, apiKeys.dropdownListByDropdown, '');
  const getDropdownListByDropdownLoading = 
    apiStatuses.pending === getDropdownListByDropdownStatus;


  const getDropdownListByDropdown = useCallback(
    (inputId) => {
      dispatch(
        fetchData({
          url: apiUrls.dropdownListByDropdown(inputId),
          method: apiMethods.GET,
          key: apiKeys.dropdownListByDropdown,
        })
      );
    },
    [ dispatch]
  );

  // const getDropdownListByDropdownOptions = useMemo(() => {
  //   return getDropdownListByDropdownData.map(({ id, label }) => ({
  //     id: id,
  //     label: label
  //   }));
  // }, [getDropdownListByDropdownData]);

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.dropdownListByDropdown] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getDropdownListByDropdownData,
      getDropdownListByDropdownStatus,
      getDropdownListByDropdown,
      // getDropdownListByDropdownOptions,
      getDropdownListByDropdownLoading
    }),

    [
      getDropdownListByDropdownData,
      getDropdownListByDropdownStatus,
      getDropdownListByDropdown,
      // getDropdownListByDropdownOptions,
      getDropdownListByDropdownLoading
    ]
  );
}

export default useGetDropdownListByDropdown;
