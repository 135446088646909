import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useMenuAccess() {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const menuAccessData = getData(state, apiKeys.menuAccess, []);
  const menuAccessStatus = getStatus(state, apiKeys.menuAccess, '');
  const menuAccessErrMsg = getErrorMessage(state, apiKeys.menuAccess, '');
  const deleteMenuAccessStatus = getStatus(state, apiKeys.deleteMenuAccess, '');

  const getMenuAccess = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.menuAccess,
      method: apiMethods.GET,
      key: apiKeys.menuAccess,
      failureMsg: formatMessage({ id: "failed-to-get-menu-access" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getMenuAccess()
  }, [dispatch, getMenuAccess]);

  useEffect(() => {
    if (deleteMenuAccessStatus === apiStatuses.succeeded) {
      getMenuAccess();
    }
  }, [deleteMenuAccessStatus, getMenuAccess])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.menuAccess }));
  }, [dispatch]);


  return useMemo(() => ({
    menuAccessData,
    menuAccessStatus,
    menuAccessErrMsg,
   
  }), [
    menuAccessData,
    menuAccessStatus,
    menuAccessErrMsg,
 
  ])
}
