import { Box, Tab, Tabs } from '@mui/material';

import React, { useState } from "react";

import TabPanel from "./components/tab-panel";
import MainCard from '@/from-theme/components/MainCard';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

export default function TabsView({
    tabs, selectedTab, onChange, title
}) {
    const [currentTab, setCurrentTab] = useState(0);
    const { formatMessage } = useIntl();


    // The value of selected tab should be index based on mui
    const handleChange = (event, newValue) => {
        if (selectedTab === undefined) {
            setCurrentTab(newValue);
        } else {
            onChange(event, newValue)
        }
    };

    const value = selectedTab === undefined ? currentTab : selectedTab;

    return (
        <MainCard>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={(event, newValue) => handleChange(event, newValue)}
                        aria-label={title || ""}
                    >
                        {tabs.map(({ id, tabName, icon }) => (
                            <Tab
                                key={`tab-name_${id}`}
                                label={formatMessage({ id: tabName })}
                                {...(icon ? { icon, iconPosition: "start" } : {})}
                                id={id}
                                aria-controls={tabName}
                            />
                        ))}
                    </Tabs>
                </Box>
                {tabs.map(({ id, tabContent }, index) => (
                    <TabPanel
                        key={`tab-content_${id}`}
                        value={value}
                        index={index}
                    >
                        {tabContent}
                    </TabPanel>
                ))}
            </Box>
        </MainCard>
    )
}


TabsView.propTypes = {
    onChange: PropTypes.func,
    selectedTab: PropTypes.string,
    tabs: PropTypes.array.isRequired,
    title: PropTypes.string,
}

TabsView.defaultProps = {
    naselectedTabme: "",
    onChange: () => { },
    title: "",
}