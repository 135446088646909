import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import Dropdown from "@/common/components/dropdown/dropdown";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import useCreateEditEnergyPriceMaster from "./hooks/useCreateEditEnergyPriceMaster";
import PropTypes from "prop-types";
import DateInput from '@/common/components/date-input/date-input';
import { apiStatuses } from "@/store/api/constants";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";
import useGetVehicleEnergyType from "@/common/hooks/useGetvehicleEnergyType";

function CreateEditEnergyPriceMaster({
  closeModal, activeEditId
}) {

  const { getvehicleETOptions, getvehicleETLoading } = useGetVehicleEnergyType();
  const {
    values, formik, getEditEnergyPriceMasterStatus,
    getEditEnergyPriceMasterErrMsg
  } = useCreateEditEnergyPriceMaster(activeEditId);

  const {
    energyPriceRange, perKmPrice, energyTypeId, epValidFrom, epValidTo, activeStatus
  } = values;

  return (
    <>
      {
        getEditEnergyPriceMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }

      {getEditEnergyPriceMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditEnergyPriceMasterErrMsg} />
      )}

      {(getEditEnergyPriceMasterStatus === apiStatuses.succeeded || !activeEditId) &&
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25}>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="energyprice-range" size="small" fullWidth
                  value={energyPriceRange}
                  inputLabel="energyprice-range"
                  formik={formik}
                  name="energyPriceRange"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="perkm-price" size="small" fullWidth
                  value={perKmPrice}
                  inputLabel="perkm-price"
                  formik={formik}
                  name="perKmPrice"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <Dropdown
                  options={getvehicleETOptions}
                  loading={getvehicleETLoading}
                  placeholder="energy-type-name"
                  inputLabel="energy-type-name"
                  value={energyTypeId}
                  formik={formik}
                  name="energyTypeId"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <DateInput inputLabel="valid-from" placeholder="valid-from" 
                value={epValidFrom} formik={formik} name="epValidFrom" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <DateInput inputLabel="valid-to" placeholder="valid-to"
                 value={epValidTo} formik={formik} name="epValidTo" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} variant="outlined" sx={{ mr: 1 }}>
              <FormattedMessage id={"cancel"} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" sx={{ mr: 1 }}>
                <FormattedMessage id={"save"} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      }
    </>
  )
}


CreateEditEnergyPriceMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditEnergyPriceMaster.defaultProps = {
  activeEditId: 0,
};

export default CreateEditEnergyPriceMaster;