import { useEffect, useMemo, useState, useCallback } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods,apiStatuses, apiUrls } from "@/store/api/constants";

export default function useDepartmentsMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const {formatMessage} = useIntl();
  
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const departmentMasterData  = getData(state, apiKeys.departmentMaster, []);
  const saveDepartmentMasterStatus = getStatus(state, apiKeys.saveDepartmentMaster, '');
  const updateDepartmentMasterStatus = getStatus(state, apiKeys.updateDepartmentMaster, '');
  const departmentMasterStatus = getStatus(state, apiKeys.departmentMaster, '');
  const departmentMasterErrMsg = getErrorMessage(state, apiKeys.departmentMaster, '');
  const deleteDepartmentMasterStatus = getStatus(state, apiKeys.deleteDepartmentMaster, '');

  const getDepartmentMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.departmentMaster,
      method: apiMethods.GET,
      key: apiKeys.departmentMaster,
      failureMsg: formatMessage({ id: "failed-to-get-department-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getDepartmentMaster()
  }, [dispatch, getDepartmentMaster]);

  useEffect(() => {
    if (deleteDepartmentMasterStatus === apiStatuses.succeeded) {
      getDepartmentMaster();
    }
  }, [deleteDepartmentMasterStatus, getDepartmentMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.departmentMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  
  useEffect(() => {
    if (saveDepartmentMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getDepartmentMaster();
    } 
  }, [saveDepartmentMasterStatus, closeModal, getDepartmentMaster]);

  useEffect(() => {
    if (updateDepartmentMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getDepartmentMaster();
    } 
  }, [updateDepartmentMasterStatus, closeModal, getDepartmentMaster]);

    return useMemo(() => ({
      departmentMasterData,
      departmentMasterStatus,
      departmentMasterErrMsg,
      isCreateEditModalOpen,
      setIsCreateEditModalOpen,
      onRowEdit,
      activeEditId,
      closeModal
    }),[
      departmentMasterData,
      departmentMasterStatus,
      departmentMasterErrMsg,
      isCreateEditModalOpen,
      setIsCreateEditModalOpen,
      onRowEdit,
      activeEditId,
      closeModal
    ])
}
