import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getErrorMessage, getStatus } from '@/store/slices/helpers/helpers';
import { fetchData, clearMultipleData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { initialValues } from '../constants/constants';
import { getFormattedPayload, getFormattedResponse } from '@/common/helpers/helpers';

function useCreateEditMenuMaster(activeEditId) {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveMenuMasterStatus = getStatus(state, apiKeys.saveMenuMaster, '');
  const updateMenuMasterStatus = getStatus(state, apiKeys.updateMenuMaster, '');
  const saveMenuMasterLoading = apiStatuses.pending === saveMenuMasterStatus;
  const getEditMenuMasterData = getData(state, apiKeys.getEditMenuMaster, {});
  const getEditMenuMasterStatus = getStatus(state, apiKeys.getEditMenuMaster, '');
  const getEditMenuMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditMenuMaster,
    formatMessage({ id: 'failed-to-get-selected-menu-details' })
  );

  const validationSchema = yup.object({
    baseUrl: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    menuName: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    sortOrderId: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    subMenuAvail: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    activeStatus: yup.boolean().required(formatMessage({ id: 'this-field-is-required' }))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (activeEditId) {
        updateMenuMaster(values);
      } else {
        saveMenuMaster(values);
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditMenuMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditMenuMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditMenuMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-selected-menu-details' })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditMenuMaster();
    }
  }, [activeEditId, getEditMenuMaster]);

  useEffect(() => {
    if (getEditMenuMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditMenuMasterData));
    }
  }, [getEditMenuMasterStatus]);

  const saveMenuMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.saveMenuMaster,
          method: apiMethods.POST,
          key: apiKeys.saveMenuMaster,
          successMsg: formatMessage({ id: 'menu-master-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'menu-master-is-failed-to-save' }),
          body: getFormattedPayload(values)
        })
      );
    },
    [dispatch, formatMessage]
  );

  const updateMenuMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.updateMenuMaster,
          method: apiMethods.PUT,
          key: apiKeys.updateMenuMaster,
          successMsg: formatMessage({ id: 'menu-master-is-updated-successfully' }),
          failureMsg: formatMessage({ id: 'menu-master-is-failed-to-update' }),
          body: getFormattedPayload({ ...values, id: activeEditId })
        })
      );
    },
    [dispatch, formatMessage, activeEditId]
  );


  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveMenuMaster,
        apiKeys.updateMenuMaster, apiKeys.getEditMenuMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      formik,
      values: formik.values,
      saveMenuMasterStatus,
      updateMenuMasterStatus,
      saveMenuMasterLoading,
      getEditMenuMasterData,
      getEditMenuMasterStatus,
      getEditMenuMasterErrMsg
    }),
    [formik, saveMenuMasterStatus, updateMenuMasterStatus, saveMenuMasterLoading,
       getEditMenuMasterData, getEditMenuMasterStatus, getEditMenuMasterErrMsg]
  );
}

export default useCreateEditMenuMaster;
