
export const initialValues = {
  secCusCode: undefined,
  secCusName: undefined,
  secCusCategory: undefined,
  secCusGstNo: undefined,
  address: undefined,
  landmark: undefined,
  subzoneId: undefined,
  cityId: undefined,
  contactPerson: undefined,
  contactNo: undefined,
  latitude: undefined,
  longitude: undefined,
  activeStatus: 1,

}
