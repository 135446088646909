import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";
import React from "react";
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";

export default function AlertDialog({
    onCloseAlert, onAgreeAlert, title, description
}) {
  const {formatMessage} = useIntl();

    return (
        <Dialog
            open={open} onClose={onCloseAlert} aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box sx={{ p: 1, py: 1.5 }}>
                <DialogTitle id="alert-dialog-title">
                    {title || formatMessage({id: "are-you-sure-you-want-to-delete-this-item"})}
                </DialogTitle>
                {!!description && (
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                )}
                <DialogActions>
                    <Button color="error" onClick={onCloseAlert}>
                    Disagree
                    </Button>
                    <Button variant="contained" onClick={onAgreeAlert} autoFocus>
                    Agree
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}


AlertDialog.propTypes = {
    onCloseAlert: PropTypes.func.isRequired,
    onAgreeAlert: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,

};