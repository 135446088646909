import TabsView from "@/common/components/tabs-view/tabs-view";
import React from "react";
import { claimMasterTabs } from "./constants/constants";

export default function ClaimMaster() {
    return(
        <TabsView
            tabs={claimMasterTabs}
        />
    )
}