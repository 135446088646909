import PropTypes from 'prop-types';

// import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

// ==============================|| CSV EXPORT ||============================== //

const PDFExport = ({ data, filename, headers }) => {

  const handleExportRows = () => {
    const doc = new jsPDF();
    const tableHeadersLabels = headers.map((c) => c.label);
    const tableHeadersKeys = headers.map((c) => c.key);

    const tableData = data.map((item) => tableHeadersKeys.map((item2) => item[item2]));

    autoTable(doc, {
      head: [tableHeadersLabels],
      body: tableData,
    });

    doc.save(`${filename || "report"}.pdf`);
  };

  return (
    // <Button
    //   variant="contained"
    //   onClick={() => handleExportRows()}
    //   disabled={data.length === 0}
    // >
    //   Export in PDF
    //   {/* use locale here */}
    // </Button>
    <IconButton
    color="error"
    aria-label="print"
    variant="contained"
    onClick={() => handleExportRows()}
    disabled={data.length === 0}
  >
    <PrintIcon />
  </IconButton>
  );
};

PDFExport.propTypes = {
  filename: PropTypes.string,
  data: PropTypes.array,
  headers: PropTypes.array
};

export default PDFExport;
