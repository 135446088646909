
export const initialValues = {
  energyPriceRange: "",
  perKmPrice: "",
  energyTypeId: "",
  epValidFrom: "",
  epValidTo: "",
  activeStatus: 1,
}

