import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import useDistrictMaster from "./hooks/useDistrictMaster";
import CreateEditDistrictMaster from "./create-district-master/create-district-master";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";

const DistrictMaster = () => {
    const { districtMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, districtMasterStatus, districtMasterErrMsg }
        = useDistrictMaster();
    const {
        onRowDelete,
        deleteDistrictMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();
    const actionButton = (
        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => setIsCreateEditModalOpen(true)}
        ><FormattedMessage id="add-district" />
        </Button>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={districtMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteDistrictMasterStatus })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={districtMasterStatus}
                    apiErrorMsg={districtMasterErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-district-master" :
                         "edit-district-master"}
                    >
                        <CreateEditDistrictMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
};

export default DistrictMaster;