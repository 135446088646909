import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from '@/from-theme/contexts/auth-reducer/actions';
import authReducer from '@/from-theme/contexts/auth-reducer/auth';

// project import
import Loader from '@/from-theme/components/Loader';
import axios from '@/from-theme/utils//axios';
import { getResponseInterceptor } from '@/store/api/helpers/helpers';
import { apiBaseUrl } from '@/store/api/constants/baseURL';


const chance = new Chance();


// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  userData: null
};

// eslint-disable-next-line no-unused-vars
const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
   // eslint-disable-next-line max-len
   // Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        // const serviceToken = window.localStorage.getItem('serviceToken');
        // if (serviceToken && verifyToken(serviceToken)) {
        // if (serviceToken) {
       const responseOriginal = await axios.get(apiBaseUrl + "authenticate");
          // const responseOriginal = await axios.get('mock/authenticate');
        const response = getResponseInterceptor(responseOriginal);
        const { data = {}, serviceToken = "", entitlement = {} } = response?.data?.response || {};
        if(response?.status === 200 && response?.data?.status === true) {
          setSession(response?.data?.data?.serviceToken);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              data,
              userData: {data, serviceToken, entitlement}
            },
            
          });
    
        } else {
          dispatch({
            type: LOGOUT
          });
          
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (login_info, password) => {
    const responseOriginal = 
   await axios.post(apiBaseUrl + 'login', { login_info, password, login_source:6 });
     const response = getResponseInterceptor(responseOriginal);
     const { data = {}, serviceToken = "", entitlement = {} } = response?.data?.response || {};
     if(response?.status === 200 && response?.data?.status === true) {
       setSession(response?.data?.data?.serviceToken);
       dispatch({
         type: LOGIN,
         payload: {
           isLoggedIn: true,
           data:{data},
           userData: {data, serviceToken, entitlement}
         },
       });
     } else {
       dispatch({
         type: LOGOUT
       });
       throw {message: response?.data?.response?.message};
     }
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/account/register', {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;

    if (
      window.localStorage.getItem('users') !== undefined
      && window.localStorage.getItem('users') !== null
    ) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

  // const logout = () => {
  //   setSession(null);
  //   dispatch({ type: LOGOUT });
  // };

  const logout = async () => {
    const responseOriginal = await axios.post(apiBaseUrl + 'logout', { });
    const response = getResponseInterceptor(responseOriginal);
    setSession(null);
    dispatch({ type: LOGOUT });
    const {message = "" } = response?.data?.response || {};
    throw {message: message};
  };

  const resetPassword = async (mobile) => {
   await axios.post(apiBaseUrl + 'checkSms', { mobile });
      //  dispatch({
      //    payload: {
      //      isLoggedIn: true,
      //      userData: {loginInfo },
      //    }
      //  });
  };

  const updatePassword = async (password) => {
    await axios.post(apiBaseUrl + 'checkSms', { password });
       //  dispatch({
       //    payload: {
       //      isLoggedIn: true,
       //      userData: {loginInfo },
       //    }
       //  });
   };

  const otpValidation = async (mobile, otp_verify ) => {
    await axios.post(apiBaseUrl + 'otp_verify', { mobile, otp_verify });
       //  dispatch({
       //    payload: {
       //      isLoggedIn: true,
       //      userData: {loginInfo },
       //    }
       //  });
   };

  const deactivate = async () => {
     const responseOriginal =  await axios.post(apiBaseUrl + 'user-deactivate-account', { });
     const response = getResponseInterceptor(responseOriginal);
     const { data = '', message = '' } = response?.data?.response || {};
        dispatch({
          type: LOGOUT,
        });
        throw {message: data + message};
   };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
  <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile,
    deactivate, otpValidation, updatePassword }}>
    {children}
  </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
