import React, { useMemo } from 'react';
import { InputLabel, TextField, InputAdornment } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import './text-input.scss';

function TextInputIcon({ type, placeholder, value, inputLabel, name, rows, formik, onChange,
   onBlur, onSearchIconClick, ...props }) {
  const { formatMessage } = useIntl();

  const formikError = useMemo(() => {
    if (formik) {
      return {
        error: formik?.touched[name] && Boolean(formik?.errors[name]),
        helperText: formik?.touched[name] && formik?.errors[name],
      };
    }
    return {};
  }, [formik, name]);

  return (
    <>
      {!!inputLabel && (
        <InputLabel className="labelClr" required={!formik?.fields[name]?.spec?.optional}
         htmlFor={name}>
          <FormattedMessage id={inputLabel} />
        </InputLabel>
      )}
      <TextField
        {...props}
        id={name}
        type={type || 'text'}
        placeholder={placeholder ? formatMessage({ id: placeholder }) : ''}
        defaultValue=""
        value={value}
        onChange={(e) => {
          if (formik) {
            formik.setFieldValue(name, e.target.value);
          } else {
            onChange(e);
          }
        }}
        onBlur={onBlur}
        {...formikError}
        fullWidth
        multiline
        rows={rows}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchOutlined style={{ cursor: 'pointer' }} onClick={onSearchIconClick} />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

TextInputIcon.propTypes = {
  type: PropTypes.oneOf(['hidden', 'number', 'text']),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  inputLabel: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.string,
  formik: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onSearchIconClick: PropTypes.func, // Added prop type for search icon click
};

TextInputIcon.defaultProps = {
  type: 'text',
  placeholder: '',
  value: '',
  inputLabel: '',
  formik: null,
  name: '',
  rows: '1',
  onChange: () => {},
  onBlur: () => {},
  onSearchIconClick: () => {}, // Added default prop for search icon click
};

export default TextInputIcon;
