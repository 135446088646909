import React from 'react';
import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import TextInput from '@/common/components/text-input/text-input';
import Dropdown from '@/common/components/dropdown/dropdown';
import SwitchInput from '@/common/components/switch-input/switch-input';
import AnimateButton from '@/from-theme/components/@extended/AnimateButton';
import { FormattedMessage } from 'react-intl';
import useCreateEditMenuList from './hooks/useCreateEditMenuList';
import useGetDropdownListMaster from '@/common/hooks/useGetDropdownListMaster';
import useGetMenuMaster from '@/common/hooks/useGetMenuMaster';
import PropTypes from 'prop-types';
import { apiStatuses } from '@/store/api/constants';
import ErrorInPage from '@/common/components/error-in-page/error-in-page';
import Spinner from '@/common/components/spinner/spinner';

function CreateEditMenuListMaster({ closeModal, activeEditId }) {
  const { moduleOptions, getDropdownListLoading } = useGetDropdownListMaster();
  const { getMainmenuOptions, getMainmenuLoading } = useGetMenuMaster();
  const { values, formik, getEditMenuListMasterStatus, getEditMenuListMasterErrMsg,
    saveMenuListMasterStatus, updateMenuListMasterStatus } = useCreateEditMenuList(activeEditId);
  const { menuId, menuDisplayName, sortOrder, routesUrl, keyName, projectId, activeStatus }
   = values;

  const isPending = [saveMenuListMasterStatus, updateMenuListMasterStatus]
  .includes(apiStatuses.pending);


   const handleKeyNameBlur = () => {
    formik.setFieldTouched('keyName', true, true); // Mark field as touched
  };

  return (
    <>
      {getEditMenuListMasterStatus === apiStatuses.pending && activeEditId && <Spinner />}
      {getEditMenuListMasterStatus === apiStatuses.rejected && 
      activeEditId && <ErrorInPage message={getEditMenuListMasterErrMsg} />}
      {(getEditMenuListMasterStatus === apiStatuses.succeeded || !activeEditId) && (
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25}>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <Dropdown
                  options={getMainmenuOptions}
                  loading={getMainmenuLoading}
                  placeholder="menu-name"
                  inputLabel="menu-name"
                  value={menuId}
                  formik={formik}
                  name="menuId"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <Dropdown
                  options={moduleOptions}
                  loading={getDropdownListLoading}
                  placeholder="project-name"
                  inputLabel="project-name"
                  value={projectId}
                  formik={formik}
                  name="projectId"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="menu-display-name"
                  size="small"
                  fullWidth
                  value={menuDisplayName}
                  inputLabel="menu-display-name"
                  formik={formik}
                  name="menuDisplayName"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="menu-url"
                  size="small"
                  fullWidth
                  value={routesUrl}
                  inputLabel="menu-url"
                  formik={formik}
                  name="routesUrl"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="key-name"
                  size="small"
                  fullWidth
                  value={keyName}
                  inputLabel="key-name"
                  formik={formik}
                  name="keyName"
                  onBlur={handleKeyNameBlur}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="order-id"
                  size="small"
                  fullWidth
                  value={sortOrder}
                  inputLabel="order-id"
                  formik={formik}
                  name="sortOrder"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending} 
             sx={{ mr: 1 }}>
              <FormattedMessage id={'cancel'} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
                <FormattedMessage id={'save'} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      )}
    </>
  );
}


CreateEditMenuListMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditMenuListMaster.defaultProps = {
  activeEditId: 0,
};


export default CreateEditMenuListMaster;
