
import {
    BookOutlined, UserOutlined
} from '@ant-design/icons';
import Zone from '../components/zone/zone';
import Subzone from '../components/subzone/subzone';

// tabName is locale id
export const zoneMasterTabs = [
    {
        id: "zone",
        tabName: "zone", 
        icon: <UserOutlined />,
        tabContent: <Zone />
    },
    {
        id: "subzone",
        tabName: "subzone",
        icon: <BookOutlined />,
        tabContent: <Subzone />
    },
   
]