import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getErrorMessage, getStatus } from '@/store/slices/helpers/helpers';
import { fetchData, clearMultipleData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { initialValues } from '../constants/constants';
import { getFormattedPayload, getFormattedResponse } from '@/common/helpers/helpers';

function useCreateEditAllowanceMaster(activeEditId) {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveAllowanceMasterStatus = getStatus(state, apiKeys.saveAllowanceMaster, '');
  const updateAllowanceMasterStatus = getStatus(state, apiKeys.updateAllowanceMaster, '');
  const saveAllowanceMasterLoading = apiStatuses.pending === saveAllowanceMasterStatus;
  const getEditAllowanceMasterData = getData(state, apiKeys.getEditAllowanceMaster, {});
  const getEditAllowanceMasterStatus = getStatus(state, apiKeys.getEditAllowanceMaster, '');
  const getEditAllowanceMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditAllowanceMaster,
    formatMessage({ id: 'failed-to-get-selected-allowance-details' })
  );

  const validationSchema = yup.object({
    hqDaCost: yup
      .string()
      .required(formatMessage({ id: 'this-field-is-required' }))
      .matches(/^[0-9]+$/, formatMessage({ id: 'only-numeric-characters-allowed' })),
    exHqDaCost: yup
      .string()
      .required(formatMessage({ id: 'this-field-is-required' }))
      .matches(/^[0-9]+$/, formatMessage({ id: 'only-numeric-characters-allowed' })),
    daGradeId: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    daValidFrom: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    daValidTo: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    activeStatus: yup.boolean().required(formatMessage({ id: 'this-field-is-required' }))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (activeEditId) {
        updateAllowanceMaster(values);
      } else {
        saveAllowanceMaster(values);
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditAllowanceMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditAllowanceMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditAllowanceMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-selected-allowance-details' })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditAllowanceMaster();
    }
  }, [activeEditId, getEditAllowanceMaster]);

  useEffect(() => {
    if (getEditAllowanceMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditAllowanceMasterData));
    }
  }, [getEditAllowanceMasterStatus]);

  const saveAllowanceMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.saveAllowanceMaster,
          method: apiMethods.POST,
          key: apiKeys.saveAllowanceMaster,
          successMsg: formatMessage({ id: 'allowance-master-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'allowance-master-is-failed-to-save' }),
          body: getFormattedPayload(values)
        })
      );
    },
    [dispatch, formatMessage]
  );

  const updateAllowanceMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.updateAllowanceMaster,
          method: apiMethods.PUT,
          key: apiKeys.updateAllowanceMaster,
          successMsg: formatMessage({ id: 'allowance-master-is-updated-successfully' }),
          failureMsg: formatMessage({ id: 'allowance-master-is-failed-to-update' }),
          body: getFormattedPayload({ ...values, id: activeEditId })
        })
      );
    },
    [dispatch, formatMessage, activeEditId]
  );


  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveAllowanceMaster, 
        apiKeys.getEditAllowanceMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      formik,
      values: formik.values,
      saveAllowanceMasterStatus,
      updateAllowanceMasterStatus,
      saveAllowanceMasterLoading,
      getEditAllowanceMasterData,
      getEditAllowanceMasterStatus,
      getEditAllowanceMasterErrMsg
    }),
    [formik, saveAllowanceMasterStatus, saveAllowanceMasterLoading, updateAllowanceMasterStatus,
       getEditAllowanceMasterData, getEditAllowanceMasterStatus, getEditAllowanceMasterErrMsg]
  );
}

export default useCreateEditAllowanceMaster;
