import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getErrorMessage, getStatus } from '@/store/slices/helpers/helpers';
import { fetchData, clearData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { initialValues } from '../constants/constants';
import { getFormattedPayload, getFormattedResponse } from '@/common/helpers/helpers';

function useCreateEditDropdownMaster(activeEditId) {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveDropdownMasterStatus = getStatus(state, apiKeys.saveDropdownMaster, '');
  const updateDropdownMasterStatus = getStatus(state, apiKeys.updateDropdownMaster, '');
  const saveDropdownMasterLoading = apiStatuses.pending === saveDropdownMasterStatus;
  const getEditDropdownMasterData = getData(state, apiKeys.getEditDropdownMaster, {});
  const getEditDropdownMasterStatus = getStatus(state, apiKeys.getEditDropdownMaster, '');
  const getEditDropdownMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditDropdownMaster,
    formatMessage({ id: 'failed-to-get-selected-employees-details' })
  );

  const validationSchema = yup.object({
    dropdownName: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    ddShortName: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    ddShortCode: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    activeStatus: yup.boolean().required(formatMessage({ id: 'this-field-is-required' }))
  });


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (activeEditId) {
        updateDropdownMaster(values);
      } else {
        saveDropdownMaster(values);
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditDropdownMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditDropdownMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditDropdownMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-selected-employees-details' })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  
  useEffect(() => {
    if (activeEditId) {
      getEditDropdownMaster();
    }
  }, [activeEditId, getEditDropdownMaster]);

  useEffect(() => {
    if (getEditDropdownMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditDropdownMasterData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditDropdownMasterStatus]);

  const saveDropdownMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.saveDropdownMaster,
          method: apiMethods.POST,
          key: apiKeys.saveDropdownMaster,
          successMsg: formatMessage({ id: 'dropdown-master-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'dropdown-master-is-failed-to-save' }),
          body: getFormattedPayload(values)
        })
      );
    },
    [dispatch, formatMessage]
  );

  const updateDropdownMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.updateDropdownMaster,
          method: apiMethods.PUT,
          key: apiKeys.updateDropdownMaster,
          successMsg: formatMessage({ id: 'dropdown-master-is-updated-successfully' }),
          failureMsg: formatMessage({ id: 'dropdown-master-is-failed-to-update' }),
          body: getFormattedPayload({ ...values, id: activeEditId })
        })
      );
    },
    [dispatch, formatMessage, activeEditId]
  );


  useEffect(
    () => () => {
      dispatch(clearData({ key: apiKeys.getEditDropdownMaster }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      formik,
      values: formik.values,
      saveDropdownMasterStatus,
      updateDropdownMasterStatus,
      saveDropdownMasterLoading,
      getEditDropdownMasterData,
      getEditDropdownMasterStatus,
      getEditDropdownMasterErrMsg
    }),
    [
      formik,
      saveDropdownMasterStatus,
      updateDropdownMasterStatus,
      saveDropdownMasterLoading,
      getEditDropdownMasterData,
      getEditDropdownMasterStatus,
      getEditDropdownMasterErrMsg
    ]
  );
}

export default useCreateEditDropdownMaster;
