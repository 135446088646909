
export const initialValues = {
  primeCusSapCode: "",
  primeCusName: null,
  primeCusCategory: null,
  primeCusGstNo: null,
  address: null,
  landmark: null,
  zoneId: null,
  subzoneId: null,
  contactPerson: null,
  contactNumber: null,
  shipToPartyCode: null,
  salesSupportRep: null,
  sapTeamLeader: null,
  teamLeaderName: null,
  zoneHeadName: null,
  salesHeadName: null,
  sapZoneHead: null,
  sapSalesHead: null,
  salesOrg: null,
  priceGroup: null,
  customerGroup: null,
  pincode: null,
  incoterms: null,
  distributionChannel: null,
  sapDivision: null,
  sapCountry: null,
  sapCompany: null,
  regionCode: null,
  contactNo: null,
  file: null,
  districtId: null,
  sapTeamLeaderName: null,
  sapSalesSupportRep: null,
  sapSalesHeadName: null,
  sapZoneHeadName: null,
  latitude: null,
  longitude: null,
  uploadFile: null,
  cityId: null,
  activeStatus: 1,


}
