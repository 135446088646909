import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetBrandNameOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getBrandNameData = getData(state, apiKeys.brandMaster, []);
  const getBrandNameStatus = getStatus(state, apiKeys.brandMaster, '');
  const getBrandNameLoading = apiStatuses.pending === getBrandNameStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.brandMaster,
        method: apiMethods.GET,
        key: apiKeys.brandMaster
      })
    );
  }, [dispatch]);

  const getBrandNameOptions = useMemo(() => {
    return getBrandNameData.filter((option) => option.activeStatus === "1")
    .map(({ fdBrandId, brandName }) => ({
      id: fdBrandId,
      label: brandName
    }))
  }, [getBrandNameData]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.brandMaster,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
        getBrandNameData,
        getBrandNameOptions,
        getBrandNameStatus,
        getBrandNameLoading
    }),
    [getBrandNameData, getBrandNameOptions, getBrandNameStatus, getBrandNameLoading]
  );
}

export default useGetBrandNameOptions;
