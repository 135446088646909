import { BookOutlined, UserOutlined } from '@ant-design/icons';
import PrimaryCustomer from '../components/primaryCustomer/primaryCustomer';
import SecondaryCustomer from '../components/scondaryCustomer/secondaryCustomer';
import { requestedFeaturesAll, requestedMenuAll }
 from '../../../from-theme/routes/constants/menuAccess';
import VerifyAccess from '@/common/components/verify-access/verify-access';

const { primaryCustTabMasterKey, secondCustTabMasterKey } = requestedMenuAll;
const { readKey } = requestedFeaturesAll;

// tabName is locale id
export const customerMasterTabs = [
  {
    id: 'primaryCustomer',
    tabName: 'primaryCustomer',
    icon: <UserOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={primaryCustTabMasterKey} requestedFeature={readKey}>
        <PrimaryCustomer />
      </VerifyAccess>
    )
  },
  {
    id: 'secondaryCustomer',
    tabName: 'secondaryCustomer',
    icon: <BookOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={secondCustTabMasterKey} requestedFeature={readKey}>
        <SecondaryCustomer />
      </VerifyAccess>
    )
  }
];
