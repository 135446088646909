
export const initialValues = {
  materialCode: null,
  materialName: null,
  buom: null,
  grossWeight: null,
  netWeight: null,
  categoryId: "",
  brandId: "",
  productId: "",
  auom: null,
  conversionFactor: null,
  controlCode: null,
  sku: null,
  deletionIndicator: null,
  activeStatus: 1,

}
