import { Base64 } from "js-base64";

export const makeId = (length = 4) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const titleCase = (str = '') => {
  return (str || '')
    .toLowerCase()
    .split(' ')
    .map((item) => (item && `${item.charAt(0).toUpperCase()}${item.slice(1)}`) || '')
    .join(' ');
};


export const getFormattedPayload = (requestPayload) => {
  if (typeof requestPayload !== "object" || requestPayload === null) {
    return {}; 
  }

  // Continue with the rest of the function
  const results = JSON.parse(JSON.stringify(requestPayload));

  if (
    typeof requestPayload === "object" &&
    !Array.isArray(requestPayload)
  ) {
    for (let prop in requestPayload) {
      const propValue = requestPayload[prop];
      if (
        propValue &&
        typeof propValue === "object" &&
        !Array.isArray(propValue) &&
        Object.keys(propValue).length > 1 &&
        typeof propValue.id !== "undefined" &&
        typeof propValue.label !== "undefined"
      ) {
        results[prop] = propValue.id;
      } else if (
        propValue &&
        typeof propValue === "object" &&
        Array.isArray(propValue) &&
        propValue.length > 0 &&
        propValue.filter(
          (item) =>
            item &&
            typeof item === "object" &&
            !Array.isArray(item) &&
            Object.keys(item).length > 1 &&
            typeof item.id !== "undefined" &&
            typeof item.label !== "undefined"
        ).length === propValue.length
      ) {
        results[prop] = propValue.map((item2) => item2.id);
      }
    }
  }
  return results;
};



export const getFormattedResponse = (response = {}) => {
  const results = JSON.parse(JSON.stringify(response));

  if(
    typeof response === "object"
    && !Array.isArray(response)
  ) {
    for(let prop in response) {
      const propValue = response[prop];
      if(propValue) {
        // will utilize later
      }
      if(prop === "activeStatus"){
        results[prop] =  Number(propValue);
      }
      else if(prop === "subMenuAvail"){
        results[prop] =  Number(propValue);
      }
      else if(prop === "planScStatus"){
        results[prop] =  Number(propValue);
      }
    }
  }
  return results;

}

export const getEncoded = (value = "") => (Base64.encode((value || "").toString()))

export const getDecoded = (value = "") => (Base64.decode((value || "").toString()))

export const getIdByIndexAndKey = (data, selected={}, keyName) => {
  const results = [];
  const trueIndexes = [];

  Object.keys(selected).forEach((propIndex) => {
    if(selected[propIndex] === true) {
      trueIndexes.push(Number(propIndex));
    }
  })

  data.forEach((item, index) => {
    if(trueIndexes.indexOf(index) !== -1) {
      results.push(item[keyName] || "id")
    }
  })

  return results;
}