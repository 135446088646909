import { InputLabel, TextField } from '@mui/material'; // Added multiline import for TextField
import { useMemo } from 'react';
import './text-input.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

function TextInput({ type, placeholder, value, inputLabel, name, rows, formik, onChange, onBlur,
   ...props }) {
  const { formatMessage } = useIntl();

  const formikError = useMemo(() => {
    if (formik) {
      return {
        error: formik?.touched[name] && Boolean(formik?.errors[name]),
        helperText: formik?.touched[name] && formik?.errors[name]
      };
    }
    return {};
  }, [formik, name]);

  return (
    <>
      {!!inputLabel && (
        <InputLabel className='labelClr' required={!formik?.fields[name]?.spec?.optional}
         htmlFor={name}>
          {}
          <FormattedMessage id={inputLabel} />
        </InputLabel>
      )}
      <TextField
        {...props}
        id={name}
        type={type || 'text'}
        placeholder={placeholder ? formatMessage({ id: placeholder }) : ''}
        defaultValue=""
        value={value}
        onChange={(e) => {
          if (formik) {
            formik.setFieldValue(name, e.target.value);
          } else {
            onChange(e);
          }
        }}
        onBlur={onBlur}
        {...formikError}
        fullWidth
        multiline
        rows={rows}
      />
    </>
  );
}

TextInput.propTypes = {
  type: PropTypes.oneOf(['hidden', 'number', 'text']),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  inputLabel: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.string,
  formik: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

TextInput.defaultProps = {
  type: 'text',
  placeholder: '',
  value: '',
  inputLabel: '',
  formik: null,
  name: '',
  rows: '1',
  onChange: () => {},
  onBlur: () => {}
};

export default TextInput;
