import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useZoneMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveZoneMasterStatus = getStatus(state, apiKeys.saveZoneMaster, '');
  const updateZoneMasterStatus = getStatus(state, apiKeys.updateZoneMaster, '');
  const zoneMasterData = getData(state, apiKeys.zoneMaster, []);
  const zoneMasterStatus = getStatus(state, apiKeys.zoneMaster, '');
  const zoneMasterErrMsg = getErrorMessage(state, apiKeys.zoneMaster, '');
  const deleteZoneMasterStatus = getStatus(state, apiKeys.deleteZoneMaster, '');

  const getZoneMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.zoneMaster,
      method: apiMethods.GET,
      key: apiKeys.zoneMaster,
      failureMsg: formatMessage({ id: "failed-to-get-zone-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getZoneMaster()
  }, [dispatch, getZoneMaster]);

  useEffect(() => {
    if (deleteZoneMasterStatus === apiStatuses.succeeded) {
      getZoneMaster();
    }
  }, [deleteZoneMasterStatus, getZoneMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.zoneMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  useEffect(() => {
    if (saveZoneMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getZoneMaster();
    } 
  }, [saveZoneMasterStatus, closeModal, getZoneMaster]);

  useEffect(() => {
    if (updateZoneMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getZoneMaster();
    } 
  }, [updateZoneMasterStatus, closeModal, getZoneMaster]);

  return useMemo(() => ({
    zoneMasterData,
    zoneMasterStatus,
    zoneMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    zoneMasterData,
    zoneMasterStatus,
    zoneMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
