import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import { LogoMain, LoginLogo } from './LogoMain';
import LogoIcon from './LogoIcon';
import { APP_DEFAULT_PATH } from 'config';
import useAuth from '@/from-theme/hooks/useAuth';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ reverse, isIcon, sx, to }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  // Check if the user is on the login page
  const isLoginPage = location.pathname === '/login';

  return (
    <ButtonBase disableRipple {...(isLoggedIn && { component: Link, to: !to ?
     APP_DEFAULT_PATH : to, sx })}>
      {/* Render the appropriate logo based on the page */}
      {isLoginPage ? <LoginLogo /> : (isIcon ? <LogoIcon /> : <LogoMain reverse={reverse} />)}
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
