/* eslint-disable max-len */
import { lazy } from 'react';

// project import
import Loadable from '@/from-theme/components/Loadable';
import DashboardLayout from '@/from-theme/layout/Dashboard';
import LocationMaster from '@/pages/location-master/location-master';
import RoleMaster from '@/pages/role-master/role-master';
import ProductMaster from '@/pages/product-master/product-master';
import ClaimMaster from '@/pages/claim-master/claim-master';
import MenuMaster from '@/pages/menu-master/menu-master';
import DropdownMaster from '@/pages/dropdown-master/dropdown-master';
import ZoneMaster from '@/pages/zone-master/zone-master';
import CustomerMaster from '@/pages/customer-master/customer-master';
import ProtectedRoute from '@/from-theme/utils/route-guard/ProtectedRoute';
import { requestedMenuAll, requestedFeaturesAll } from './constants/menuAccess';


// render - dashboard page
const DashboardPage = Loadable(lazy(() => import('pages/dashboard-master/dashboard-page')));

// render - master menu page
const EmployeeMaster = Loadable(lazy(() => import('pages/employee-master/employee-master')));
const CreateEditEmployeeMaster = Loadable(lazy(() => import(
  'pages/employee-master/pages/create-edit-employee-master/create-edit-employee-master'
)));

const UserMaster = Loadable(lazy(() => import('pages/user-master/user-master')));
const CreateEditUserMaster = Loadable(lazy(() => import(
  'pages/user-master/pages/create-edit-user-master/create-edit-user-master'
)));
const CompetitorMaster = Loadable(lazy(() => import('pages/ifd-competitor-master/ifd-competitor-master')));

const CreateEditCompetitorMaster = Loadable(lazy(() => import(
  'pages/ifd-competitor-master/pages/create-edit-ifd-competitor-master/create-edit-ifd-competitor-master'
)));
const CompetitorOffers = Loadable(lazy(() => import('pages/competitor-offers/competitor-offers')));
const CreateEditCompetitorOffers = Loadable(lazy(() => import(
  'pages/competitor-offers/pages/create-edit-competitor-offers/create-edit-competitor-offers'
)));

const DivisionMaster = Loadable(lazy(() => import('pages/division-master/division-master')));
const CreateEditDivisionMaster = Loadable(lazy(() => import(
  'pages/division-master/pages/create-edit-division-master/create-edit-division-master'
)));

const PrimaryCustomerMaster = Loadable(lazy(() => import('pages/division-master/division-master')));
const SecondaryCustomerMaster = Loadable(lazy(() => import(
  'pages/division-master/division-master')));

const CreateEditPrimeCustMaster = Loadable(lazy(() => import(
  'pages/division-master/pages/create-edit-division-master/create-edit-division-master'
)));
const CreateEditSecondCustMaster = Loadable(lazy(() => import(
  'pages/division-master/pages/create-edit-division-master/create-edit-division-master'
)));
const CompanyMaster = Loadable(lazy(() => import('pages/company-master/company-master')));
const FdCompetitorMaster = Loadable(lazy(() => import('pages/fd-competitor-master/fd-competitor-master')));
const PlanApplicable = Loadable(lazy(() => import('pages/plan-applicable/plan-applicable')));

const VenueMaster = Loadable(lazy(() => import('pages/venue-master/venue-master')));
const BankMaster = Loadable(lazy(() => import('pages/bank-master/bank-master')));
const GlMaster = Loadable(lazy(() => import('pages/gl-master/gl-master')));
const PlantMaster = Loadable(lazy(() => import('pages/plant-master/plant-master')));
const ValidationIprospect = Loadable(lazy(() => 
import('pages/prospect-validation-ifoods/prospect-validation-ifoods')));
const EditValidationIprospect = Loadable(lazy(() => import(
  'pages/prospect-validation-ifoods/pages/create-edit-validation-ifoods/create-edit-validation-ifoods'
)));
const ApprovalBo = Loadable(lazy(() => import('pages/approval-plan/approval-plan')));
const PlanDeviation = Loadable(lazy(() => import('pages/plan-deviation/plan-deviation')));
const CreateEditApprovalBo = Loadable(lazy(() => import(
  'pages/approval-plan/pages/create-edit-approval-plan/create-edit-approval-plan'
)));
const PlanValidationView = Loadable(lazy(() => import(
  'pages/plan-deviation/pages/approval-reject-plan-deviation/approval-reject-plan-deviation'
)));
const ApprovalFoods = Loadable(lazy(() => import('pages/approval-foods/approval-foods')));
const CreateEditApprovalFoods = Loadable(lazy(() => import(
  'pages/approval-foods/pages/create-edit-approval-foods/create-edit-approval-foods'
)));

const MenuAccess = Loadable(lazy(() => import('pages/menu-master/components/menuAccess/menuAccess')));
const CreateEditMenuAccess = Loadable(lazy(() => import(
  'pages/menu-master/components/menuAccess/pages/create-edit-menu-access/create-edit-menu-access'
)));

const ApprovalClaimBackoffice = Loadable(lazy(() => import('pages/approval-claim/approval-claim')));
const CreateEditApprovalClaimBO = Loadable(lazy(() => import(
  'pages/approval-claim/pages/create-edit-approval-claim/create-edit-approval-claim'
)));

const ClaimSmartForm = Loadable(lazy(() => import(
  'pages/claim-details-report/pages/claim-expense-view/approval-claim-smart-form')));

const CreateEditPlantMaster = Loadable(lazy(() => import(
  'pages/plant-master/pages/create-edit-plant-master/create-edit-plant-master'
)));
const CreateEditVenueMaster = Loadable(lazy(() => import(
  'pages/venue-master/pages/create-edit-venue-master/create-edit-venue-master'
)));
const ProspectMaster = Loadable(lazy(() => import('pages/prospect-master/prospect-master')));
const CreateEditProspectMaster = Loadable(lazy(() => import(
  'pages/prospect-master/pages/create-edit-prospect-master/create-edit-prospect-master'
)));
const ShiftMaster = Loadable(lazy(() => import('pages/shift-master/shift-master')));
const AttendanceReport = Loadable(lazy(() => import('pages/attendance-report/attendance-report')));
const AppTrackReport = Loadable(lazy(() => import('pages/app-track-report/app-track-report')));
const MapViewReport = Loadable(lazy(() => import('pages/map-view-report/map-view-report')));
//const MapView = Loadable(lazy(() => import('pages/map/map')));
const TravelReport = Loadable(lazy(() => import('pages/travel-report/travel-report')));
const TravelClaimReport = Loadable(lazy(() => import('pages/travelling-claim-report/travelling-claim-report')));
const CompetitorReport = Loadable(lazy(() => import('pages/competitor-report/competitor-report')));
const ClaimDetailsReport = Loadable(lazy(() => import('pages/claim-details-report/claim-details-report')));
const ClaimExpenseView = Loadable(lazy(() => import(
  'pages/claim-details-report/pages/claim-expense-view/claim-expense-view')));
const ClaimExpenseReport = Loadable(lazy(() => import('pages/claim-expense-report/claim-expense-report')));
const OutletVisitReport = Loadable(lazy(() => import('pages/outlet-visit-report/outlet-visit-report')));
const SecondaryOutletVisitReport = Loadable(lazy(() => import('pages/secondary-outlet-visit-report/secondary-outlet-visit-report')));
const IfoodsPlanReport = Loadable(lazy(() => import('pages/plan-report/plan-report')));
const PlanDeviationReport = Loadable(lazy(() => import('pages/plan-deviation-report/plan-deviation-report')));
const IfoodsProspectReport = Loadable(lazy(() => import('pages/prospect-report/prospect-report')));
const PrimaryCustomerReport = Loadable(lazy(() => import('pages/primary-cust-report/primary-customer-report')));
const SecondaryCustomerReport = Loadable(lazy(() => import('pages/second-cust-report/second-cust-report')));
const CreateEditShiftMaster = Loadable(lazy(() => import(
  'pages/shift-master/pages/create-edit-shift-master/create-edit-shift-master'
)));
const OutletMaster = Loadable(lazy(() => import('pages/outlet-master/outlet-master')));
const CreateEditOutletMaster = Loadable(lazy(() => import(
  'pages/outlet-master/pages/create-edit-outlet-master/create-edit-outlet-master'
)));
const ClaimCreation = Loadable(lazy(() => import('pages/claim-creation/claim-creation')));
const CreateEditClaim = Loadable(lazy(() => import(
  'pages/claim-creation/pages/create-edit-claim-creation/create-edit-claim-creation'
)));
const PlanCreation = Loadable(lazy(() => import('pages/plan-creation/plan-creation')));
const CreateEditPlan = Loadable(lazy(() => import(
  'pages/plan-creation/pages/create-edit-plan-creation/create-edit-plan-creation'
)));

const {
  dashboardKey, companyMasterKey, planValidationKey, approvalFoodsMasterKey,
  approvalClaimBOKey, bankMasterKey, claimMasterKey, travelReportKey, appTrackReportKey,
  ifdCompetitorMasterKey, competitorOffersKey, customerMasterKey, divisionMasterKey, dropdownMasterKey,
  employeeMasterKey, locationMasterKey, menuMasterKey, menuAccessTabMasterKey, secondaryOutletVisitReportKey,
  outletMasterKey, planApplicableMasterKey, planCreationKey, plantMasterKey, claimExpenseReportKey,
  productMasterKey, prospectMasterKey, prospectValidationMasterKey, attendanceReportKey,
  competitorReportKey, prospectReportKey, primeCustReportKey, secondCustReportKey, planDeviationKey,
  roleMasterKey, shiftMasterKey, userMasterKey, venueMasterKey, zoneMasterKey, ifoodsPlanReportKey,
  outletVisitReportKey, claimCreationKey, mapViewReportKey, planDeviationReportKey, claimDetailsReportKey,
  travelClaimReportKey, glMasterKey, fdCompetitorMasterKey} = requestedMenuAll;
const {createKey, readKey, updateKey} = requestedFeaturesAll;

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'dashboard',
          element: <ProtectedRoute requestedMenu={dashboardKey} requestedFeature={readKey} >
            <DashboardPage />
          </ProtectedRoute>
        },
        {
          path: 'company-master',
          element: <ProtectedRoute requestedMenu={companyMasterKey} requestedFeature={readKey} >
            <CompanyMaster />
          </ProtectedRoute>,
        },
        {
          path: 'fd-competitor-master',
          element: <ProtectedRoute requestedMenu={fdCompetitorMasterKey} requestedFeature={readKey} >
            <FdCompetitorMaster />
          </ProtectedRoute>,
        },
        {
          path: 'bank-master',
          element: <ProtectedRoute requestedMenu={bankMasterKey} requestedFeature={readKey} >
            <BankMaster />
            </ProtectedRoute>,
        },
        {
          path: 'gl-master',
          element: <ProtectedRoute requestedMenu={glMasterKey} requestedFeature={readKey} >
            <GlMaster />
            </ProtectedRoute>,
        },
        {
          path: 'customer-master',
          element: <ProtectedRoute requestedMenu={customerMasterKey} requestedFeature={readKey} >
            <CustomerMaster />
          </ProtectedRoute>,
        },
        {
          path: 'plan-applicable',
          element: <ProtectedRoute requestedMenu={planApplicableMasterKey} requestedFeature={readKey} >
            <PlanApplicable />
          </ProtectedRoute>,
        },
        {
          path: 'competitor-offers',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={competitorOffersKey} requestedFeature={readKey} >
                <CompetitorOffers />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={competitorOffersKey} requestedFeature={createKey} >
                <CreateEditCompetitorOffers />
              </ProtectedRoute>,
            },
            {
              path: "edit/:compProdOffId",
              element: <ProtectedRoute requestedMenu={competitorOffersKey} requestedFeature={updateKey} >
                <CreateEditCompetitorOffers isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'ifd-competitor-master',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={ifdCompetitorMasterKey} requestedFeature={readKey}>
                <CompetitorMaster />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={ifdCompetitorMasterKey} requestedFeature={createKey}>
                <CreateEditCompetitorMaster />
              </ProtectedRoute>,
            },
            {
              path: "edit/:competitorId",
              element: <ProtectedRoute requestedMenu={ifdCompetitorMasterKey} requestedFeature={updateKey}>
                <CreateEditCompetitorMaster isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'employee-master',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={employeeMasterKey} requestedFeature={readKey}>
                <EmployeeMaster />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={employeeMasterKey} requestedFeature={createKey}>
                <CreateEditEmployeeMaster />
              </ProtectedRoute>,
            },
            {
              path: "edit/:employeeId",
              element: <ProtectedRoute requestedMenu={employeeMasterKey} requestedFeature={updateKey}>
                <CreateEditEmployeeMaster isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'user-master',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={userMasterKey} requestedFeature={readKey}>
                <UserMaster />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={userMasterKey} requestedFeature={createKey}>
                <CreateEditUserMaster />
              </ProtectedRoute>,
            },
            {
              path: "edit/:userId",
              element: <ProtectedRoute requestedMenu={userMasterKey} requestedFeature={updateKey}>
                <CreateEditUserMaster isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'division-master',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={divisionMasterKey} requestedFeature={readKey}>
                <DivisionMaster />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={divisionMasterKey} requestedFeature={createKey}>
                <CreateEditDivisionMaster />
              </ProtectedRoute>,
            },
            {
              path: "edit/:divisionId",
              element: <ProtectedRoute requestedMenu={divisionMasterKey} requestedFeature={updateKey}>
                <CreateEditDivisionMaster isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'primary-customer-master',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={customerMasterKey} requestedFeature={readKey}>
                <PrimaryCustomerMaster />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={customerMasterKey} requestedFeature={createKey}>
                <CreateEditPrimeCustMaster />
              </ProtectedRoute>,
            },
            {
              path: "edit/:id",
              element: <ProtectedRoute requestedMenu={customerMasterKey} requestedFeature={updateKey}>
                <CreateEditPrimeCustMaster isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'secondary-customer-master',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={customerMasterKey} requestedFeature={readKey}>
                <SecondaryCustomerMaster />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={customerMasterKey} requestedFeature={createKey}>
                <CreateEditSecondCustMaster />
              </ProtectedRoute>,
            },
            {
              path: "edit/:id",
              element: <ProtectedRoute requestedMenu={customerMasterKey} requestedFeature={updateKey}>
                <CreateEditSecondCustMaster isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'plant-master',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={plantMasterKey} requestedFeature={readKey}>
                <PlantMaster />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={plantMasterKey} requestedFeature={createKey}>
                <CreateEditPlantMaster />
              </ProtectedRoute>,
            },
            {
              path: "edit/:plantId",
              element: <ProtectedRoute requestedMenu={plantMasterKey} requestedFeature={updateKey}>
                <CreateEditPlantMaster isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'menu-access',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={menuAccessTabMasterKey} requestedFeature={readKey}>
                <MenuAccess />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <>
                <CreateEditMenuAccess />
              </>,
            },
            {
              path: "edit/:menuAccessId",
              element: <ProtectedRoute requestedMenu={menuAccessTabMasterKey} requestedFeature={updateKey}>
                <CreateEditMenuAccess isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'plan-validation',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={planValidationKey} requestedFeature={readKey}>
                <ApprovalBo />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={planValidationKey} requestedFeature={createKey}>
                <CreateEditApprovalBo />
              </ProtectedRoute>,
            },
            {
              path: "edit/:planScheduleId",
              element: <ProtectedRoute requestedMenu={planValidationKey} requestedFeature={updateKey}>
                <CreateEditApprovalBo isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'plan-deviation',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={planDeviationKey} requestedFeature={readKey}>
                <PlanDeviation />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={planDeviationKey} requestedFeature={createKey}>
                <PlanValidationView />
              </ProtectedRoute>,
            },
            {
              path: "edit/:planScheduleId",
              element: <ProtectedRoute requestedMenu={planDeviationKey} requestedFeature={updateKey}>
                <PlanValidationView isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'approval-foods',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={approvalFoodsMasterKey} requestedFeature={readKey}>
                <ApprovalFoods />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={approvalFoodsMasterKey} requestedFeature={createKey}>
                <CreateEditApprovalFoods />
              </ProtectedRoute>,
            },
            {
              path: "edit/:planScheduleId",
              element: <ProtectedRoute requestedMenu={approvalFoodsMasterKey} requestedFeature={updateKey}>
                <CreateEditApprovalFoods isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'approval-claim-backoffice',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={approvalClaimBOKey} requestedFeature={readKey}>
                <ApprovalClaimBackoffice />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={approvalClaimBOKey} requestedFeature={createKey}>
                <CreateEditApprovalClaimBO />
              </ProtectedRoute>,
            },
            {
              path: "edit/:claimSubmissionId",
              element: <ProtectedRoute requestedMenu={approvalClaimBOKey} requestedFeature={updateKey}>
                <CreateEditApprovalClaimBO isEditMode />
              </ProtectedRoute>,
            },
          ]
        },
        {
          path: 'prospect-validation',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={prospectValidationMasterKey} requestedFeature={readKey}>
                <ValidationIprospect />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={prospectValidationMasterKey} requestedFeature={createKey}>
                <EditValidationIprospect />
              </ProtectedRoute>,
            },
            {
              path: "edit/:ifProspectId",
              element: <ProtectedRoute requestedMenu={prospectValidationMasterKey} requestedFeature={updateKey}>
                <EditValidationIprospect isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'shift-master',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={shiftMasterKey} requestedFeature={readKey}>
                <ShiftMaster />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={shiftMasterKey} requestedFeature={createKey}>
                <CreateEditShiftMaster />
              </ProtectedRoute>,
            },
            {
              path: "edit/:id",
              element: <ProtectedRoute requestedMenu={shiftMasterKey} requestedFeature={updateKey}>
                <CreateEditShiftMaster isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'outlet-master',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={outletMasterKey} requestedFeature={readKey}>
                <OutletMaster />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={outletMasterKey} requestedFeature={createKey}>
                <CreateEditOutletMaster />
              </ProtectedRoute>,
            },
            {
              path: "edit/:ifOutletId",
              element: <ProtectedRoute requestedMenu={outletMasterKey} requestedFeature={updateKey}>
                <CreateEditOutletMaster isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'prospect-master',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={prospectMasterKey} requestedFeature={readKey}>
                <ProspectMaster />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={prospectMasterKey} requestedFeature={createKey}>
                <CreateEditProspectMaster />
              </ProtectedRoute>,
            },
            {
              path: "edit/:ifProspectId",
              element: <ProtectedRoute requestedMenu={prospectMasterKey} requestedFeature={updateKey}>
                <CreateEditProspectMaster isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'claim-creation',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={claimCreationKey} requestedFeature={readKey}>
                <ClaimCreation />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={claimCreationKey} requestedFeature={createKey}>
                <CreateEditClaim />
              </ProtectedRoute>,
            },
            {
              path: "edit/:claimId",
              element: <ProtectedRoute requestedMenu={claimCreationKey} requestedFeature={updateKey}>
                <CreateEditClaim isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'pjp-creation',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={planCreationKey} requestedFeature={readKey}>
                <PlanCreation />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={planCreationKey} requestedFeature={createKey}>
                <CreateEditPlan />
              </ProtectedRoute>,
            },
            {
              path: "edit/:fdPjpId",
              element: <ProtectedRoute requestedMenu={planCreationKey} requestedFeature={updateKey}>
                <CreateEditPlan isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'venue-master',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={venueMasterKey} requestedFeature={readKey}>
                <VenueMaster />
              </ProtectedRoute>,
            },
            {
              path: "create",
              element: <ProtectedRoute requestedMenu={venueMasterKey} requestedFeature={createKey}>
                <CreateEditVenueMaster />
              </ProtectedRoute>,
            },
            {
              path: "edit/:userId",
              element: <ProtectedRoute requestedMenu={venueMasterKey} requestedFeature={updateKey}>
                <CreateEditVenueMaster isEditMode />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'location-master',
          element: <ProtectedRoute requestedMenu={locationMasterKey} requestedFeature={readKey}>
            <LocationMaster />
          </ProtectedRoute>,
        },
        {
          path: 'role-master',
          element: <ProtectedRoute requestedMenu={roleMasterKey} requestedFeature={readKey}>
            <RoleMaster />
          </ProtectedRoute>,
        },
        {
          path: 'menu-master',
          element: <ProtectedRoute requestedMenu={menuMasterKey} requestedFeature={readKey}>
            <MenuMaster />
          </ProtectedRoute>,
        },
        {
          path: 'product-master',
          element: <ProtectedRoute requestedMenu={productMasterKey} requestedFeature={readKey}>
            <ProductMaster />
          </ProtectedRoute>,
        },
        {
          path: 'claim-master',
          element: <ProtectedRoute requestedMenu={claimMasterKey} requestedFeature={readKey}>
            <ClaimMaster />
          </ProtectedRoute>,
        },
        {
          path: 'dropdown-master',
          element: <ProtectedRoute requestedMenu={dropdownMasterKey} requestedFeature={readKey}>
            <DropdownMaster />
          </ProtectedRoute>,
        },
        {
          path: 'zone-master',
          element: <ProtectedRoute requestedMenu={zoneMasterKey} requestedFeature={readKey}>
            <ZoneMaster />
          </ProtectedRoute>,
        },
        {
          path: 'attendance-report',
          element: <ProtectedRoute requestedMenu={attendanceReportKey} requestedFeature={readKey}>
            <AttendanceReport />
          </ProtectedRoute>,
        },
        {
          path: 'app-track-report',
          element: <ProtectedRoute requestedMenu={appTrackReportKey} requestedFeature={readKey}>
            <AppTrackReport />
          </ProtectedRoute>,
        },
        {
          path: 'competitor-report',
          element: <ProtectedRoute requestedMenu={competitorReportKey} requestedFeature={readKey}>
            <CompetitorReport />
          </ProtectedRoute>,
        },
        {
          path: 'claim-details-report',
          children: [
            {
              index: true,
              element: <ProtectedRoute requestedMenu={claimDetailsReportKey} requestedFeature={readKey}>
                <ClaimDetailsReport />
              </ProtectedRoute>,
            },
            {
              path: "view/:claimSubmissionId",
              element: <ProtectedRoute requestedMenu={claimDetailsReportKey} requestedFeature={readKey}>
                <ClaimExpenseView />
              </ProtectedRoute>,
            },
            {
              path: "edit/:claimSubmissionId",
              element: <ProtectedRoute requestedMenu={claimDetailsReportKey} requestedFeature={readKey}>
                <ClaimSmartForm />
              </ProtectedRoute>,
            }
          ]
        },
        {
          path: 'claim-expense-report',
          element: <ProtectedRoute requestedMenu={claimExpenseReportKey} requestedFeature={readKey}>
            <ClaimExpenseReport />
          </ProtectedRoute>,
        },
        {
          path: 'travel-report',
          element: <ProtectedRoute requestedMenu={travelReportKey} requestedFeature={readKey}>
            <TravelReport />
          </ProtectedRoute>,
        },
        {
          path: 'travel-claim-report',
          element: <ProtectedRoute requestedMenu={travelClaimReportKey} requestedFeature={readKey}>
            <TravelClaimReport />
          </ProtectedRoute>,
        },
        {
          path: 'plan-report',
          element: <ProtectedRoute requestedMenu={ifoodsPlanReportKey} requestedFeature={readKey}>
            <IfoodsPlanReport />
          </ProtectedRoute>,
        },
        {
          path: 'plan-deviation-report',
          element: <ProtectedRoute requestedMenu={planDeviationReportKey} requestedFeature={readKey}>
            <PlanDeviationReport />
          </ProtectedRoute>,
        },
        {
          path: 'map-view',
          element: <ProtectedRoute requestedMenu={mapViewReportKey} requestedFeature={readKey}>
            <MapViewReport />
          </ProtectedRoute>,
        },
        // {
        //   path: 'map',
        //   element: <ProtectedRoute requestedMenu={mapViewReportKey} requestedFeature={readKey}>
        //     <MapView />
        //   </ProtectedRoute>,
        // },
        {
          path: 'outlet-visit-report',
          element: <ProtectedRoute requestedMenu={outletVisitReportKey} requestedFeature={readKey}>
            <OutletVisitReport />
          </ProtectedRoute>,
        },
        {
          path: 'secondary-outlet-visit-report',
          element: <ProtectedRoute requestedMenu={secondaryOutletVisitReportKey} 
          requestedFeature={readKey}>
            <SecondaryOutletVisitReport />
          </ProtectedRoute>,
        },
        {
          path: 'prospect-report',
          element: <ProtectedRoute requestedMenu={prospectReportKey} requestedFeature={readKey}>
            <IfoodsProspectReport />
          </ProtectedRoute>,
        },
        {
          path: 'primary-customer-report',
          element: <ProtectedRoute requestedMenu={primeCustReportKey} requestedFeature={readKey}>
            <PrimaryCustomerReport />
          </ProtectedRoute>,
        },
        {
          path: 'secondary-customer-report',
          element: <ProtectedRoute requestedMenu={secondCustReportKey} requestedFeature={readKey}>
            <SecondaryCustomerReport />
          </ProtectedRoute>,
        },
      ]
    },
    // {
    //   path: '/',
    //   element: <DashboardLayout />,
    //   children: [
    //     {
    //       path: 'approval-master',
    //       element: <ProtectedRoute requestedMenu={approvalMasterKey} requestedFeature={readKey}>
    //         <DashboardPage />
    //       </ProtectedRoute>
    //     },
    //   ]
    // },
  
  ]
};

export default MainRoutes;
