import { Box, TableCell, TableRow } from "@mui/material";
import React from "react";
import PropTypes from 'prop-types';
import ErrorInPage from "@/common/components/error-in-page/error-in-page";

function TableError({colSpan, message}) {
    return (
        <TableRow>
            <TableCell colSpan={colSpan}>
                <ErrorInPage message={message} />
                <Box sx={{ m: 30 }} />
            </TableCell>
        </TableRow>
    )
}

TableError.propTypes = {
    colSpan: PropTypes.number,
    message: PropTypes.message
  };

export default TableError;