import PropTypes from 'prop-types';

// material-ui
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// root style
const RootStyle = styled(Box)({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden'
});

// scroll bar style
const ScrollContainer = styled(Box)(({ theme }) => ({
  maxHeight: '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '10px',
    height: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: alpha(theme.palette.grey[500], 0.48),
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: alpha(theme.palette.grey[400], 0.98),
  }
}));

export default function SimpleScroll({ children, sx, ...other }) {
  return (
    <>
      <RootStyle>
        <ScrollContainer sx={sx} {...other}>
          {children}
        </ScrollContainer>
      </RootStyle>
      <Box sx={{ display: { xs: 'block', md: 'none' }, overflowX: 'auto', ...sx }} {...other}>
      </Box>
    </>
  );
}

SimpleScroll.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
};
