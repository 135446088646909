import React from 'react';
import TableView from '@/common/components/TableView/TableView';
import { Grid, Button } from '@mui/material';
import { PlusOutlined } from '@ant-design/icons';
import { columns, reportFileName } from './constants/constants';
import CreateEditMaterialCustomer from 
'./create-edit-material-master/create-edit-material-master';
import ModalDialog from '@/common/components/modal-dialog/modal-dialog';
import { FormattedMessage } from 'react-intl';
import useOnActions from './hooks/useOnActions';
import AlertDialog from '@/common/components/alert-dialog/alert-dialog';
import useMaterialMaster from './hooks/useMaterialMaster';
import VerifyAccess from '@/common/components/verify-access/verify-access';
import { requestedMenuAll, requestedFeaturesAll } from '@/from-theme/routes/constants/menuAccess';
import { isUserHasAccess } from '@/from-theme/utils/route-guard/helpers/helpers';
import useAuth from '@/from-theme/hooks/useAuth';

const { customerMasterKey } = requestedMenuAll;
const { createKey, updateKey, deleteKey } = requestedFeaturesAll;

export default function Material() {
  const {
    materialMasterData,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal,
    materialMasterStatus,
    materialMasterErrMsg
  } = useMaterialMaster();

  const { userData } = useAuth();
  const entitlement = userData?.entitlement || {};

  const isUpdateDeleteAccess =
    isUserHasAccess(customerMasterKey, updateKey, entitlement) &&
     isUserHasAccess(customerMasterKey, deleteKey, entitlement);
  const { onRowDelete, deleteMaterialStatus, isAlertDialog, 
    onCloseAlert, onAgreeAlert } = useOnActions();

  const actionButton = (
    <VerifyAccess requestedMenu={customerMasterKey} requestedFeature={createKey}>
      <Button variant="contained" startIcon={<PlusOutlined />} onClick={() =>
         setIsCreateEditModalOpen(true)}>
        <FormattedMessage id="add-material" />
      </Button>
    </VerifyAccess>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableView
          data={materialMasterData}
          columns={columns({ onRowEdit, onRowDelete, deleteMaterialStatus,
             isUpdateDeleteAccess })}
          actionButton={actionButton}
          reportFileName={reportFileName}
          apiStatus={materialMasterStatus}
          apiErrorMsg={materialMasterErrMsg}
          stringInExport={["activeStatus"]}
        />
        {isCreateEditModalOpen && (
          <ModalDialog
            closeModal={() => closeModal()}
            modalTitle={!activeEditId ? 'create-material-master' :
             'edit-material-master'}
            fullScreen
          >
            <CreateEditMaterialCustomer closeModal={() => closeModal()}
             activeEditId={activeEditId} />
          </ModalDialog>
        )}
        {isAlertDialog && <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />}
      </Grid>
    </Grid>
  );
}
