import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import Dropdown from "@/common/components/dropdown/dropdown";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import useCreateEditDepartmentMaster from "./hooks/useCreateEditDepartmentMaster";
import useGetDivisionOptions from "@/common/hooks/useGetDivisionMaster";
import PropTypes from "prop-types";
import { apiStatuses } from "@/store/api/constants";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";


function CreateEditDepartmentMaster({
  closeModal, activeEditId
}) {

  const { getDivisionOptions, getDivisionLoading } = useGetDivisionOptions();
  const {
    values, formik, getEditDepartmentMasterStatus, getEditDepartmentMasterErrMsg,
     saveDepartmentMasterStatus, updateDepartmentMasterStatus,
  } = useCreateEditDepartmentMaster(activeEditId);

  const isPending = [saveDepartmentMasterStatus, updateDepartmentMasterStatus,]
  .includes(apiStatuses.pending);


  const {
    departmentName, departmentCode, divisionId, activeStatus
  } = values;

  return (
    <>
      {
        getEditDepartmentMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }

      {getEditDepartmentMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditDepartmentMasterErrMsg} />
      )}

      {(getEditDepartmentMasterStatus === apiStatuses.succeeded || !activeEditId) &&
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25} style={{paddingBottom:'240px'}}>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="department-short-name" size="small" fullWidth
                  value={departmentCode}
                  inputLabel="department-short-name"
                  formik={formik}
                  name="departmentCode"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="department-name" size="small" fullWidth
                  value={departmentName}
                  inputLabel="department-name"
                  formik={formik}
                  name="departmentName"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <Dropdown
                  options={getDivisionOptions}
                  loading={getDivisionLoading}
                  placeholder="division-name"
                  inputLabel="division-name"
                  value={divisionId}
                  formik={formik}
                  name="divisionId"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending}
             sx={{ mr: 1 }}>
              <FormattedMessage id={"cancel"} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
                <FormattedMessage id={"save"} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      }
    </>
  )
}

CreateEditDepartmentMaster.defaultProps = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
}


CreateEditDepartmentMaster.defaultProps = {
  activeEditId: 0,
};



export default CreateEditDepartmentMaster;