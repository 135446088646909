import PropTypes from 'prop-types';
import useAuth from '@/from-theme/hooks/useAuth';
import { isUserHasAccess } from '@/from-theme/utils/route-guard/helpers/helpers';

const VerifyAccess = (props) => {
    const {children, requestedMenu, requestedFeature} = props;
    const { userData } = useAuth();
    const entitlement = userData?.entitlement || {};

    if(isUserHasAccess(requestedMenu, requestedFeature, entitlement)) {
        return children
    }
    return null;
};

VerifyAccess.propTypes = {
    children: PropTypes.node,
    requestedMenu: PropTypes.string.isRequired,
    requestedFeature: PropTypes.string.isRequired,
};

export default VerifyAccess;
