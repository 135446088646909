import TabsView from "@/common/components/tabs-view/tabs-view";
import React from "react";
import { dropdownMasterTabs } from "./constants/constants";

export default function LocationMaster() {
    return(
        <TabsView
            tabs={dropdownMasterTabs}
        />
    )
}