import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import useCreateEditRoleMaster from "./hooks/useCreateEditRoleMaster";
import PropTypes from "prop-types";
import { apiStatuses } from "@/store/api/constants";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";


function CreateEditRoleMaster({
  closeModal, activeEditId
}) {

  const {
    values, formik, getEditRoleMasterStatus, getEditRoleMasterErrMsg, saveRoleMasterStatus,
    updateRoleMasterStatus } = useCreateEditRoleMaster(activeEditId);

  const isPending = [saveRoleMasterStatus, updateRoleMasterStatus].includes(apiStatuses.pending);

  const {
     roleName, activeStatus
  } = values;

  return (
    <>
      {
        getEditRoleMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }

      {getEditRoleMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditRoleMasterErrMsg} />
      )}

      {(getEditRoleMasterStatus === apiStatuses.succeeded || !activeEditId) &&
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25}>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="role-name" size="small" fullWidth
                  value={roleName}
                  inputLabel="role-name"
                  formik={formik}
                  name="roleName"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending}
             sx={{ mr: 1 }}>
              <FormattedMessage id={"cancel"} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
                <FormattedMessage id={"save"} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      }
    </>
  )
}

CreateEditRoleMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditRoleMaster.defaultProps = {
  activeEditId: 0,
};

export default CreateEditRoleMaster;