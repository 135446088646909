// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { ChromeOutlined } from '@ant-design/icons';
import { requestedFeaturesAll, requestedMenuAll } from '../routes/constants/menuAccess';

// icons
const icons = {
  ChromeOutlined
};


const {dashboardKey} = requestedMenuAll;
const {readKey} = requestedFeaturesAll;

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const dashboard = {
  id: 'dashboard',
  title: <FormattedMessage id="dashboard" />,
  type: 'group',
  url: '/dashboard',
  icon: icons.ChromeOutlined,
  requestedMenu: {dashboardKey},
  requestedFeature: {readKey}
};

export default dashboard;
