import TabsView from "@/common/components/tabs-view/tabs-view";
import React from "react";
import { productMasterTabs } from "./constants/constants";

export default function ProductMaster() {
    return(
        <TabsView
            tabs={productMasterTabs}
        />
    )
}