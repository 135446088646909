export const isUserHasAccess = (requestedMenu="", requestedFeature="", entitlement={}) =>{
   return ((entitlement[requestedMenu] || "") || []).indexOf(requestedFeature) !== -1

}

export const isUserHasAnyAccess = (requestedMenus = [], entitlement = {}) => {
   return requestedMenus.some((menu) => {
     const menuEntitlement = entitlement[menu];
     return Array.isArray(menuEntitlement) && menuEntitlement.length > 0;
   });
 };
