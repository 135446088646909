import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useMenuMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveMenuMasterStatus = getStatus(state, apiKeys.saveMenuMaster, '');
  const updateMenuMasterStatus = getStatus(state, apiKeys.updateMenuMaster, '');
  const menuMasterData = getData(state, apiKeys.menuMaster, []);
  const menuMasterStatus = getStatus(state, apiKeys.menuMaster, '');
  const menuMasterErrMsg = getErrorMessage(state, apiKeys.menuMaster, '');
  const deleteMenuMasterStatus = getStatus(state, apiKeys.deleteMenuMaster, '');

  const getMenuMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.menuMaster,
      method: apiMethods.GET,
      key: apiKeys.menuMaster,
      failureMsg: formatMessage({ id: "failed-to-get-menu-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getMenuMaster()
  }, [dispatch, getMenuMaster]);

  useEffect(() => {
    if (deleteMenuMasterStatus === apiStatuses.succeeded) {
      getMenuMaster();
    }
  }, [deleteMenuMasterStatus, getMenuMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.menuMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  useEffect(() => {
    if (saveMenuMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getMenuMaster();
    } 
  }, [saveMenuMasterStatus, closeModal, getMenuMaster]);

  useEffect(() => {
    if (updateMenuMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getMenuMaster();
    } 
  }, [updateMenuMasterStatus, closeModal, getMenuMaster]);

  return useMemo(() => ({
    menuMasterData,
    menuMasterStatus,
    menuMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    menuMasterData,
    menuMasterStatus,
    menuMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
