import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useCountryMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveCountryMasterStatus = getStatus(state, apiKeys.saveCountryMaster, '');
  const updateCountryMasterStatus = getStatus(state, apiKeys.updateCountryMaster, '');
  const countryMasterData = getData(state, apiKeys.countryMaster, []);
  const countryMasterStatus = getStatus(state, apiKeys.countryMaster, '');
  const countryMasterErrMsg = getErrorMessage(state, apiKeys.countryMaster, '');
  const deleteCountryMasterStatus = getStatus(state, apiKeys.deleteCountryMaster, '');

  const getCountryMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.countryMaster,
      method: apiMethods.GET,
      key: apiKeys.countryMaster,
      failureMsg: formatMessage({ id: "failed-to-get-country-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getCountryMaster()
  }, [dispatch, getCountryMaster]);

  useEffect(() => {
    if (deleteCountryMasterStatus === apiStatuses.succeeded) {
      getCountryMaster();
    }
  }, [deleteCountryMasterStatus, getCountryMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.countryMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  
  useEffect(() => {
    if (saveCountryMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getCountryMaster();
    } 
  }, [saveCountryMasterStatus, closeModal, getCountryMaster]);

  useEffect(() => {
    if (updateCountryMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getCountryMaster();
    } 
  }, [updateCountryMasterStatus, closeModal, getCountryMaster]);

  return useMemo(() => ({
    countryMasterData,
    countryMasterStatus,
    countryMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    countryMasterData,
    countryMasterStatus,
    countryMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
