import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearMultipleData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetSubZoneOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getSubZoneData = getData(state, apiKeys.subzoneMaster, []);
  const getSubZoneStatus = getStatus(state, apiKeys.subzoneMaster, '');
  const getSubZoneLoading = apiStatuses.pending === getSubZoneStatus;
  const getSubZoneListData = getData(state, apiKeys.subZoneList, []);
  const getSubZoneListStatus = getStatus(state, apiKeys.subZoneList, '');
  const getSubZoneListLoading = apiStatuses.pending === getSubZoneListStatus;


    //*************************on this apis all subzone data****************************
    const getSubZoneRawData = useCallback(() => {
      dispatch(fetchData({
        url: apiUrls.subzoneMaster,
        method: apiMethods.GET,
        key: apiKeys.subzoneMaster,
      }))
    }, [dispatch]);

  const getSubZoneOptions = useMemo(() => {
    return getSubZoneData.filter((option) => option.activeStatus === "1")
    .map(({ subzoneId, subzoneName }) => ({
      id: subzoneId,
      label: subzoneName
    }))
  }, [getSubZoneData]);
    //*************************on this apis all subzone data****************************

  //*************************this apis based on zone data****************************
  const getSubZoneList = useCallback((inputId) => {
    dispatch(fetchData({
      url: apiUrls.subZoneList,
      method: apiMethods.POST,
      key: apiKeys.subZoneList,
      body: inputId,
    }))
  }, [dispatch]);

  const getSubZoneListOptions = useMemo(() => {
    return getSubZoneListData
    .map(({ subZoneId, subZoneName }) => ({
      id: subZoneId,
      label: subZoneName
    }))
  }, [getSubZoneListData]);

  //*************************this apis based on zone data****************************
  

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.subzoneMaster, apiKeys.subZoneList] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getSubZoneRawData,
      getSubZoneData,
      getSubZoneOptions,
      getSubZoneStatus,
      getSubZoneLoading,
      getSubZoneList,
      getSubZoneListOptions,
      getSubZoneListLoading
    }),
    [getSubZoneRawData, getSubZoneData, 
      getSubZoneOptions, getSubZoneStatus,
    getSubZoneLoading, getSubZoneList, getSubZoneListOptions,
    getSubZoneListLoading ]
  );
}

export default useGetSubZoneOptions;
