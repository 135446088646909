import { RouterProvider } from 'react-router-dom';

// project import
import router from '@/from-theme/routes';
import ThemeCustomization from '@/from-theme/themes';

import Locales from '@/from-theme/components/Locales';
import RTLLayout from '@/from-theme/components/RTLLayout';
import ScrollTop from '@/from-theme/components/ScrollTop';
import Snackbar from '@/from-theme/components/@extended/Snackbar';
import Notistack from '@/from-theme/components/third-party/Notistack';

// auth provider
import { JWTProvider as AuthProvider } from '@/from-theme/contexts//JWTContext';

// ==============================|| APP - THEME, ROUTER, LOCAL ||============================== //

const App = () => (
  <ThemeCustomization>
    <RTLLayout>
    <Locales>
      <ScrollTop>
        <AuthProvider>
          <>
            <Notistack>
              <RouterProvider router={router} />
              <Snackbar />
            </Notistack>
          </>
        </AuthProvider>
      </ScrollTop>
    </Locales>
    </RTLLayout>
  </ThemeCustomization>
);

export default App;
