// third-party
import { FormattedMessage } from 'react-intl';


// ==============================|| MENU ITEMS - PAGES ||============================== //

const pages = [
  {
    id: 'bank-master',
    title: <FormattedMessage id="bank-master" />,
    type: 'item',
    url: '/bank-master',
    target: false,
  },
  {
    id: 'company-master',
    title: <FormattedMessage id="company-master" />,
    type: 'item',
    url: '/company-master',
    target: false,
  },
  {
    id: 'gl-master',
    title: <FormattedMessage id="gl-master" />,
    type: 'item',
    url: '/gl-master',
    target: false,
  },
  {
    id: 'fd-competitor-master',
    title: <FormattedMessage id="fd-competitor-master" />,
    type: 'item',
    url: '/fd-competitor-master',
    target: false,
  },
  {
    id: 'ifd-competitor-master',
    title: <FormattedMessage id="ifd-competitor-master" />,
    type: 'item',
    url: '/ifd-competitor-master',
    target: false,
    children: [
      {
        id: 'create-competitor-master',
        title: <FormattedMessage id="create-competitor-master" />,
        type: 'item',
        url: '/ifd-competitor-master/create',
        target: false
      },
      {
        id: 'edit-competitor-master',
        title: <FormattedMessage id="edit-competitor-master" />,
        type: 'item',
        url: '/ifd-competitor-master/edit',
        target: false
      },
    ]
  },
  {
    id: 'competitor-offers',
    title: <FormattedMessage id="competitor-offers" />,
    type: 'item',
    url: '/competitor-offers',
    target: false,
  },
  {
    id: 'claim-master',
    title: <FormattedMessage id="claim-master" />,
    type: 'item',
    url: '/claim-master',
    target: false,
  },
  {
    id: 'customer-master',
    title: <FormattedMessage id="customer-master" />,
    type: 'item',
    url: '/customer-master',
    target: false,
  },
  {
    id: 'division-master',
    title: <FormattedMessage id="division-master" />,
    type: 'item',
    url: '/division-master',
    target: false,
    children: [
      {
        id: 'create-division-master',
        title: <FormattedMessage id="create-division-master" />,
        type: 'item',
        url: '/division-master/create',
        target: false
      },
      {
        id: 'edit-division-master',
        title: <FormattedMessage id="edit-division-master" />,
        type: 'item',
        url: '/division-master/edit',
        target: false
      },
    ]
  },
  {
    id: 'dropdown-master',
    title: <FormattedMessage id="dropdown-master" />,
    type: 'item',
    url: '/dropdown-master',
    target: false,
  },
  {
    id: 'location-master',
    title: <FormattedMessage id="location-master" />,
    type: 'item',
    url: '/location-master',
    target: false,
  },
  {
    id: 'menu-master',
    title: <FormattedMessage id="menu-master" />,
    type: 'item',
    url: '/menu-master',
    target: false,
  },
  {
    id: 'outlet-master',
    title: <FormattedMessage id="outlet-master" />,
    type: 'item',
    url: '/outlet-master',
    target: false,
    children: [
      {
        id: 'create-outlet-master',
        title: <FormattedMessage id="create-outlet-master" />,
        type: 'item',
        url: '/outlet-master/create',
        target: false
      },
      {
        id: 'edit-outlet-master',
        title: <FormattedMessage id="edit-outlet-master" />,
        type: 'item',
        url: '/outlet-master/edit',
        target: false
      },
    ]
  },
  {
    id: 'plant-master',
    title: <FormattedMessage id="plant-master" />,
    type: 'item',
    url: '/plant-master',
    target: false,
    children: [
      {
        id: 'create-plant-master',
        title: <FormattedMessage id="create-plant-master" />,
        type: 'item',
        url: '/plant-master/create',
        target: false
      },
      {
        id: 'edit-plant-master',
        title: <FormattedMessage id="edit-plant-master" />,
        type: 'item',
        url: '/plant-master/edit',
        target: false
      },
    ]
  },
  {
    id: 'plan-applicable',
    title: <FormattedMessage id="plan-applicable" />,
    type: 'item',
    url: '/plan-applicable',
    target: false,
  },
  {
    id: 'product-master',
    title: <FormattedMessage id="product-master" />,
    type: 'item',
    url: '/product-master',
    target: false,
  },
  {
    id: 'prospect-master',
    title: <FormattedMessage id="prospect-master" />,
    type: 'item',
    url: '/prospect-master',
    target: false,
  },
  {
    id: 'role-master',
    title: <FormattedMessage id="role-master" />,
    type: 'item',
    url: '/role-master',
    target: false,
  },
  {
    id: 'shift-master',
    title: <FormattedMessage id="shift-master" />,
    type: 'item',
    url: '/shift-master',
    target: false,
    children: [
      {
        id: 'create-shift-master',
        title: <FormattedMessage id="create-shift-master" />,
        type: 'item',
        url: '/shift-master/create',
        target: false
      },
      {
        id: 'edit-shift-master',
        title: <FormattedMessage id="edit-shift-master" />,
        type: 'item',
        url: '/shift-master/edit',
        target: false
      },
    ]
  },
  {
    id: 'user-master',
    title: <FormattedMessage id="user-master" />,
    type: 'item',
    url: '/user-master',
    target: false,
    children: [
      {
        id: 'create-user-master',
        title: <FormattedMessage id="create-user-master" />,
        type: 'item',
        url: '/user-master/create',
        target: false
      },
      {
        id: 'edit-user-master',
        title: <FormattedMessage id="edit-user-master" />,
        type: 'item',
        url: '/user-master/edit',
        target: false
      },
    ]
  },
  {
    id: 'venue-master',
    title: <FormattedMessage id="venue-master" />,
    type: 'item',
    url: '/venue-master',
    target: false,
    children: [
      {
        id: 'edit-venue-mapping',
        title: <FormattedMessage id="edit-venue-mapping" />,
        type: 'item',
        url: '/venue-master/edit',
        target: false
      },
    ]
  },
  {
    id: 'zone-master',
    title: <FormattedMessage id="zone-master" />,
    type: 'item',
    url: '/zone-master',
    target: false,
  },
       
        {
          id: 'employee-master',
          title: <FormattedMessage id="employee-master" />,
          type: 'item',
          url: '/employee-master',
          target: false,
          children: [
            {
              id: 'create-employee-master',
              title: <FormattedMessage id="create-employee-master" />,
              type: 'item',
              url: '/employee-master/create',
              target: false
            },
            {
              id: 'edit-employee-master',
              title: <FormattedMessage id="edit-employee-master" />,
              type: 'item',
              url: '/employee-master/edit',
              target: false
            },
          ]
        },
       
        {
          id: 'ifoods-plan',
          title: <FormattedMessage id="ifoods-plan-approval" />,
          type: 'item',
          url: '/ifoods-plan',
          target: false
        },
        {
          id: 'foods-plan',
          title: <FormattedMessage id="foods-plan-approval" />,
          type: 'item',
          url: '/foods-plan',
          target: false
        },
        {
          id: 'pjp-creation',
          title: <FormattedMessage id="pjp-creation" />,
          type: 'item',
          url: '/pjp-creation',
          target: false,
          children: [
            {
              id: 'create-plan',
              title: <FormattedMessage id="create-plan" />,
              type: 'item',
              url: '/pjp-creation/create',
              target: false
            },
            {
              id: 'edit-plan',
              title: <FormattedMessage id="edit-plan" />,
              type: 'item',
              url: '/pjp-creation/edit',
              target: false
            },
          ]
        },
        {
          id: 'claim-creation',
          title: <FormattedMessage id="claim-creation" />,
          type: 'item',
          url: '/claim-creation',
          target: false,
        },
        {
          id: 'plan-validation',
          title: <FormattedMessage id="plan-validation" />,
          type: 'item',
          url: '/plan-validation',
          target: false
        },
        // {
        //   id: 'plan-deviation',
        //   title: <FormattedMessage id="plan-deviation-validation" />,
        //   type: 'item',
        //   url: '/plan-deviation',
        // },
        {
          id: 'plan-deviation',
          title: <FormattedMessage id="plan-deviation" />,
          type: 'item',
          url: '/plan-deviation',
          target: false,
          children: [
            {
              id: 'edit-plan-deviation',
              title: <FormattedMessage id="edit-plan-deviation" />,
              type: 'item',
              url: '/plan-deviation/edit',
              target: false
            },
           
          ]
        },
        {
          id: 'prospect-validation',
          title: <FormattedMessage id="ifoods-prospect-validation" />,
          type: 'item',
          url: '/prospect-validation',
          target: false
        },
        {
          id: 'approval-claim-backoffice',
          title: <FormattedMessage id="approval-claim-backoffice" />,
          type: 'item',
          url: '/approval-claim-backoffice',
          target: false,
          children: [
            {
              id: 'edit-approval-claim-backoffice',
              title: <FormattedMessage id="edit-approval-claim-backoffice" />,
              type: 'item',
              url: '/approval-claim-backoffice/edit',
              target: false
            },
           
          ]
        },
        {
          id: 'attendance-report',
          title: <FormattedMessage id="attendance-report" />,
          type: 'item',
          url: '/attendance-report',
          target: false
        },
        {
          id: 'app-track-report',
          title: <FormattedMessage id="app-track-report" />,
          type: 'item',
          url: '/app-track-report',
          target: false
        },
        {
          id: 'competitor-report',
          title: <FormattedMessage id="competitor-report" />,
          type: 'item',
          url: '/competitor-report',
          target: false
        },
        {
          id: 'claim-details-report',
          title: <FormattedMessage id="claim-details-report" />,
          type: 'item',
          url: '/claim-details-report',
          target: false,
          children: [
            {
              id: 'view-claim-details-report',
              title: <FormattedMessage id="view-claim-details-report" />,
              type: 'item',
              url: '/claim-details-report/view',
              target: false
            },
            {
              id: 'claim-smart-form',
              title: <FormattedMessage id="claim-smart-form" />,
              type: 'item',
              url: '/claim-details-report/edit',
              target: false
            },
          ]
        },
        {
          id: 'claim-expense-report',
          title: <FormattedMessage id="claim-expense-report" />,
          type: 'item',
          url: '/claim-expense-report',
          target: false
        },
        {
          id: 'plan-report',
          title: <FormattedMessage id="plan-report" />,
          type: 'item',
          url: '/plan-report',
          target: false
        },
        {
          id: 'plan-deviation-report',
          title: <FormattedMessage id="plan-deviation-report" />,
          type: 'item',
          url: '/plan-deviation-report',
          target: false
        },
        {
          id: 'map-view',
          title: <FormattedMessage id="map-view" />,
          type: 'item',
          url: '/map-view',
          target: false
        },
        {
          id: 'map',
          title: <FormattedMessage id="map" />,
          type: 'item',
          url: '/map',
          target: false
        },
        {
          id: 'outlet-visit-report',
          title: <FormattedMessage id="outlet-visit-report" />,
          type: 'item',
          url: '/outlet-visit-report',
          target: false
        },
        {
          id: 'secondary-outlet-visit-report',
          title: <FormattedMessage id="secondary-outlet-visit-report" />,
          type: 'item',
          url: '/secondary-outlet-visit-report',
          target: false
        },
        {
          id: 'prospect-report',
          title: <FormattedMessage id="prospect-report" />,
          type: 'item',
          url: '/prospect-report',
          target: false
        },
        {
          id: 'primary-customer-report',
          title: <FormattedMessage id="primary-customer-report" />,
          type: 'item',
          url: '/primary-customer-report',
          target: false
        },
        {
          id: 'travel-report',
          title: <FormattedMessage id="travel-report" />,
          type: 'item',
          url: '/travel-report',
          target: false
        },
        {
          id: 'travel-claim-report',
          title: <FormattedMessage id="travel-claim-report" />,
          type: 'item',
          url: '/travel-claim-report',
          target: false
        },
        {
          id: 'secondary-customer-report',
          title: <FormattedMessage id="secondary-customer-report" />,
          type: 'item',
          url: '/secondary-customer-report',
          target: false
        },
]

export default pages;
