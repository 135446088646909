import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateDesignationMaster({activeEditId, getDepartment}) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveDesignationMasterStatus = getStatus(state, apiKeys.saveDesignationMaster, '');
  const updateDesignationMasterStatus = getStatus(state, apiKeys.updateDesignationMaster, '');
  const saveDesignationMasterLoading = apiStatuses.pending === saveDesignationMasterStatus;
  const getEditDesignationMasterData = getData(state, apiKeys.getEditDesignationMaster, {});
  const getEditDesignationMasterStatus = getStatus(state, apiKeys.getEditDesignationMaster, "");
  const getEditDesignationMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditDesignationMaster,
    formatMessage({ id: "failed-to-get-selected-designation-details" })
  );

  const validationSchema = yup.object({
    designationCode: yup.string().required(formatMessage({ id: "this-field-is-required" }))
    .matches(/^[A-Za-z\s]+$/, formatMessage({ id: 'only-alphanumerical-characters-allowed' })),
    designationName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    departmentId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    desGradeId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateDesignationMaster(values)
      }else{
        saveDesignationMaster(values)
      }
    }
  });

  const { values } = formik;
  const { divisionId } = values;

  useEffect(() => {
    let payload;
    if (divisionId) {
      payload = getFormattedPayload({ divisionId });
      getDepartment(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisionId, getDepartment]);


  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditDesignationMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditDesignationMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditDesignationMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-designation-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  const onChangeDivision = useCallback(() => {
    formik.setFieldValue('departmentId', undefined);
  }, [formik]);


  useEffect(() => {
    if (activeEditId) {
      getEditDesignationMaster()
    }
  }, [activeEditId, getEditDesignationMaster]);

  useEffect(() => {
    if (getEditDesignationMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditDesignationMasterData))
    }
  }, [getEditDesignationMasterStatus])


  const saveDesignationMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveDesignationMaster,
        method: apiMethods.POST,
        key: apiKeys.saveDesignationMaster,
        successMsg: formatMessage({ id: "designation-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "designation-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateDesignationMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateDesignationMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateDesignationMaster,
        successMsg: formatMessage({ id: "designation-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "designation-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveDesignationMaster,
        apiKeys.updateDesignationMaster, apiKeys.getEditDesignationMaster] }));
    },
    [dispatch]
  );

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveDesignationMasterStatus,
    updateDesignationMasterStatus,
    saveDesignationMasterLoading,
    getEditDesignationMasterData,
    getEditDesignationMasterStatus,
    getEditDesignationMasterErrMsg,
    onChangeDivision
  }), [
    formik,
    saveDesignationMasterStatus,
    updateDesignationMasterStatus,
    saveDesignationMasterLoading,
    getEditDesignationMasterData,
    getEditDesignationMasterStatus,
    getEditDesignationMasterErrMsg,
    onChangeDivision
  ])
}

export default useCreateDesignationMaster