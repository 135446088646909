import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateEditCityMaster(activeEditId, getDistrict) {

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveCityMasterStatus = getStatus(state, apiKeys.saveCityMaster, '');
  const updateCityMasterStatus = getStatus(state, apiKeys.updateCityMaster, '');
  const saveCityMasterLoading = apiStatuses.pending === saveCityMasterStatus;
  const getEditCityMasterData = getData(state, apiKeys.getEditCityMaster, {});
  const getEditCityMasterStatus = getStatus(state, apiKeys.getEditCityMaster, "");
  const getEditCityMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditCityMaster,
    formatMessage({ id: "failed-to-get-selected-city-details" })
  );

  const validationSchema = yup.object({
    stateId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    districtId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    cityName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    countryId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (activeEditId) {
        updateCityMaster(values)
      } else {
        saveCityMaster(values)
      }
    }
  });

  const getEditCityMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditCityMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditCityMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-city-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditCityMaster()
    }
  }, [activeEditId, getEditCityMaster]);

  const { values } = formik;
  const { stateId, } = values;

     useEffect(() => {
      let payload;
      if (stateId) {
        // set data 
        payload = getFormattedPayload({ stateId });
        getDistrict(payload);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateId, getDistrict]);


  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const onChangeCountry = useCallback(() => {
    formik.setFieldValue('stateId', undefined);
    formik.setFieldValue('districtId', undefined);
},[formik])

const onChangeState = useCallback(() => {
  formik.setFieldValue('districtId', undefined);
},[formik])

  useEffect(() => {
    if (getEditCityMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditCityMasterData))
    }
  }, [getEditCityMasterStatus])

  const saveCityMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveCityMaster,
        method: apiMethods.POST,
        key: apiKeys.saveCityMaster,
        successMsg: formatMessage({ id: "city-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id: "city-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateCityMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateCityMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateCityMaster,
        successMsg: formatMessage({ id: "city-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "city-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);




  useEffect(() => () => {
    dispatch(clearMultipleData({ key: [apiKeys.saveCityMaster, apiKeys.getEditCityMaster] }));
  }, [dispatch])

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveCityMasterStatus,
    updateCityMasterStatus,
    saveCityMasterLoading,
    getEditCityMasterData,
    getEditCityMasterStatus,
    getEditCityMasterErrMsg,
    onChangeCountry,
    onChangeState,
  }), [
    formik,
    saveCityMasterStatus,
    updateCityMasterStatus,
    saveCityMasterLoading,
    getEditCityMasterData,
    getEditCityMasterStatus,
    getEditCityMasterErrMsg,
    onChangeCountry,
    onChangeState,
  ])
}

export default useCreateEditCityMaster