import { DeleteOutlined } from "@ant-design/icons";
import { IconButton, Stack, Tooltip } from "@mui/material";
import PropTypes from 'prop-types';
import { apiStatuses } from "@/store/api/constants";

function DeleteAction({row, onRowDelete, deleteGradeMasterStatus}) {
    const {original: {gradeId}} = row; 
    return (
    <Stack direction="row" spacing={1} alignItems="center">
        <Tooltip title="Delete">
            <IconButton
                color="error"
                onClick={() => onRowDelete(gradeId)}
                disabled={deleteGradeMasterStatus === apiStatuses.pending}
            >
                <DeleteOutlined />
            </IconButton>
        </Tooltip>
    </Stack>
    );
}

DeleteAction.propTypes = {
    row: PropTypes.object,
    table: PropTypes.object,
    options: PropTypes.array,
    id: PropTypes.number,
    index: PropTypes.number,
    onRowDelete: PropTypes.func.isRequired,
    deleteGradeMasterStatus: PropTypes.string,
};
export default DeleteAction;
