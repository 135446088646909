import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useBrandMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveBrandMasterStatus = getStatus(state, apiKeys.saveBrandMaster, '');
  const updateBrandMasterStatus = getStatus(state, apiKeys.updateBrandMaster, '');
  const brandMasterData = getData(state, apiKeys.brandMaster, []);
  const brandMasterStatus = getStatus(state, apiKeys.brandMaster, '');
  const brandMasterErrMsg = getErrorMessage(state, apiKeys.brandMaster, '');
  const deleteBrandMasterStatus = getStatus(state, apiKeys.deleteBrandMaster, '');

  const getBrandMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.brandMaster,
      method: apiMethods.GET,
      key: apiKeys.brandMaster,
      failureMsg: formatMessage({ id: "failed-to-get-brand-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getBrandMaster()
  }, [dispatch, getBrandMaster]);

  useEffect(() => {
    if (deleteBrandMasterStatus === apiStatuses.succeeded) {
      getBrandMaster();
    }
  }, [deleteBrandMasterStatus, getBrandMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.brandMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  
  useEffect(() => {
    if (saveBrandMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getBrandMaster();
    } 
  }, [saveBrandMasterStatus, closeModal, getBrandMaster]);

  useEffect(() => {
    if (updateBrandMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getBrandMaster();
    } 
  }, [updateBrandMasterStatus, closeModal, getBrandMaster]);

  return useMemo(() => ({
    brandMasterData,
    brandMasterStatus,
    brandMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    brandMasterData,
    brandMasterStatus,
    brandMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
