import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useEnergyTypeMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const energytypeMasterData = getData(state, apiKeys.energytypeMaster, []);
  const saveEnergyTypeMasterStatus = getStatus(state, apiKeys.saveEnergyTypeMaster, '');
  const updateEnergyTypeMasterStatus = getStatus(state, apiKeys.updateEnergyTypeMaster, '');
  const energytypeMasterStatus = getStatus(state, apiKeys.energytypeMaster, '');
  const energytypeMasterErrMsg = getErrorMessage(state, apiKeys.energytypeMaster, '');
  const deleteEnergyTypeMasterStatus = getStatus(state, apiKeys.deleteEnergyTypeMaster, '');


  const getEnergyTypeMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.energytypeMaster,
      method: apiMethods.GET,
      key: apiKeys.energytypeMaster,
      failureMsg: formatMessage({ id: "failed-to-get-energytype-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getEnergyTypeMaster()
  }, [dispatch, getEnergyTypeMaster]);

  useEffect(() => {
    if (deleteEnergyTypeMasterStatus === apiStatuses.succeeded) {
      getEnergyTypeMaster();
    }
  }, [deleteEnergyTypeMasterStatus, getEnergyTypeMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.energytypeMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  
  useEffect(() => {
    if (saveEnergyTypeMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getEnergyTypeMaster();
    } 
  }, [saveEnergyTypeMasterStatus, closeModal, getEnergyTypeMaster]);

  useEffect(() => {
    if (updateEnergyTypeMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getEnergyTypeMaster();
    } 
  }, [updateEnergyTypeMasterStatus, closeModal, getEnergyTypeMaster]);

  return useMemo(() => ({
    energytypeMasterData,
    energytypeMasterStatus,
    energytypeMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    energytypeMasterData,
    energytypeMasterStatus,
    energytypeMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
