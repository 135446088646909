import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateEditDistrictMaster(activeEditId) {

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveDistrictMasterStatus = getStatus(state, apiKeys.saveDistrictMaster, '');
  const updateDistrictMasterStatus = getStatus(state, apiKeys.updateDistrictMaster, '');
  const saveDistrictMasterLoading = apiStatuses.pending === saveDistrictMasterStatus;
  const getEditDistrictMasterData = getData(state, apiKeys.getEditDistrictMaster, {});
  const getEditDistrictMasterStatus = getStatus(state, apiKeys.getEditDistrictMaster, "");
  const getEditDistrictMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditDistrictMaster,
    formatMessage({ id: "failed-to-get-selected-district-details" })
  );

  const validationSchema = yup.object({
    districtName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    stateId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    countryId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateDistrictMaster(values)
      }else{
        saveDistrictMaster(values)
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditDistrictMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditDistrictMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditDistrictMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-district-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditDistrictMaster()
    }
  }, [activeEditId, getEditDistrictMaster]);

  const onChangeCountry = useCallback(() => {
    formik.setFieldValue('stateId', undefined);
},[formik])


  useEffect(() => {
    if (getEditDistrictMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditDistrictMasterData))
    }
  }, [getEditDistrictMasterStatus])

  const saveDistrictMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveDistrictMaster,
        method: apiMethods.POST,
        key: apiKeys.saveDistrictMaster,
        successMsg: formatMessage({ id: "district-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "district-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateDistrictMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateDistrictMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateDistrictMaster,
        successMsg: formatMessage({ id: "district-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "district-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);





  useEffect(() => () => {
    dispatch(clearMultipleData({ key: [apiKeys.saveDistrictMaster,
       apiKeys.getEditDistrictMaster] }));
  }, [dispatch])

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveDistrictMasterStatus,
    updateDistrictMasterStatus,
    saveDistrictMasterLoading,
    getEditDistrictMasterData,
    getEditDistrictMasterStatus,
    getEditDistrictMasterErrMsg,
    onChangeCountry
  }), [
    formik,
    saveDistrictMasterStatus,
    updateDistrictMasterStatus,
    saveDistrictMasterLoading,
    getEditDistrictMasterData,
    getEditDistrictMasterStatus,
    getEditDistrictMasterErrMsg,
    onChangeCountry
  ])
}

export default useCreateEditDistrictMaster