// third-party
import { combineReducers } from 'redux';
import fetchReducer from '../slices/fetch-slice';
import globalSlice from 'store/slices/global-slice';

// project import
import menu from './menu';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, fetch: fetchReducer, global: globalSlice });

export default reducers;
