import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputLabel } from '@mui/material';
import PropTypes  from 'prop-types';
import dayjs from 'dayjs';


import "./date-input.scss"


export default function DateInput({
    placeholder = '', size = 'small',
    inputLabel = "", value="", onChange,
    name, formik,
    ...props
}) {
  const {formatMessage} = useIntl();

  
  const formikError = React.useMemo(() => {
    if(formik) {
      return (
        {
          error: formik?.touched[name] && Boolean(formik?.errors[name]),
          helperText: formik?.touched[name] && formik?.errors[name]
        }
      )
    }
    return ({})
  }, [formik, name])

  return (
    <> 
      {!!inputLabel && 
        <InputLabel className='labelClr' required={!formik?.fields[name]?.spec?.optional}
         for={name}>
          <FormattedMessage id={inputLabel} />
        </InputLabel>
      }
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...props}
        format="DD-MM-YYYY"
        style={{ width: '80%' }}
        size={size || 'small'}
        fullWidth
        placeholder={placeholder ? formatMessage({id: placeholder}) : ""}
        value={dayjs(value)}
        sx={{height: "20px"}}
        className='date-input'
        onChange={(newValue) => {
          if(formik) {
            formik.setFieldValue(name, dayjs(newValue).format("YYYY-MM-DD"));
          } else {
            onChange(newValue);
          }
        }}
        slotProps={{
          textField: {
            // external error handling
            ...formikError,
            id: name
          }
        }}
      />
    </LocalizationProvider>
    </>
  );
}



DateInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  inputLabel: PropTypes.string,
  size: PropTypes.size,
  name: PropTypes.string,
  formik: PropTypes.object,
  onChange: PropTypes.func,
}

DateInput.defaultProps = {
  placeholder: "",
  value: "",
  inputLabel: "",
  size: "",
  formik: null,
  name: "",
  onChange: () => {},
}
