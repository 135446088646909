import React from "react";
import PropTypes  from 'prop-types';
import { Alert, Grid } from "@mui/material";

function ErrorInPage({message}) {
    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid item>
                <Alert severity="error">{message}</Alert>
            </Grid>
        </Grid>
        // Updates required
    )
}

ErrorInPage.propTypes = {
    message: PropTypes.string,
}
  
ErrorInPage.defaultProps = {
    message: "",
}

export default ErrorInPage;