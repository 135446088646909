import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useAllowanceMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveAllowanceMasterStatus = getStatus(state, apiKeys.saveAllowanceMaster, '');
  const updateAllowanceMasterStatus = getStatus(state, apiKeys.updateAllowanceMaster, '');
  const allowanceMasterData = getData(state, apiKeys.allowanceMaster, []);
  const allowanceMasterStatus = getStatus(state, apiKeys.allowanceMaster, '');
  const allowanceMasterErrMsg = getErrorMessage(state, apiKeys.allowanceMaster, '');
  const deleteAllowanceMasterStatus = getStatus(state, apiKeys.deleteAllowanceMaster, '');

  const getAllowanceMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.allowanceMaster,
      method: apiMethods.GET,
      key: apiKeys.allowanceMaster,
      failureMsg: formatMessage({ id: "failed-to-get-allowance-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getAllowanceMaster()
  }, [dispatch, getAllowanceMaster]);

  useEffect(() => {
    if (deleteAllowanceMasterStatus === apiStatuses.succeeded) {
      getAllowanceMaster();
    }
  }, [deleteAllowanceMasterStatus, getAllowanceMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.allowanceMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  
  useEffect(() => {
    if (saveAllowanceMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getAllowanceMaster();
    } 
  }, [saveAllowanceMasterStatus, closeModal, getAllowanceMaster]);

  useEffect(() => {
    if (updateAllowanceMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getAllowanceMaster();
    } 
  }, [updateAllowanceMasterStatus, closeModal, getAllowanceMaster]);

  return useMemo(() => ({
    allowanceMasterData,
    allowanceMasterStatus,
    allowanceMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    allowanceMasterData,
    allowanceMasterStatus,
    allowanceMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
