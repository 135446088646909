import PropTypes from 'prop-types';

// ==============================|| LOGO COMPONENT ||============================== //

// const LogoMain = ({ reverse }) => {
  const LogoMain = () => {
  return (
    /**
     * If you want to use an image instead of an SVG, uncomment the following, and comment out the
     *  <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="Mantis"
     *  width="100" />
     *
     */
  
    <img className='main-logo' src="/assets/images/logo.jpeg" alt='main-logo'/>
    
  );
};

const LoginLogo = () => {
  return (
    /**
     * If you want to use an image instead of an SVG, uncomment the following, and comment out
     *  the <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="Mantis"
     *  width="100" />
     *
     */
  
    <img className='login-logo' src="/assets/images/login-logo.png" alt='login-logo'/>
    
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export { LogoMain, LoginLogo };
