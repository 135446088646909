import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography, Modal, Card, CardMedia, IconButton } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useState, useEffect,  } from 'react';
import RejectionFiles from './RejectionFiles';
import { FormattedMessage } from 'react-intl';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';

const DropzoneWrapper = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${theme.palette.secondary.main}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
  '&:hover': { opacity: 0.72, cursor: 'pointer' }
}));

const SingleFileUpload = ({ error, file, setFieldValue, sx, onUpload, onRemoveCallBack,
   inputLabel }) => {
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState('Upload');
  const [uploadStatus, setUploadStatus] = useState('Upload');

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0];
      setFieldValue(
        'file',
        Object.assign(acceptedFile, {
          preview: URL.createObjectURL(acceptedFile)
        })
      );
      setUploadStatus('Uploading...'); // Set status when upload starts
      if (onUpload) {
        onUpload(acceptedFiles); 
      }
    }
  });

  useEffect(() => {
    if (file) {
      const newFileName = typeof file === 'string' ? file.split('/').pop() : file.name;
      setFileName(newFileName);
      setUploadStatus(newFileName); // Display the file name once uploaded
    } else {
      setFileName('Upload');
      setUploadStatus('Upload');
    }
  }, [file]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onRemove = () => {
    setFieldValue('file', null);
    onRemoveCallBack(file);
    setUploadStatus('Upload'); // Reset status after file removal
  };

  return (
    <Box sx={{ width: '100%', height: '50%', ...sx }}>
      {!!inputLabel && (
        <Typography variant="subtitle1" gutterBottom>
          <FormattedMessage id={inputLabel} />
        </Typography>
      )}

      <DropzoneWrapper
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body1">{uploadStatus}</Typography> 
      </DropzoneWrapper>

      {file && (
        <Stack direction="row" alignItems="center">
          <IconButton color="success" variant="dashed" onClick={handleOpen}>
            <EyeOutlined />
          </IconButton>
          
          <IconButton color="error" variant="dashed" onClick={onRemove}>
            <DeleteOutlined />
          </IconButton>
        </Stack>
      )}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            outline: 'none', 
          }}
        >
          <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
            {(file && (file?.preview || file)) && (
              <CardMedia
                component="img"
                src={file?.preview || file}
                alt={fileName}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px'
                }}
              />
            )}
          </Card>
        </Box>
      </Modal>
    </Box>
  );
};

SingleFileUpload.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setFieldValue: PropTypes.func.isRequired,
  onRemoveCallBack: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
  sx: PropTypes.object,
  inputLabel: PropTypes.string
};

export default SingleFileUpload;
