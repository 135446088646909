import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import CreateEditDesignationMaster from "./create-designation-master/create-designation-master";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import useDesignationMaster from "./hooks/useDesignationsMaster";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";
import useAuth from "@/from-theme/hooks/useAuth";

const {roleMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;

const DesignationMaster = () => {
    const { designationMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, designationMasterStatus, designationMasterErrMsg }
        = useDesignationMaster();
        const { userData } = useAuth();
        const entitlement = userData?.entitlement || {};
      
        const isUpdateDeleteAccess = isUserHasAccess(roleMasterKey, updateKey, entitlement)
          && isUserHasAccess(roleMasterKey, deleteKey, entitlement);
        const {
        onRowDelete,
        deleteDesignationMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();
    const actionButton = (
    <VerifyAccess requestedMenu={roleMasterKey} requestedFeature={createKey}>
        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => setIsCreateEditModalOpen(true)}
        ><FormattedMessage id="add-designation" />
        </Button>
        </VerifyAccess>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={designationMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteDesignationMasterStatus,
                        isUpdateDeleteAccess })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={designationMasterStatus}
                    apiErrorMsg={designationMasterErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-designation-master" :
                         "edit-designation-master"}
                    >
                        <CreateEditDesignationMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
};

export default DesignationMaster;