// project import
import dashboard from './dashboard';
import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const breadcrumbs = {
  items: [dashboard, ...pages, ]
};

export default breadcrumbs;
