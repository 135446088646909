import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useEnergyPriceMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const energypriceMasterData = getData(state, apiKeys.energypriceMaster, []);
  const saveEnergyPriceMasterStatus = getStatus(state, apiKeys.saveEnergyPriceMaster, '');
  const updateEnergyPriceMasterStatus = getStatus(state, apiKeys.updateEnergyPriceMaster, '');
  const energypriceMasterStatus = getStatus(state, apiKeys.energypriceMaster, '');
  const energypriceMasterErrMsg = getErrorMessage(state, apiKeys.energypriceMaster, '');
  const deleteEnergyPriceMasterStatus = getStatus(state, apiKeys.deleteEnergyPriceMaster, '');

  const getEnergyPriceMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.energypriceMaster,
      method: apiMethods.GET,
      key: apiKeys.energypriceMaster,
      failureMsg: formatMessage({ id: "failed-to-get-energyprice-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getEnergyPriceMaster()
  }, [dispatch, getEnergyPriceMaster]);

  useEffect(() => {
    if (deleteEnergyPriceMasterStatus === apiStatuses.succeeded) {
      getEnergyPriceMaster();
    }
  }, [deleteEnergyPriceMasterStatus, getEnergyPriceMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.energypriceMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);
  
  useEffect(() => {
    if (saveEnergyPriceMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getEnergyPriceMaster();
    } 
  }, [saveEnergyPriceMasterStatus, closeModal, getEnergyPriceMaster]);

  useEffect(() => {
    if (updateEnergyPriceMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getEnergyPriceMaster();
    } 
  }, [updateEnergyPriceMasterStatus, closeModal, getEnergyPriceMaster]);

  return useMemo(() => ({
    energypriceMasterData,
    energypriceMasterStatus,
    energypriceMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    energypriceMasterData,
    energypriceMasterStatus,
    energypriceMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
