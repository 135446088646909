import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetCategoryNameOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getCategoryNameData = getData(state, apiKeys.categoryMaster, []);
  const getCategoryNameStatus = getStatus(state, apiKeys.categoryMaster, '');
  const getCategoryNameLoading = apiStatuses.pending === getCategoryNameStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.categoryMaster,
        method: apiMethods.GET,
        key: apiKeys.categoryMaster
      })
    );
  }, [dispatch]);

  const getCategoryNameOptions = useMemo(() => {
    return getCategoryNameData.filter((option) => option.activeStatus === "1")
    .map(({ fdCatId, categoryName }) => ({
      id: fdCatId,
      label: categoryName
    }))
  }, [getCategoryNameData]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.categoryMaster,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
        getCategoryNameData,
        getCategoryNameOptions,
        getCategoryNameStatus,
        getCategoryNameLoading
    }),
    [getCategoryNameData, getCategoryNameOptions, getCategoryNameStatus, getCategoryNameLoading]
  );
}

export default useGetCategoryNameOptions;
