import React from 'react';
import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import Dropdown from '@/common/components/dropdown/dropdown';
import TextInput from '@/common/components/text-input/text-input';
import SwitchInput from '@/common/components/switch-input/switch-input';
import AnimateButton from '@/from-theme/components/@extended/AnimateButton';
import { FormattedMessage } from 'react-intl';
import DateInput from '@/common/components/date-input/date-input';
import useCreateEditAllowanceMaster from './hooks/useCreateEditAllowanceMaster';
import PropTypes from 'prop-types';
import { apiStatuses } from '@/store/api/constants';
import ErrorInPage from '@/common/components/error-in-page/error-in-page';
import Spinner from '@/common/components/spinner/spinner';
import useGetGradeData from '@/common/hooks/useGetGradeMaster';

function CreateEditAllowanceMaster({ closeModal, activeEditId }) {
  const { getGradeOptions, getGradeLoading } = useGetGradeData();


  const { values, formik, getEditAllowanceMasterStatus, getEditAllowanceMasterErrMsg,
    saveAllowanceMasterStatus, updateAllowanceMasterStatus, } 
  = useCreateEditAllowanceMaster(activeEditId);

  const { hqDaCost, exHqDaCost, daGradeId, daValidFrom, daValidTo, activeStatus } = values;

  const isPending = [saveAllowanceMasterStatus,
    updateAllowanceMasterStatus,].includes(apiStatuses.pending);


  return (
    <>
      {getEditAllowanceMasterStatus === apiStatuses.pending && activeEditId && <Spinner />}

      {getEditAllowanceMasterStatus === apiStatuses.rejected && activeEditId && 
      <ErrorInPage message={getEditAllowanceMasterErrMsg} />}

      {(getEditAllowanceMasterStatus === apiStatuses.succeeded || !activeEditId) && (
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25}>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <Dropdown
                  options={getGradeOptions}
                  loading={getGradeLoading}
                  placeholder="grade"
                  inputLabel="grade"
                  value={daGradeId}
                  formik={formik}
                  name="daGradeId"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="hq-da-cost"
                  size="small"
                  fullWidth
                  value={hqDaCost}
                  inputLabel="hq-da-cost"
                  formik={formik}
                  name="hqDaCost"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="ex-hq-da-cost"
                  size="small"
                  fullWidth
                  value={exHqDaCost}
                  inputLabel="ex-hq-da-cost"
                  formik={formik}
                  name="exHqDaCost"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <DateInput inputLabel="valid-from" placeholder="valid-from" 
                value={daValidFrom} formik={formik} name="daValidFrom" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <DateInput inputLabel="valid-to" placeholder="valid-to"
                 value={daValidTo} formik={formik} name="daValidTo" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} disabled={isPending} variant="outlined"
             sx={{ mr: 1 }}>
              <FormattedMessage id={'cancel'} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
                <FormattedMessage id={'save'} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      )}
    </>
  );
}


CreateEditAllowanceMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditAllowanceMaster.defaultProps = {
  activeEditId: 0
};



export default CreateEditAllowanceMaster;
