import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getStatus } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiUrls } from "@/store/api/constants";

export default function useOnActions() {
  const [isAlertDialog, setIsAlertDialog] = useState(false);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);
  const [currentRowId, setCurrentRowId] = useState(null);



  const deleteCountryMasterStatus = getStatus(state, apiKeys.deleteCountryMaster, '');

  const onCloseAlert = useCallback(() => {
    setIsAlertDialog((prevState) => !prevState);
    setCurrentRowId(null);
  }, []);

  const onAgreeAlert = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.deleteCountryMaster(currentRowId),
      method: apiMethods.DELETE,
      key: apiKeys.deleteCountryMaster,
      successMsg: formatMessage({ id: "brand-master-has-been-deleted-successfully" }),
      failureMsg: formatMessage({ id: "failed-to-delete-brand-master" }),
    }))
    onCloseAlert();
  }, [dispatch, formatMessage, onCloseAlert, currentRowId]);


  const onRowDelete = useCallback((id) => {
    setCurrentRowId(id);
    setIsAlertDialog(true);
  }, []);



  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.deleteCountryMaster }));
  }, [dispatch]);


  return useMemo(() => ({
    onRowDelete,
    deleteCountryMasterStatus,
    isAlertDialog,
    onCloseAlert,
    onAgreeAlert,

  }), [
    onRowDelete,
    deleteCountryMasterStatus,
    isAlertDialog,
    onCloseAlert,
    onAgreeAlert,
  ])
}
