import { Chip, Grid } from "@mui/material";
import EditAction from "../components/edit-action/edit-action";
import DeleteAction from "../components/delete-action/delete-action";
import { dateString } from "@/common/components/TableView/helpers/helpers";

export const columns = ({ onRowEdit, onRowDelete, deleteCountryMasterStatus }) => ([
  {
    header: 's-no',
    accessorKey: 'countryId',
    cell: (value) => {
      const countryId = value.row.index + 1;
     return countryId;
    }
  },
  {
    header: 'country-name',
    accessorKey: 'countryName'
  },
  {
    header: 'created-at',
    hideColumn: true,
    accessorKey: 'createdAt',
    cell: ({ getValue }) => dateString(getValue()) 
  },
  {
    header: 'created-by',
    hideColumn: true,
    accessorKey: 'createdBy'
  },
  {
    header: 'updated-at',
    hideColumn: true,
    accessorKey: 'updatedAt',
    cell: ({ getValue }) => dateString(getValue()) 

  },
  {
    header: 'updated-by',
    hideColumn: true,
    accessorKey: 'updatedBy'
  },
  {
    header: 'status',
    accessorKey: 'activeStatus',
    cell: (cell) => {
      switch (cell.getValue()) {
        case '0':
          return <Chip color="error" label="In-Active" size="small" variant="light" />;
        case '1':
          return <Chip color="success" label="Active" size="small" variant="light" />;

      }
    }
  },
  {
    header: 'action',
    id: "edit",
    cell: (props) => (
      <Grid container className="table-icon-actions-2">
        <Grid item>
          <EditAction
            {...props}
            onRowEdit={onRowEdit}
          />
        </Grid>
        <Grid item>
          <DeleteAction
            {...props}
            onRowDelete={onRowDelete} deleteCountryMasterStatus={deleteCountryMasterStatus}
          />
        </Grid>
      </Grid>
    ),
    meta: {
      className: 'cell-center'
    }
  }

]);

export const reportFileName = "Country Master";

