import { Box, LinearProgress, TableCell, TableRow } from "@mui/material";
import React from "react";
import PropTypes from 'prop-types';
import "./table-loader.scss";

function TableLoader({colSpan}) {
    return (
        <TableRow>
            <TableCell colSpan={colSpan} className="table-loader--cell">
                <LinearProgress />
                <Box sx={{ m: 30 }} />
            </TableCell>
        </TableRow>
    )
}

TableLoader.propTypes = {
    colSpan: PropTypes.number,
  
  };

export default TableLoader;