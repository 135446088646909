import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearMultipleData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetCityOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getCityData = getData(state, apiKeys.cityMaster, []);
  const getCityStatus = getStatus(state, apiKeys.cityMaster, '');
  const getCityLoading = apiStatuses.pending === getCityStatus;
  const getCitiesByDistrictData = getData(state, apiKeys.cityList, []);
  const getCitiesByDistrictStatus = getStatus(state, apiKeys.cityList, '');
  const getCitiesByDistrictLoading = apiStatuses.pending === getCitiesByDistrictStatus;

  const getCityRawData = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.cityMaster, 
        method: apiMethods.GET,
        key: apiKeys.cityMaster
      })
    );
  }, [dispatch]);

  const getCityOptions = useMemo(() => {
    return getCityData.filter((option) => option.activeStatus === "1")
    .map(({ cityId, cityName }) => ({
      id: cityId,
      label: cityName
    }))
  }, [getCityData]);

     //*************************this apis based on district data****************************
     const getCitiesByDistrict = useCallback((inputId) => {
      dispatch(fetchData({
        url: apiUrls.cityList,
        method: apiMethods.POST,
        key: apiKeys.cityList,
        body: inputId,
      }))
    }, [dispatch]);
  
    const getCitiesByDistrictOptions = useMemo(() => {
      return getCitiesByDistrictData
      .map(({ cityId, cityName }) => ({
        id: cityId,
        label: cityName
      }))
    }, [getCitiesByDistrictData]);
  
    //*************************this apis based on district data****************************
  

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.cityMaster, apiKeys.cityList,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getCityRawData,
      getCityData,
      getCityOptions,
      getCityStatus,
      getCityLoading,
      getCitiesByDistrict,
      getCitiesByDistrictOptions,
      getCitiesByDistrictStatus,
      getCitiesByDistrictLoading
    }),
    [getCityRawData, getCityData, 
      getCityOptions, getCityStatus, getCityLoading,
      getCitiesByDistrict, getCitiesByDistrictOptions,
      getCitiesByDistrictStatus, getCitiesByDistrictLoading]
  );
}

export default useGetCityOptions;
