import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetCityMasterByDistrict(district) {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getCitysByDistrictData = 
    getData(state, apiKeys.cityListByDistrict(district), []);
  const getCitysByDistrictStatus = 
    getStatus(state, apiKeys.cityListByDistrict(district), '');
  const getCitysByDistrictLoading = apiStatuses.pending === getCitysByDistrictStatus;
  
  const getCitysByDistrict = useCallback((inputId) => {
    dispatch(fetchData({
      url: apiUrls.cityListByDistrict,
      method: apiMethods.POST,
      key: apiKeys.cityListByDistrict(district),
      body: inputId,
    }))
  }, [district, dispatch]);

  const getCitysByDistrictOptions = useMemo(() => {
    return getCitysByDistrictData
    .map(({ cityId, cityName }) => ({
      id: cityId,
      label: cityName
      
    }))
  }, [getCitysByDistrictData]);


  useEffect(
    () => () => {
      dispatch(clearData({ key: apiKeys.cityListByDistrict(district) }));
    },
    [district, dispatch]
  );

  return useMemo(
    () => ({
      getCitysByDistrictData,
      getCitysByDistrictStatus,
      getCitysByDistrict,
      getCitysByDistrictOptions,
      getCitysByDistrictLoading
    }),

    [
      getCitysByDistrictData,
      getCitysByDistrictStatus,
      getCitysByDistrict,
      getCitysByDistrictOptions,
      getCitysByDistrictLoading
    ]
  );
}

export default useGetCityMasterByDistrict;
