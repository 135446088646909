import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import Dropdown from "@/common/components/dropdown/dropdown";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import useCreateEditGradeMaster from "./hooks/useCreateEditGradeMaster";
import useGetDropdownListMaster from "@/common/hooks/useGetDropdownListMaster";
import PropTypes from "prop-types";
import { apiStatuses } from "@/store/api/constants";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";


function CreateEditGradeMaster({
  closeModal, activeEditId
}) {

  const { transportModeOptions, getDropdownListLoading } = useGetDropdownListMaster();

  const {
    values, formik, getEditGradeMasterStatus,
    getEditGradeMasterErrMsg, saveGradeMasterStatus,
    updateGradeMasterStatus,
  } = useCreateEditGradeMaster(activeEditId);

  const isPending = [saveGradeMasterStatus, updateGradeMasterStatus,].includes(apiStatuses.pending);

  const {
    gradeCode, gradeName, transportModeId, activeStatus
  } = values;

  return (
    <>
      {
        getEditGradeMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }

      {getEditGradeMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditGradeMasterErrMsg} />
      )}

      {(getEditGradeMasterStatus === apiStatuses.succeeded || !activeEditId) &&
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25} style={{paddingBottom:'240px'}}>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="grade-name" size="small" fullWidth
                  value={gradeName}
                  inputLabel="grade-name"
                  formik={formik}
                  name="gradeName"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="grade-code" size="small" fullWidth
                  value={gradeCode}
                  inputLabel="grade-code"
                  formik={formik}
                  name="gradeCode"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <Dropdown
                  options={transportModeOptions}
                  loading={getDropdownListLoading}
                  placeholder="transport-mode"
                  inputLabel="transport-mode"
                  value={transportModeId}
                  formik={formik}
                  name="transportModeId"
                  isCheckBox
                  multiple
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending}
             sx={{ mr: 1 }}>
              <FormattedMessage id={"cancel"} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
                <FormattedMessage id={"save"} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      }
    </>
  )
}

CreateEditGradeMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditGradeMaster.defaultProps = {
  activeEditId: 0,
};

export default CreateEditGradeMaster;