import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetVehicleEnergyType() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getvehicleETData = getData(state, apiKeys.energytypeMaster, []);
  const getvehicleETStatus = getStatus(state, apiKeys.energytypeMaster, '');
  const getvehicleETLoading = apiStatuses.pending === getvehicleETStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.energytypeMaster,
        method: apiMethods.GET,
        key: apiKeys.energytypeMaster
      })
    );
  }, [dispatch]);

  const getvehicleETOptions = useMemo(() => {
    return getvehicleETData.filter((option) => option.activeStatus === "1")
    .map(({ energyTypeId, vehicleCatName }) => ({
      id: energyTypeId,
      label: vehicleCatName
    }))
  }, [getvehicleETData]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.energytypeMaster,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getvehicleETData,
      getvehicleETOptions,
      getvehicleETStatus,
      getvehicleETLoading
    }),
    [getvehicleETData, getvehicleETOptions, getvehicleETStatus, getvehicleETLoading]
  );
}

export default useGetVehicleEnergyType;
