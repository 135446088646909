
import {
    BookOutlined, UserOutlined, UsergroupAddOutlined
} from '@ant-design/icons';
import Allowance from '../components/allowance/allowance';
import EnergyPrice from '../components/energyprice/energyprice';
import EnergyType from '../components/energytype/energytype';
import Accommodation from '../components/accommodation/accommodation';
import { requestedFeaturesAll, requestedMenuAll } 
from '../../../from-theme/routes/constants/menuAccess';
import VerifyAccess from '@/common/components/verify-access/verify-access';

const { allowanceTabMasterKey, energyPriceTabMasterKey,
    energyTypeTabMasterKey, accommodationTabMasterKey } = requestedMenuAll;
const { readKey } = requestedFeaturesAll;

// tabName is locale id
export const claimMasterTabs = [
    {
        id: "allowance",
        tabName: "allowance", 
        icon: <UserOutlined />,
        tabContent: (<VerifyAccess requestedMenu={allowanceTabMasterKey} requestedFeature={readKey}>
        <Allowance />
      </VerifyAccess>)
    },
    {
        id: "energyprice",
        tabName: "energyprice",
        icon: <BookOutlined />,
        tabContent: <VerifyAccess requestedMenu={energyPriceTabMasterKey}
         requestedFeature={readKey}>
        <EnergyPrice /> </VerifyAccess>
    },
    {
        id: "energytype",
        tabName: "energytype",
        icon: <UsergroupAddOutlined />,
        tabContent: <VerifyAccess requestedMenu={energyTypeTabMasterKey}
         requestedFeature={readKey}>
        <EnergyType /> </VerifyAccess>
    },
    {
        id: "accommodation",
        tabName: "accommodation",
        icon: <UsergroupAddOutlined />,
        tabContent: <VerifyAccess requestedMenu={accommodationTabMasterKey}
        requestedFeature={readKey}>
       <Accommodation /> </VerifyAccess>
    }
]