import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetDropdownOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getDropdownData = getData(state, apiKeys.dropdownMaster, []);
  const getDropdownStatus = getStatus(state, apiKeys.dropdownMaster, '');
  const getDropdownLoading = apiStatuses.pending === getDropdownStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.dropdownMaster,
        method: apiMethods.GET,
        key: apiKeys.dropdownMaster
      })
    );
  }, [dispatch]);

  const getDropdownOptions = useMemo(() => {
    return getDropdownData.filter((option) => option.activeStatus === "1")
    .map(({ dropDownId, dropdownName }) => ({
      id: dropDownId,
      label: dropdownName
    }))
  }, [getDropdownData]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.dropdownMaster,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getDropdownData,
      getDropdownOptions,
      getDropdownStatus,
      getDropdownLoading
    }),
    [getDropdownData, getDropdownOptions, getDropdownStatus, getDropdownLoading]
  );
}

export default useGetDropdownOptions;
