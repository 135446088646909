import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getErrorMessage, getStatus } from '@/store/slices/helpers/helpers';
import { fetchData, clearMultipleData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { initialValues } from '../constants/constants';
import { getFormattedPayload, getFormattedResponse } from '@/common/helpers/helpers';

function useCreateEditBrandMaster(activeEditId) {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveBrandMasterStatus = getStatus(state, apiKeys.saveBrandMaster, '');
  const updateBrandMasterStatus = getStatus(state, apiKeys.updateBrandMaster, '');
  const saveBrandMasterLoading = apiStatuses.pending === saveBrandMasterStatus;
  const getEditBrandMasterData = getData(state, apiKeys.getEditBrandMaster, {});
  const getEditBrandMasterStatus = getStatus(state, apiKeys.getEditBrandMaster, '');
  const getEditBrandMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditBrandMaster,
    formatMessage({ id: 'failed-to-get-selected-brand-details' })
  );

  const validationSchema = yup.object({
    brandName: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    brandCode: yup.string().required(formatMessage({ id: 'this-field-is-required' }))
    .matches(/^[A-Za-z0-9]+$/, formatMessage({ id: 'only-alphanumerical-characters-allowed' })),
    activeStatus: yup.boolean().required(formatMessage({ id: 'this-field-is-required' }))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (activeEditId) {
        updateBrandMaster(values);
      } else {
        saveBrandMaster(values);
      }
    }
  });
  
  useEffect(() => {
    formik.setFieldValue('brandCode', formik.values.brandCode.toUpperCase());
  }, [ formik.values.brandCode]);

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditBrandMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditBrandMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditBrandMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-selected-brand-details' })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditBrandMaster();
    }
  }, [activeEditId, getEditBrandMaster]);

  useEffect(() => {
    if (getEditBrandMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditBrandMasterData));
    }
  }, [getEditBrandMasterStatus]);

  const saveBrandMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.saveBrandMaster,
          method: apiMethods.POST,
          key: apiKeys.saveBrandMaster,
          successMsg: formatMessage({ id: 'brand-master-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'brand-master-is-failed-to-save' }),
          body: getFormattedPayload(values)
        })
      );
    },
    [dispatch, formatMessage]
  );

  const updateBrandMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.updateBrandMaster,
          method: apiMethods.PUT,
          key: apiKeys.updateBrandMaster,
          successMsg: formatMessage({ id: 'brand-master-is-updated-successfully' }),
          failureMsg: formatMessage({ id: 'brand-master-is-failed-to-update' }),
          body: getFormattedPayload({ ...values, id: activeEditId })
        })
      );
    },
    [dispatch, formatMessage, activeEditId]
  );


  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveBrandMaster, 
        apiKeys.getEditBrandMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      formik,
      values: formik.values,
      saveBrandMasterStatus,
      updateBrandMasterStatus,
      saveBrandMasterLoading,
      getEditBrandMasterData,
      getEditBrandMasterStatus,
      getEditBrandMasterErrMsg
    }),
    [formik, saveBrandMasterStatus, updateBrandMasterStatus, saveBrandMasterLoading,
       getEditBrandMasterData, getEditBrandMasterStatus, getEditBrandMasterErrMsg]
  );
}

export default useCreateEditBrandMaster;
