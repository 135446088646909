
export const initialValues = {
  accomGradeId: "",
  accomCategory: "",
  lodgingCost: "",
  boardingCost: "",
  accomValidFrom: "",
  accomValidTo: "",
  activeStatus: 1,

}
