import { BookOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import MenuMaster from '../components/menuMaster/menuMaster';
import MenuList from '../components/menuList/menuList';
import MenuAccess from '../components/menuAccess/menuAccess';
import { requestedFeaturesAll, requestedMenuAll }
 from '../../../from-theme/routes/constants/menuAccess';
import VerifyAccess from '@/common/components/verify-access/verify-access';

const { menuTabMasterKey, menuListTabMasterKey, menuAccessTabMasterKey } = requestedMenuAll;
const { readKey } = requestedFeaturesAll;

// tabName is locale id
export const productMasterTabs = [
  {
    id: 'menuMaster',
    tabName: 'menuMaster',
    icon: <UserOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={menuTabMasterKey} requestedFeature={readKey}>
        <MenuMaster />
      </VerifyAccess>
    )
  },
  {
    id: 'menuList',
    tabName: 'menuList',
    icon: <BookOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={menuListTabMasterKey} requestedFeature={readKey}>
        <MenuList />
      </VerifyAccess>
    )
  },
  {
    id: 'menuAccess',
    tabName: 'menuAccess',
    icon: <UsergroupAddOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={menuAccessTabMasterKey} requestedFeature={readKey}>
        <MenuAccess />
      </VerifyAccess>
    )
  }
];
