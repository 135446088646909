import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getErrorMessage, getStatus } from '@/store/slices/helpers/helpers';
import { fetchData, clearMultipleData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { initialValues } from '../constants/constants';
import { getFormattedPayload, getFormattedResponse } from '@/common/helpers/helpers';

function useCreateEditStateMaster(activeEditId) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveStateMasterStatus = getStatus(state, apiKeys.saveStateMaster, '');
  const updateStateMasterStatus = getStatus(state, apiKeys.updateStateMaster, '');
  const saveStateMasterLoading = apiStatuses.pending === saveStateMasterStatus;
  const getEditStateMasterData = getData(state, apiKeys.getEditStateMaster, {});
  const getEditStateMasterStatus = getStatus(state, apiKeys.getEditStateMaster, '');
  const getEditStateMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditStateMaster,
    formatMessage({ id: 'failed-to-get-selected-state-details' })
  );

  const validationSchema = yup.object({
    stateName: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    countryId: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    activeStatus: yup.boolean().required(formatMessage({ id: 'this-field-is-required' }))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (activeEditId) {
        updateStateMaster(values);
      } else {
        saveStateMaster(values);
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;


  const getEditStateMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditStateMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditStateMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-selected-state-details' })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditStateMaster();
    }
  }, [activeEditId, getEditStateMaster]);

  useEffect(() => {
    if (getEditStateMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditStateMasterData));
    }
  }, [getEditStateMasterStatus]);

  const saveStateMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.saveStateMaster,
          method: apiMethods.POST,
          key: apiKeys.saveStateMaster,
          successMsg: formatMessage({ id: 'state-master-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'state-master-is-failed-to-save' }),
          body: getFormattedPayload(values)
        })
      );
    },
    [dispatch, formatMessage]
  );

  const updateStateMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.updateStateMaster,
          method: apiMethods.PUT,
          key: apiKeys.updateStateMaster,
          successMsg: formatMessage({ id: 'state-master-is-updated-successfully' }),
          failureMsg: formatMessage({ id: 'state-master-is-failed-to-update' }),
          body: getFormattedPayload({ ...values, id: activeEditId })
        })
      );
    },
    [dispatch, formatMessage, activeEditId]
  );


  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveStateMaster, apiKeys.getEditStateMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      formik,
      values: formik.values,
      saveStateMasterStatus,
      updateStateMasterStatus,
      saveStateMasterLoading,
      getEditStateMasterData,
      getEditStateMasterStatus,
      getEditStateMasterErrMsg
    }),
    [formik, saveStateMasterStatus, updateStateMasterStatus, saveStateMasterLoading,
       getEditStateMasterData, getEditStateMasterStatus, getEditStateMasterErrMsg]
  );
}

export default useCreateEditStateMaster;
