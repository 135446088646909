import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useSecondaryCustomerMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveSecCustomerMasterStatus = getStatus(state, apiKeys.saveSecCustomerMaster, '');
  const updateSecCustomerMasterStatus = getStatus(state, apiKeys.updateSecCustomerMaster, '');
  const secondaryCustomerMasterData = getData(state, apiKeys.secondaryCustomerMaster, []);
  const secondaryCustomerMasterStatus = getStatus(state, apiKeys.secondaryCustomerMaster, '');
  const secondaryCustomerMasterErrMsg = getErrorMessage(state, apiKeys.secondaryCustomerMaster, '');
  const deleteSecondaryCustomerMasterStatus =
    getStatus(state, apiKeys.deleteSecondaryCustomerMaster, '');


  const getSecondaryCustomerMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.secondaryCustomerMaster,
      method: apiMethods.GET,
      key: apiKeys.secondaryCustomerMaster,
      failureMsg: formatMessage({ id: "failed-to-get-secondary-customer-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getSecondaryCustomerMaster()
  }, [dispatch, getSecondaryCustomerMaster]);

  useEffect(() => {
    if (deleteSecondaryCustomerMasterStatus === apiStatuses.succeeded) {
      getSecondaryCustomerMaster();
    }
  }, [deleteSecondaryCustomerMasterStatus, getSecondaryCustomerMaster])



  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.secondaryCustomerMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  useEffect(() => {
    if (saveSecCustomerMasterStatus === apiStatuses.succeeded) {
      closeModal();
      getSecondaryCustomerMaster();
    } 
  }, [saveSecCustomerMasterStatus, closeModal, getSecondaryCustomerMaster ]);

  useEffect(() => {
    if (updateSecCustomerMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getSecondaryCustomerMaster();
    } 
  }, [updateSecCustomerMasterStatus, closeModal, getSecondaryCustomerMaster]);

  return useMemo(() => ({
    getSecondaryCustomerMaster,
    secondaryCustomerMasterData,
    secondaryCustomerMasterStatus,
    secondaryCustomerMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    getSecondaryCustomerMaster,
    secondaryCustomerMasterData,
    secondaryCustomerMasterStatus,
    secondaryCustomerMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
