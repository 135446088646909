import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateDepartmentMaster(activeEditId) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveDepartmentMasterStatus = getStatus(state, apiKeys.saveDepartmentMaster, '');
  const updateDepartmentMasterStatus = getStatus(state, apiKeys.updateDepartmentMaster, '');
  const saveDepartmentMasterLoading = apiStatuses.pending === saveDepartmentMasterStatus;
  const getEditDepartmentMasterData = getData(state, apiKeys.getEditDepartmentMaster, {});
  const getEditDepartmentMasterStatus = getStatus(state, apiKeys.getEditDepartmentMaster, "");
  const getEditDepartmentMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditDepartmentMaster,
    formatMessage({ id: "failed-to-get-selected-department-details" })
  );

  const validationSchema = yup.object({
    departmentName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    departmentCode: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    divisionId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateDepartmentMaster(values)
      }else{
        saveDepartmentMaster(values)
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;


  const getEditDepartmentMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditDepartmentMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditDepartmentMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-department-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditDepartmentMaster()
    }
  }, [activeEditId, getEditDepartmentMaster]);

  useEffect(() => {
    if (getEditDepartmentMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditDepartmentMasterData))
    }
  }, [getEditDepartmentMasterStatus])

  const saveDepartmentMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveDepartmentMaster,
        method: apiMethods.POST,
        key: apiKeys.saveDepartmentMaster,
        successMsg: formatMessage({ id: "department-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "department-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateDepartmentMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateDepartmentMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateDepartmentMaster,
        successMsg: formatMessage({ id: "department-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "department-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveDepartmentMaster,
         apiKeys.getEditDepartmentMaster, apiKeys.updateDepartmentMaster] }));
    },
    [dispatch]
  );

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveDepartmentMasterStatus,
    updateDepartmentMasterStatus,
    saveDepartmentMasterLoading,
    getEditDepartmentMasterData,
    getEditDepartmentMasterStatus,
    getEditDepartmentMasterErrMsg,
  }), [
    formik,
    saveDepartmentMasterStatus,
    updateDepartmentMasterStatus,
    saveDepartmentMasterLoading,
    getEditDepartmentMasterData,
    getEditDepartmentMasterStatus,
    getEditDepartmentMasterErrMsg,
  ])
}

export default useCreateDepartmentMaster